import { Component, input } from '@angular/core';
import { MaterialModule } from '@modules/material.module';

export interface NavigationListButtonData {
  icon: string;
  text: string;
}

@Component({
  selector: 'app-navigation-list-button',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './navigation-list-button.component.html',
})
export class NavigationListButtonComponent {
  isActivated = input<boolean>(false);
  expanded = input.required<boolean>();
  buttonData = input.required<NavigationListButtonData>();
}
