import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { DataBytes } from '@shared/interfaces/data-bytes';
import { LoadingState } from '@shared/interfaces/loading-state';

import { PFAThresholds, PMC } from './pfa-pmc-thresholds';

export interface SpectrumData {
  method: string;
  signalLength: number;
  windowType: string;
  windowLength: number;
  fftLength: number;
  normalizationType: string;
  frequencyBin: number;
  frequencyResolution: number;
  methodVariance: number;
  segmentsCount: number;
  overlap: number;
}

export interface SpectrumDto {
  amplitudesContentPath: string;
  freqMin: number;
  freqMax: number;
  data?: SpectrumData;
}

export interface Spectrum {
  amplitudes: DataBytes;
  freqMin: number;
  freqMax: number;
  data?: SpectrumData;
}

export interface NoiseFieldData {
  filterType: string;
  filterFrequencyBandwidth: number;
  filterPFA: number;
  filterLength: number;
}

export interface NoiseFieldDto {
  amplitudesContentPath: string;
  freqMin: number;
  freqMax: number;
  data?: NoiseFieldData;
}

export interface NoiseField {
  amplitudes: DataBytes;
  freqMin: number;
  freqMax: number;
  data?: NoiseFieldData;
}

export interface PeakDto {
  index: number;
  frequency: number;
  type: string;
  amplitude: number;
  meanField: number;
  rms: number;
  psd: number;
  localSNR: number;
  emergingSNR: number;
  pfa: number;
  totalQuadraticError: number;
  quadraticError3dB: number;
  relativeBandWidth3dB: number;
}

export interface Peak {
  index: number;
  frequency: number;
  amplitude: number;
  meanField: number;
  rms: number;
  psd: number;
  type: string;
  pmc: PMC;
  localSNR: number;
  relativeBandWidth3dBRatio: number;
  totalQuadraticError: number;
  quadraticError3dB: number;
}

export interface CycleDto {
  spectrum: SpectrumDto;
  noise: NoiseFieldDto;
  peaks: PeakDto[];
  thresholds: PFAThresholds;
}

export interface CyclesDto {
  progress: string;
  data?: CycleDto[];
}

export interface Cycle {
  index: number;
  spectrum: Spectrum;
  noise: NoiseField;
  peaks: Peak[];
  thresholds: PFAThresholds;
}

export interface Cycles {
  progress: ComputationStepProgress;
  loadingState: LoadingState;
  data?: Cycle[];
}

export const defaultCyclesWithLoadingState = (loadingState: LoadingState = LoadingState.Unknown): Cycles => ({
  progress: ComputationStepProgress.Unknown,
  loadingState,
});
