@if (cycleSpectrumData(); as spectrum) {
  @let _welchWOSA = welchWOSA();

  <div class="m-2 flex flex-col bg-white">
    <span class="mb-2 font-bold">Estimation for Spectrum {{ cycleIndex() + 1 }}</span>
    <div class="flex flex-col">
      <app-spectrum-parameter name="Method" [value]="spectrum.method" />
      <app-spectrum-parameter name="Signal length" [value]="spectrum.signalLength" />
      <app-spectrum-parameter [name]="windowTypeLabel()" [value]="spectrum.windowType" />
      <app-spectrum-parameter [name]="windowLengthLabel()" [value]="spectrum.windowLength" />
      @if (_welchWOSA) {
        <ng-container>
          <app-spectrum-parameter name="Window overlap" [value]="spectrum.overlap" />
          @if (showSegmentsCount()) {
            <ng-container>
              <app-spectrum-parameter name="Number of segments" [value]="spectrum.segmentsCount" />
            </ng-container>
          }
        </ng-container>
      }
      <app-spectrum-parameter name="Next pow 2 FFT length" [value]="spectrum.fftLength" />
      <app-spectrum-parameter name="Normalization" [value]="spectrum.normalizationType" />
      <app-spectrum-parameter name="Frequency bin" [value]="frequencyBin() + 'Hz'" />
      <app-spectrum-parameter name="Frequency resolution" [value]="frequencyResolution() + 'Hz'" />
      <app-spectrum-parameter name="Normalized variance" [value]="normalizedVariance()" />
    </div>
  </div>
}

@if (cycleNoiseData(); as noise) {
  <div class="m-2 flex flex-col bg-white">
    <span class="mb-2 font-bold">Noise estimation for Spectrum {{ cycleIndex() + 1 }}</span>
    <div class="flex flex-col">
      <app-spectrum-parameter name="Filter type" [value]="noise.filterType" />
      <app-spectrum-parameter
        name="Filter frequency bandwidth"
        [value]="noise.filterFrequencyBandwidth"
        [tooltip]="'x zerocrossing-based bandwidth of spectral window'" />
      <app-spectrum-parameter name="Filter PFA" [value]="noise.filterPFA" />
      <app-spectrum-parameter name="Filter length" [value]="noise.filterLength" />
    </div>
  </div>
}
