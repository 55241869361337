import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { ProcessingStatus } from '@shared/interfaces/processing-status';

import { HarmonicTrajectory, PeakTrajectory } from '../interfaces/trajectory.interface';

export const name = 'sensor-Trajectories';

export interface SensorTrajectoriesState {
  sensorId: SensorId | null;
  status: ProcessingStatus;
  nextComputationDate: Date | null;
  peakTrajectories: PeakTrajectory[] | null;
  harmonicTrajectories: HarmonicTrajectory[] | null;
}

export const SENSOR_TRAJECTORIES_INITIAL_STATE: SensorTrajectoriesState = {
  sensorId: null,
  status: ProcessingStatus.Unknown,
  nextComputationDate: null,
  peakTrajectories: null,
  harmonicTrajectories: null,
};
