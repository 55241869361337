import {
  ComputationStepProgress,
  stringToComputationStepProgress,
} from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';

import { Stationarity, StationarityDto } from '../interface/stationarity';
import { ValidationStepStatus } from '../interface/validation-step-status';

export function mapStationarityFromDto(dto: StationarityDto): Stationarity {
  const stationarity: Stationarity = {
    progress: stringToComputationStepProgress(dto.progress),
    loadingState: LoadingState.Loaded,
    data: undefined,
  };

  if (stationarity.progress !== ComputationStepProgress.Completed) {
    return stationarity;
  }

  const dtoData = dto.data!;

  const nonStationarityPercentage = dtoData.stationarityData.detectionIndex;
  const thresholds = dtoData.thresholds;

  const status =
    nonStationarityPercentage < thresholds.stationarity
      ? ValidationStepStatus.Success
      : nonStationarityPercentage < thresholds.mediumNonStationarity
        ? ValidationStepStatus.Warning
        : ValidationStepStatus.Failure;

  stationarity.data = {
    ...dtoData,
    status,
    spectrogram: {
      ...dtoData.spectrogram,
      image: {
        contentPath: dtoData.spectrogram.imageContentPath,
        loadingState: LoadingState.Unknown,
      },
    },
    stationarityData: {
      ...dtoData.stationarityData,
      variance: {
        contentPath: dtoData.stationarityData.varianceContentPath,
        loadingState: LoadingState.Unknown,
      },
      projection: {
        contentPath: dtoData.stationarityData.projectionContentPath,
        loadingState: LoadingState.Unknown,
      },
      image: {
        contentPath: dtoData.stationarityData.imageContentPath,
        loadingState: LoadingState.Unknown,
      },
    },
    thresholds: {
      ...dtoData.thresholds,
    },
  };

  return stationarity;
}
