import { Injectable } from '@angular/core';
import { RealtimeRegistrationInterface } from '@features/realtime/shared/interfaces/realtime-registration-service';
import { RealtimeClientService } from '@features/realtime/shared/services/realtime-client.service';
import { TrajectoryService } from '@features/sensor-trajectories/services/trajectory/trajectory.service';
import { string2Status } from '@features/signals-status/shared/interface/astrion-signals-status.mapper';
import { Store } from '@ngrx/store';
import { ProcessingStatus } from '@shared/interfaces/processing-status';

import { SensorTrajectoriesActions } from '../store/sensor-trajectories.actions';
import { sensorTrajectoriesFeature } from '../store/sensor-trajectories.feature';
import { SensorTrackingStatusChanged as SensorTrackingStatusChanged } from './interface';

@Injectable()
export class TrajectoryRealtimeService implements RealtimeRegistrationInterface {
  constructor(
    private trajectoryService: TrajectoryService,
    private store: Store
  ) {}

  registerMessageHandlers(realtimeClient: RealtimeClientService): void {
    realtimeClient.registerMessageHandlers([
      { messageType: 'SensorTrackingStatusChanged', callback: this.sensorTrackingStatusChangedHandler },
    ]);
  }

  sensorTrackingStatusChangedHandler = async (msg: unknown): Promise<void> => {
    const statusMessage = msg as SensorTrackingStatusChanged;
    const newStatus = string2Status(statusMessage.newStatus);
    this.store.dispatch(
      SensorTrajectoriesActions.trajectoriesStatusChanged({
        sensorId: statusMessage.sensorId,
        status: newStatus,
        nextComputationDate: statusMessage.nextComputationDate,
      })
    );

    if (
      this.store.selectSignal(sensorTrajectoriesFeature.selectSensorId)() === statusMessage.sensorId &&
      newStatus == ProcessingStatus.Completed
    ) {
      this.trajectoryService.fetchTrajectories(statusMessage.sensorId);
    }
  };
}
