import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';

import { PeakIdentification, PeakIdentificationDto } from '../interface/peak-identification';
import { mapCyclesFromDto } from './cycles-mapping';
import { mapFusionFromDto } from './fusion-mapping';
import { mapHarmonicSeriesFromDto } from './harmonic-series-mapping';

export const mapPeakIdentificationFromDto = (dto: PeakIdentificationDto): PeakIdentification => ({
  cycles: mapCyclesFromDto(dto.cycles),
  fusion: mapFusionFromDto(dto.fusion),
  harmonicSeries: mapHarmonicSeriesFromDto(dto.harmonicSeries),
  progress: dto.computationDone ? ComputationStepProgress.Completed : ComputationStepProgress.InProgress,
  loadingState: LoadingState.Loaded,
});
