import { Injectable } from '@angular/core';
import { RealtimeRegistrationInterface } from '@features/realtime/shared/interfaces/realtime-registration-service';
import { RealtimeClientService } from '@features/realtime/shared/services/realtime-client.service';
import { Store } from '@ngrx/store';

import { mapEquipmentFromDto } from '../interfaces/equipment.interface';
import { EquipmentsActions } from '../store/equipments.actions';
import { EquipmentCreatedMessage, EquipmentDeletedMessage, EquipmentEditedMessage } from './interfaces';

@Injectable()
export class EquipmentRealtimeService implements RealtimeRegistrationInterface {
  constructor(private store: Store) {}

  registerMessageHandlers(realtimeClient: RealtimeClientService): void {
    realtimeClient.registerMessageHandlers([
      { messageType: 'EquipmentCreated', callback: this.equipmentCreatedHandler },
    ]);
    realtimeClient.registerMessageHandlers([{ messageType: 'EquipmentEdited', callback: this.equipmentEditedHandler }]);
    realtimeClient.registerMessageHandlers([
      { messageType: 'EquipmentDeleted', callback: this.equipmentDeletedHandler },
    ]);
  }

  equipmentCreatedHandler = (message: unknown): void => {
    const equipmentCreatedMessage = message as EquipmentCreatedMessage;
    this.store.dispatch(
      EquipmentsActions.equipmentCreated({
        equipment: mapEquipmentFromDto({ ...equipmentCreatedMessage }),
      })
    );
  };

  equipmentEditedHandler = (message: unknown): void => {
    const equipmentEditedMessage = message as EquipmentEditedMessage;
    this.store.dispatch(
      EquipmentsActions.equipmentUpdated({
        equipment: mapEquipmentFromDto({ ...equipmentEditedMessage }),
      })
    );
  };

  equipmentDeletedHandler = (message: unknown): void => {
    const equipmentDeletedMessage = message as EquipmentDeletedMessage;
    this.store.dispatch(
      EquipmentsActions.equipmentDeleted({
        id: equipmentDeletedMessage.id,
      })
    );
  };
}
