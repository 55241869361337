<mat-toolbar class="m3-headline-small-button mt-3 flex w-full flex-row justify-between p-0">
  <div class="relative flex flex-grow flex-row justify-stretch">
    <div class="z-10 flex flex-row items-center overflow-clip">
      @if (loading()) {
        <button mat-icon-button matTooltip="Home" class="opacity-0">
          <mat-icon>home</mat-icon>
        </button>
      } @else {
        <button mat-icon-button matTooltip="Home" (click)="this.homeClicked.emit()">
          <mat-icon class="m3-headline-small-icon">home</mat-icon>
        </button>
        @if (equipment(); as equipment) {
          <mat-icon class="m3-headline-small-icon">chevron_right</mat-icon>
          <button mat-button (click)="this.equipmentClicked.emit(equipment)">{{ equipment.name }}</button>
        }
        @if (sensor(); as sensor) {
          <mat-icon class="m3-headline-small-icon">chevron_right</mat-icon>
          <button mat-button (click)="this.sensorClicked.emit(sensor)">{{ sensor.name }}</button>
        }
        @if (filename(); as filename) {
          <mat-icon class="m3-headline-small-icon">chevron_right</mat-icon>
          <span class="select-none">{{ filename }}</span>
        }
      }
    </div>
  </div>
  <div class="flex flex-none flex-row"><ng-content /></div>
</mat-toolbar>
