import { DataType, makeBinaryField } from '@astrion-webtools/graph';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';

export const cycleBytesStorageDescriptor = (id: string, freqMin: number, freqMax: number) => {
  return makeBinaryField({
    id,
    dbName: ASTRION_INDEXEDDB_NAME,
    storeName: ASTRION_INDEXEDDB_TABLES.cycles,
    dstName: 'data',
    dataType: DataType.Float64,
    additionalFields: {
      freqMin,
      freqMax,
    },
  });
};

export const fusionBytesStorageDescriptor = (id: string, freqMin: number, freqMax: number) => {
  return makeBinaryField({
    id,
    dbName: ASTRION_INDEXEDDB_NAME,
    storeName: ASTRION_INDEXEDDB_TABLES.fusion,
    dstName: 'data',
    dataType: DataType.Float64,
    additionalFields: {
      freqMin,
      freqMax,
    },
  });
};
