import { computed, signal } from '@angular/core';
import { toUnique } from '@tools/utilities/array-utils';

export class IdListSelectionModel<TId> {
  private _selectedIds = signal<TId[]>([]);

  public selectionSignal = computed(() => this._selectedIds());

  public selection = () => this.selectionSignal();

  public setSelection = (newSelection: TId[]) => {
    this._selectedIds.set(toUnique(newSelection));
  };

  public clearSelection = () => {
    this._selectedIds.set([]);
  };

  public hasSelection = () => {
    return this._selectedIds().length !== 0;
  };

  public unselectIds = (ids: TId[]) => {
    this._selectedIds.update(currentSelection => {
      const uniqueIdsToUnselect = toUnique(ids);

      return currentSelection.filter(id => !uniqueIdsToUnselect.includes(id));
    });
  };

  public unselectId = (id: TId) => {
    this.unselectIds([id]);
  };

  public selectIds = (ids: TId[]) => {
    this._selectedIds.update(currentSelection => toUnique([...currentSelection, ...ids]));
  };

  public selectId = (id: TId) => {
    this.selectIds([id]);
  };
}
