@let _activated = isActivated();
@let _data = buttonData();

@if (expanded()) {
  <a mat-list-item [activated]="_activated" [style.backgroundColor]="_activated ? '#227A83' : '#00545B'" class="h-14">
    <mat-icon matListItemIcon [style.color]="'#FFFFFF'">{{ _data.icon }}</mat-icon>
    <span matListItem class="text-sm" [style.color]="'#FFFFFF'">{{ _data.text }}</span>
  </a>
} @else {
  <a mat-list-item [activated]="_activated" [style.backgroundColor]="_activated ? '#227A83' : '#00545B'" class="h-14">
    <mat-icon
      mat-icon-button
      [matTooltip]="_data.text"
      matTooltipPosition="after"
      class="align-middle"
      [style.color]="'#FFFFFF'"
      >{{ _data.icon }}</mat-icon
    >
  </a>
}
