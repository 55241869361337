<div class="flex h-full w-full flex-col gap-1">
  <div class="flex justify-center text-lg font-medium shadow-slate-300 text-shadow-sm">Fault detection projection</div>
  <lib-gl-graph
    class="flex-grow"
    id="sationarity-variance"
    [data]="data()"
    xTitle="Time (s)"
    yTitle="Fault (%)"
    group="stationarity"
    [yMeanTicks]="3"
    [xMeanTicks]="5"
    lockY />
</div>
