import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';

export interface HarmonicDto {
  peakIndex: number;
  rank: number;
}

export interface HarmonicSerieDto {
  fundamentalFrequency: number;
  density: number;
  regularity: number;
  richness: number;
  energy: number;
  thd: number;
  harmonics: HarmonicDto[];
}

export interface HarmonicSeriesDto {
  progress: string;
  data?: HarmonicSerieDto[];
}

export interface Harmonic {
  peakIndex: number;
  rank: number;
}

export interface HarmonicSerie {
  fundamentalFrequency: number;
  density: number;
  regularity: number;
  richness: number;
  energy: number;
  thd: number;
  harmonicsCount: number;
  harmonics: Harmonic[];
}

export interface HarmonicSeries {
  progress: ComputationStepProgress;
  loadingState: LoadingState;
  data?: HarmonicSerie[];
}

export const defaultHarmonicSeriesWithLoadingState = (
  loadingState: LoadingState = LoadingState.Unknown
): HarmonicSeries => ({
  progress: ComputationStepProgress.Unknown,
  loadingState,
});
