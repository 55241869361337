import { Component, computed, input } from '@angular/core';
import { GlGraphComponent, GraphCurve, GraphInput } from '@astrion-webtools/graph';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';

@Component({
  selector: 'app-stationarity-detection-projection',
  standalone: true,
  imports: [GlGraphComponent],
  templateUrl: './stationarity-detection-projection.component.html',
  styles: ``,
})
export class StationarityDetectionProjectionComponent {
  projection = input.required<GraphCurve | undefined>();
  data = computed<GraphInput>(() => {
    const curves: GraphCurve[] = [];
    const projection = this.projection();

    if (projection != undefined) {
      curves.push(projection);
    }

    return {
      dbName: ASTRION_INDEXEDDB_NAME,
      storeName: ASTRION_INDEXEDDB_TABLES.stationarityTestProjection,
      curves,
    };
  });
}
