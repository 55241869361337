@let _uploadState = uploadState();

<h1 mat-dialog-title>{{ title() }}</h1>
@if (_uploadState.finished) {
  <mat-dialog-content class="flex-grow">
    <mat-checkbox [checked]="showSuccess()" (change)="setShowSuccess($event.checked)" class="primary-checkbox">
      Show successful uploads
    </mat-checkbox>
  </mat-dialog-content>
}
<mat-dialog-content class="relative m-0 flex-grow !py-0 px-4">
  @if (!_uploadState.finished) {
    <app-upload-progress-screen [progress]="uploadProgress()" class="absolute inset-0" />
  } @else {
    <app-upload-result-list [uploads]="filteredUploads()" />
  }
</mat-dialog-content>
<mat-dialog-actions>
  @if (_uploadState.uploading) {
    <button mat-button class="error-button" (click)="cancelUpload()"><mat-icon>close</mat-icon>Cancel upload</button>
  }
  <span class="grow"></span>
  <button mat-raised-button class="primary-button" [disabled]="!isFinished()" [mat-dialog-close] #closeButton>
    <mat-icon>done</mat-icon>Done
  </button>
</mat-dialog-actions>
