import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  QueryList,
  Signal,
  viewChild,
  viewChildren,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { PathBarComponent } from '@components/path-bar/path-bar.component';
import { ScrollableBorderedContainerComponent } from '@components/scrollable-bordered-container/scrollable-bordered-container.component';
import { Equipment } from '@features/equipments/shared/interfaces/equipment.interface';
import { EquipmentsActions } from '@features/equipments/shared/store/equipments.actions';
import { Sensor } from '@features/sensors/shared/interfaces/sensor.interface';
import {
  ReportStepperComponent,
  Step,
} from '@features/signal-report/components/report-stepper/report-stepper.component';
import { ReportPages } from '@features/signal-report/shared/interface/report-page';
import { SignalReportActions } from '@features/signal-report/shared/store/signal-report.actions';
import { signalReportFeature } from '@features/signal-report/shared/store/signal-report.feature';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { signalsFeature } from '@features/signals/shared/store/signals.feature';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';
import { LoadingState } from '@shared/interfaces/loading-state';
import { ReportAnchorDirective } from '@tools/directives/report-anchor.directive';
import { ReportDataValidationComponent } from '../../components/report-data-validation/report-data-validation.component';
import { ReportOverviewComponent } from '../../components/report-overview/report-overview.component';
import { ReportPeakIdentificationComponent } from '../../components/report-peak-identification/report-peak-identification.component';
import { ReportSectionComponent } from '../../components/report-section/report-section.component';

const overviewSectionAnchorId = 'stepOne';
const spectralAnalysisAnchorId = 'stepTwo';
const dataValidationAnchorId = 'stepThree';

@Component({
  selector: 'app-one-page-signal-report',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PathBarComponent,
    MaterialModule,
    ReportOverviewComponent,
    ReportStepperComponent,
    ReportDataValidationComponent,
    ReportPeakIdentificationComponent,
    ScrollableBorderedContainerComponent,
    ReportAnchorDirective,
    ReportSectionComponent,
  ],
  templateUrl: './one-page-signal-report.component.html',
  styles: ``,
})
export class OnePageSignalReportComponent {
  signalId = this.store.selectSignal(signalsFeature.selectPageSignalId);
  reportTitle: Signal<string> = this.store.selectSignal(signalReportFeature.selectNameWithoutExtension);
  loadingState: Signal<LoadingState> = this.store.selectSignal(signalReportFeature.selectLoadingState);
  pages: Signal<ReportPages> = this.store.selectSignal(signalReportFeature.selectReportPages);
  sensor = this.store.selectSignal(signalReportFeature.selectSignalSensor);
  equipment = this.store.selectSignal(signalReportFeature.selectSignalEquipment);
  loading = computed(() => this.loadingState() === LoadingState.Loading);
  public container = viewChild.required<string, ElementRef>('container', { read: ElementRef });

  anchors: QueryList<ElementRef> = new QueryList<ElementRef>();
  @ViewChildren(ReportAnchorDirective, { read: ElementRef, emitDistinctChangesOnly: true })
  set childrenAnchors(children: QueryList<ElementRef>) {
    this.anchors = children;
  }

  stepper = viewChild.required<ReportStepperComponent>(ReportStepperComponent);
  sections = viewChildren(ReportSectionComponent);

  overviewSection = { icon: 'landscape', name: 'Signal overview', anchorId: overviewSectionAnchorId };
  spectralAnalysisSection = { icon: 'troubleshoot', name: 'Spectral analysis', anchorId: spectralAnalysisAnchorId };
  dataValidationSection = { icon: 'checklist', name: 'Data validation', anchorId: dataValidationAnchorId };

  steps: Step[] = [this.overviewSection, this.spectralAnalysisSection, this.dataValidationSection];

  constructor(
    private store: Store,
    private router: Router
  ) {
    this.store.dispatch(EquipmentsActions.equipmentsFetchRequestedIfNotInit());
  }

  ngOnInit(): void {
    const signalId = this.signalId();
    if (signalId) {
      this.store.dispatch(SignalReportActions.signalBaseDataFetchRequested({ signalId }));
    }
  }

  downloadSignal() {
    const signalId = this.signalId();
    if (signalId) {
      this.store.dispatch(SignalsActions.signalDownloadRequested({ signalId }));
    }
  }

  navigateToRoot() {
    this.router.navigateByUrl(`/operation`);
  }

  navigateToEquipment(equipment: Equipment) {
    this.router.navigateByUrl(`/equipment/${equipment.id}`);
  }

  navigateToSensor(sensor: Sensor) {
    this.router.navigateByUrl(`/sensor/${sensor.id}`);
  }

  onRefreshClicked() {
    const signalId = this.signalId();
    if (signalId) {
      this.store.dispatch(SignalReportActions.signalBaseDataFetchRequested({ signalId }));
    }
  }

  goToStep(step: Step) {
    const section = this.sections().find(section => section.sectionData().anchorId === step.anchorId);

    if (section === undefined) {
      return;
    }

    if (!section.expanded()) {
      section.expand();
    }

    const anchor = this.anchors.find(a => a.nativeElement.id === step.anchorId)!.nativeElement;

    setTimeout(() => {
      anchor.scrollIntoView({ behavior: 'smooth' });
    }, 150);
  }

  onScroll(): void {
    const mainAnchor = this.findScrolledAnchor();
    if (mainAnchor.length > 0) {
      const step = this.steps.find(s => s.anchorId === mainAnchor);
      if (step) {
        this.stepper().setScrolledStep(step);
      }
    }
  }

  onSectionExpansionToggled(): void {
    setTimeout(() => {
      this.onScroll();
    }, 200);
  }

  private findScrolledAnchor() {
    const container = this.container();
    const container_rect = container.nativeElement.getBoundingClientRect();

    const container_middle = (container_rect.bottom - container_rect.top) / 2;

    let mainAnchor = '';

    this.anchors.forEach(anchor => {
      if (anchor.nativeElement.getBoundingClientRect().top < container_middle) {
        mainAnchor = anchor.nativeElement.id;
      }
    });
    return mainAnchor;
  }
}
