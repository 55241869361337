import { Progress } from '@app-types/progress.interface';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { AStrionSignalsStatusMap } from '@features/signals-status/shared/interface/astrion-signals-status.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AStrionSignalUploadResult } from '../interface/astrion-signal-upload-result.interface';
import { AStrionSignal, AStrionSignalId } from '../interface/astrion-signal.interface';

export const SignalsActions = createActionGroup({
  source: 'Signals',
  events: {
    noops: emptyProps(),

    sensorSignalsFetchRequested: props<{ sensorId: SensorId }>(),
    sensorSignalsFetchFailed: emptyProps(),
    sensorSignalsFetched: props<{ sensorId: SensorId; signals: AStrionSignal[] }>(),

    signalFetchRequested: props<{ signalId: AStrionSignalId }>(),
    signalFetchFailed: emptyProps(),
    signalFetched: props<{ signal: AStrionSignal }>(),

    signalDeletionRequested: props<{ signal: AStrionSignal }>(),
    signalDeleted: props<{ signalId: AStrionSignalId; sensorId: SensorId }>(),
    signalDeletionFailed: emptyProps(),

    signalUpdateRequested: props<{ signal: AStrionSignal }>(),
    signalUpdated: props<{ signal: AStrionSignal }>(),
    signalUpdateFailed: props<{ signal: AStrionSignal }>(),

    signalsUploadRequested: props<{ sensorId: SensorId; files: File[] }>(),
    signalsUploadStarted: emptyProps(),
    signalsUploadMadeProgress: props<Progress>(),
    signalsUploaded: props<{ uploads: AStrionSignalUploadResult[]; sensorId: SensorId }>(),
    signalsUploadFinished: props<{ uploads: AStrionSignalUploadResult[]; sensorId: SensorId }>(),
    signalsUploadFailed: emptyProps(),
    signalsUploadCancellationRequested: emptyProps(),
    signalsUploadCanceled: emptyProps(),

    signalDataFetchRequested: props<{ signalId: AStrionSignalId }>(),

    signalDownloadRequested: props<{ signalId: AStrionSignalId }>(),
    signalDownloaded: emptyProps(),
    signalDownloadFailed: emptyProps(),

    signalsStatusFetchRequested: props<{ signalIds: AStrionSignalId[] }>(),
    sensorStatusFetchRequested: props<{ sensorId: SensorId }>(),
    signalsStatusFetched: props<{ statuses: AStrionSignalsStatusMap }>(),
    signalsStatusFetchFailed: emptyProps(),
  },
});
