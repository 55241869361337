@let _expanded = expanded();

<a class="flex" [class.flex-row]="_expanded" [class.gap-4]="_expanded" [routerLink]="['/']">
  <app-loading-logo [rotating]="true" speed="very-very-slow" class="h-10 w-10" />
  @if (_expanded) {
    <div class="my-auto">
      <span class="text-xl font-normal">AStrion</span>
    </div>
  }
</a>
