import { Injectable } from '@angular/core';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';

import { DataValidationApiService } from '../services/data-validation-api.service';
import { mapDataValidationFromDto } from '../utils/data-validation-mapping';
import { DataValidationActions } from './data-validation.actions';

@Injectable()
export class DataValidationEffects {
  constructor(
    private actions$: Actions,
    private api: DataValidationApiService
  ) {}

  tryFetchSignalDataValidationEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.dataValidationFetchRequested),
      switchMap(({ signalId }) =>
        this.api.getDataValidation(signalId).pipe(
          map(dataValidationDto =>
            DataValidationActions.dataValidationFetched({
              signalId,
              dataValidation: mapDataValidationFromDto(dataValidationDto),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? DataValidationActions.dataValidationNotFound({ signalId })
              : DataValidationActions.dataValidationFetchFailed({ signalId });
          })
        )
      )
    );
  });
}
