import { Injectable, Signal } from '@angular/core';
import { Guid } from '@app-types/guid.type';
import { TrajectoryService } from '@features/sensor-trajectories/services/trajectory/trajectory.service';
import {
  trendCompositeKey,
  TrendType,
} from '@features/sensor-trajectories/shared/interfaces/trajectory-trend.interface';
import { Trajectory, TrajectoryType } from '@features/sensor-trajectories/shared/interfaces/trajectory.interface';
import {
  filterByDetectionPercentage,
  filterByFrequency,
} from '@features/sensor-trajectories/shared/utils/trajectories-filters';
import { ComponentStore } from '@ngrx/component-store';
import { FilterModel, OptionFilterModel } from '@shared/interfaces/filter-model';
import { StatefulMap } from '@tools/utilities/stateful-map';

type TrendMap<T> = StatefulMap<TrendType, T>;

export interface Trend {
  trajectory: Trajectory;
  trendType: TrendType;
}

export type TrendSelectionState = Map<TrendType, { partial: boolean; all: boolean }>;

interface SensorTrajectoriesState {
  trajectoryType: TrajectoryType;
  selected: TrendMap<Set<Guid>>;
  nameFilter: OptionFilterModel | undefined;
  frequencyFilter: FilterModel | undefined;
  detectionPercentageFilter: FilterModel | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class SensorTrajectoriesStore extends ComponentStore<SensorTrajectoriesState> {
  constructor(private trajectoryService: TrajectoryService) {
    super({
      trajectoryType: TrajectoryType.PeakTrajectory,
      selected: initialSelected(),
      nameFilter: undefined,
      frequencyFilter: undefined,
      detectionPercentageFilter: undefined,
    });
  }

  // internal state
  readonly trajectoryType = this.selectSignal(({ trajectoryType }) => trajectoryType);
  readonly selected = this.selectSignal(({ selected }) => selected);
  readonly filters = this.selectSignal(({ nameFilter, frequencyFilter, detectionPercentageFilter }) => ({
    nameFilter,
    frequencyFilter,
    detectionPercentageFilter,
  }));

  // all trajectories
  readonly trajectories = this.trajectoryService.selectTrajectories();
  readonly hasPeakTrajectories = this.selectSignal(this.trajectories, trajectories => !!trajectories?.peakTrajectories);
  readonly hasHarmonicTrajectories = this.selectSignal(
    this.trajectories,
    trajectories => !!trajectories?.harmonicTrajectories
  );

  readonly visualizedCurveIds = this.trajectoryService.selectVisualizedCurveIds();

  readonly selectedTrajectoryType = this.selectSignal(
    this.trajectoryType,
    this.hasPeakTrajectories,
    this.hasHarmonicTrajectories,
    (
      trajectoryType,
      hasPeakTrajectories,
      hasHarmonicTrajectories
    ): TrajectoryType.PeakTrajectory | TrajectoryType.HarmonicTrajectory | TrajectoryType.None => {
      switch (trajectoryType) {
        case TrajectoryType.HarmonicTrajectory:
          if (hasHarmonicTrajectories) {
            return TrajectoryType.HarmonicTrajectory;
          } else if (hasPeakTrajectories) {
            return TrajectoryType.PeakTrajectory;
          } else {
            return TrajectoryType.None;
          }
        default:
          if (hasPeakTrajectories) {
            return TrajectoryType.PeakTrajectory;
          } else if (hasHarmonicTrajectories) {
            return TrajectoryType.HarmonicTrajectory;
          } else {
            return TrajectoryType.None;
          }
      }
    }
  );

  // trajectories of the asked type
  readonly filteredTrajectoriesByType = this.selectSignal(
    this.trajectories,
    this.selectedTrajectoryType,
    (trajectories, selectedTrajectoryType) => {
      if (!trajectories) {
        return [];
      }

      switch (selectedTrajectoryType) {
        case TrajectoryType.PeakTrajectory:
          return trajectories.peakTrajectories ?? [];
        case TrajectoryType.HarmonicTrajectory:
          return trajectories.harmonicTrajectories ?? [];
        default:
          return [];
      }
    }
  );

  // Apply all filters
  readonly filteredTrajectories = this.selectSignal(
    this.filteredTrajectoriesByType,
    this.filters,
    (filteredTrajectoriesByType, { nameFilter, frequencyFilter, detectionPercentageFilter }) =>
      filterByNameOption(
        filterByDetectionPercentage(
          filterByFrequency(filteredTrajectoriesByType, frequencyFilter),
          detectionPercentageFilter
        ),
        nameFilter
      ) ?? []
  );

  // Filters values
  readonly filtersValues: Signal<Map<'frequency' | 'detectionPercentage', number[]>> = this.selectSignal(
    this.filteredTrajectoriesByType,
    trajectories =>
      new Map([
        ['frequency', trajectories.map(t => t.frequency)],
        ['detectionPercentage', trajectories.map(t => t.detectionPercentage)],
      ])
  );

  // Number of trajectories
  private readonly nFilteredTrajectories = this.selectSignal(
    this.filteredTrajectories,
    filteredTrajectories => filteredTrajectories.length
  );

  // Selection
  private readonly allSelectedByTrendType = this.selectSignal(
    this.selected,
    this.nFilteredTrajectories,
    (selected, nTrajectories) => selected.mapValues(set => set.size >= nTrajectories)
  );
  private readonly partiallySelectedByTrendType = this.selectSignal(
    this.selected,
    this.nFilteredTrajectories,
    (selected, nTrajectories) => selected.mapValues(set => set.size > 0 && set.size < nTrajectories)
  );

  readonly allSelectedTrends: Signal<Trend[]> = this.selectSignal(
    this.selected,
    this.filteredTrajectories,
    (selected, trajectories) =>
      selected.reduce((array, set, trendType) => {
        const selectedTrajectories = trajectories.filter(trajectory => set.has(trajectory.id));
        array.push(
          ...selectedTrajectories.map(trajectory => ({
            trendType,
            trajectory,
          }))
        );
        return array;
      }, [] as Trend[])
  );
  private readonly selectedTrendsCount = this.selectSignal(this.allSelectedTrends, trends => trends.length);
  readonly hasSelected = this.selectSignal(this.selectedTrendsCount, count => count > 0);

  readonly trendsSelectionState: Signal<TrendSelectionState> = this.selectSignal(
    this.partiallySelectedByTrendType,
    this.allSelectedByTrendType,
    (partiallyMap, allMap) =>
      partiallyMap.reduce((map, partial, trendType) => {
        map.set(trendType, { partial, all: allMap.get(trendType)! });
        return map;
      }, new Map() as TrendSelectionState)
  );

  // Actions
  selectAllTrend(trendType: TrendType) {
    const visualizedCurveIds = this.visualizedCurveIds();
    const trajectories = this.filteredTrajectories().filter(
      t => !visualizedCurveIds.has(trendCompositeKey(t, trendType))
    );
    this.patchState(state => ({
      selected: state.selected.set(trendType, new Set(trajectories.map(t => t.id))),
    }));
  }

  unselectAllTrend(trendType: TrendType) {
    this.patchState(state => ({
      selected: state.selected.set(trendType, new Set()),
    }));
  }

  unselectAll() {
    this.patchState({
      selected: initialSelected(),
    });
  }

  setTrajectoryType(trajectoryType: TrajectoryType) {
    const prevTrajectory = this.selectedTrajectoryType();
    this.patchState({
      trajectoryType,
    });
    if (this.selectedTrajectoryType() !== prevTrajectory) {
      this.unselectAll();
    }
  }

  isVisualized(trendType: TrendType, trajectory: Trajectory) {
    return this.visualizedCurveIds().has(trendCompositeKey(trajectory, trendType));
  }

  toggle(trendType: TrendType, trajectory: Trajectory) {
    if (!this.isVisualized(trendType, trajectory)) {
      this.patchState(state => ({
        selected: state.selected.update(trendType, set => {
          const newSet = new Set(set);
          if (!newSet.delete(trajectory.id)) {
            newSet.add(trajectory.id);
          }
          return newSet;
        }),
      }));
    }
  }

  setFrequencyFilter(frequencyFilter: FilterModel | undefined) {
    this.patchState({ frequencyFilter });
    this.cleanSelectionAfterFilter();
  }

  setDetectionPercentageFilter(detectionPercentageFilter: FilterModel | undefined) {
    this.patchState({ detectionPercentageFilter });
    this.cleanSelectionAfterFilter();
  }

  setNameFilter(nameFilter: OptionFilterModel | undefined) {
    this.patchState({ nameFilter });
    this.cleanSelectionAfterFilter();
  }

  private cleanSelectionAfterFilter() {
    const filteredTrajectories = this.filteredTrajectories();
    const ids = new Set(filteredTrajectories.map(t => t.id));
    this.patchState(state => ({
      selected: state.selected.mapValues(set => new Set([...set].filter(id => ids.has(id)))),
    }));
  }
}

const initialSelected = () => {
  return StatefulMap.fromEntries<TrendType, Set<Guid>>([
    [TrendType.Frequency, new Set()],
    [TrendType.Energy, new Set()],
    [TrendType.HarmonicsCount, new Set()],
    [TrendType.AverageHarmonicEnergy, new Set()],
    [TrendType.Regularity, new Set()],
    [TrendType.THD, new Set()],
  ]);
};

const filterByNameOption = (trajectories: Trajectory[] | null, nameFilter: OptionFilterModel | undefined) => {
  if (!trajectories) {
    return null;
  }
  if (
    nameFilter?.valuedOptions.find(o => o.id === 1)?.value === false &&
    nameFilter?.valuedOptions.find(o => o.id === 2)?.value === false
  ) {
    return [];
  }
  let filteredTrajectories = trajectories;
  if (nameFilter?.valuedOptions.find(o => o.id === 1)?.value) {
    filteredTrajectories = filteredTrajectories.filter(t => t.isLabeled);
  }
  if (nameFilter?.valuedOptions.find(o => o.id === 2)?.value) {
    filteredTrajectories = filteredTrajectories.filter(t => !t.isLabeled);
  }

  return filteredTrajectories;
};
