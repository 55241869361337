@let _icon = icon();
@let _canEdit = canEdit();

<mat-card class="bg-scheme-surface">
  <mat-card-header>
    <mat-card-title>{{ overviewTitle() }}</mat-card-title>
    @if (_canEdit) {
      <button mat-icon-button class="absolute inset-y-1 end-0" (click)="editClicked.emit()">
        <mat-icon>edit</mat-icon>
      </button>
    }
  </mat-card-header>
  <mat-card-content class="h-[72px] p-[12px]">
    <div class="mt-1 flex select-none flex-row justify-between">
      <ng-content />
      @if (_icon) {
        <mat-icon class="fg-primary-30">{{ icon() }}</mat-icon>
      }
    </div></mat-card-content
  >
</mat-card>
