import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EquipmentId } from '@features/equipments/shared/interfaces/equipment.interface';
import { Sensor, SensorId, SensorPayload } from '@features/sensors/shared/interfaces/sensor.interface';
import { SensorsApiService } from '@features/sensors/shared/services/sensors-api.service';
import { MaterialModule } from '@modules/material.module';

export interface SensorDialogData {
  equipmentId: EquipmentId;
  sensor?: Sensor;
}

@Component({
  selector: 'app-create-sensor-dialog',
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule],
  providers: [SensorsApiService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sensor-dialog.component.html',
  styles: ``,
})
export class SensorDialogComponent {
  existingSensorId: SensorId | undefined;
  okButtonText = 'Create';
  titleText = 'New sensor';

  sensorForm = new FormGroup({
    name: new FormControl<string | null>(null, Validators.required),
    type: new FormControl<string | null>(null, Validators.required),
    model: new FormControl<string | null>(null),
    component: new FormControl<string | null>(null),
    orientation: new FormControl<string | null>(null),
    collectPoint: new FormControl<string | null>(null),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SensorDialogData,
    private dialogRef: MatDialogRef<SensorDialogComponent>
  ) {
    if (data.sensor) {
      this.existingSensorId = data.sensor.id;
      this.sensorForm = new FormGroup({
        name: new FormControl<string | null>(data.sensor.name, Validators.required),
        type: new FormControl<string | null>(data.sensor.sensorType, Validators.required),
        model: new FormControl<string | null>(data.sensor.model),
        component: new FormControl<string | null>(data.sensor.component),
        orientation: new FormControl<string | null>(data.sensor.orientation),
        collectPoint: new FormControl<string | null>(data.sensor.collectPoint),
      });
      this.okButtonText = 'Update';
      this.titleText = 'Edit sensor';
    }
  }

  async onSubmit() {
    const payload: SensorPayload = {
      id: this.existingSensorId,
      equipmentId: this.data.equipmentId,
      name: this.sensorForm.value.name!,
      sensorType: this.sensorForm.value.type!,
      model: this.sensorForm.value.model!,
      component: this.sensorForm.value.component!,
      orientation: this.sensorForm.value.orientation!,
      collectPoint: this.sensorForm.value.collectPoint!,
    };
    this.dialogRef.close(payload);
  }
}
