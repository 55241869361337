import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import {
  AStrionSignalUploadResult,
  AStrionSignalUploadStatus,
  isSuccess,
} from '@features/signals/shared/interface/astrion-signal-upload-result.interface';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-upload-result-list',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  templateUrl: './upload-result-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadResultListComponent {
  public uploads = input.required<AStrionSignalUploadResult[]>();

  isSuccess = isSuccess;
  color(status: AStrionSignalUploadStatus): string {
    return isSuccess(status) ? '#749e31' : '#9e313e';
  }

  icon(status: AStrionSignalUploadStatus): string {
    return isSuccess(status) ? 'task' : 'error';
  }

  statusMessage(status: AStrionSignalUploadStatus): string {
    switch (status) {
      case AStrionSignalUploadStatus.Success:
        return 'Upload successful';
      case AStrionSignalUploadStatus.InvalidDataContent:
        return 'Invalid signal data.';
      case AStrionSignalUploadStatus.InvalidDataType:
        return 'Unknown data type in signal.';
      case AStrionSignalUploadStatus.InvalidFileContent:
        return 'Unknown or invalid file format.';
      case AStrionSignalUploadStatus.InvalidSamplingFrequency:
        return 'Invalid sampling frequency provided.';
      case AStrionSignalUploadStatus.TooManySamples:
        return 'Signal has too many samples. The limit is 600 000 for now.';
      case AStrionSignalUploadStatus.DifferentSamplingCondition:
        return 'Signal has not same sampling frequency or sample counts than the others.';
      default:
        return 'Unknown error.';
    }
  }
}
