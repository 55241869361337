import { NgModule } from '@angular/core';
import { CUSTOM_COLORMAPS } from '@astrion-webtools/graph';
import { RealtimeModule } from '@features/realtime/realtime.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { faultDetectionColorMap, stationarityColorMap } from './colormaps';
import { DataValidationRealtimeRegistrationService } from './shared/realtime/data-validation-realtime-service';
import { DataValidationSensorApiService } from './shared/services/data-validation-sensor-api.service';
import { ValidationFlagsApiService } from './shared/services/validation-flags-api.service';
import { DataValidationEffects } from './shared/store/data-validation.effects';
import { dataValidationFeature } from './shared/store/data-validation.feature';
import { DataValidationSamplingTestEffects } from './shared/store/sampling-test/data-validation.sampling-test.effects';
import { DataValidationStationarityEffects } from './shared/store/stationarity/data-validation.stationarity.effects';
import { DataValidationTimeSaturationEffects } from './shared/store/time-saturation/data-validation.time-saturation.effects';

@NgModule({
  declarations: [],
  providers: [
    ValidationFlagsApiService,
    DataValidationSensorApiService,
    {
      provide: CUSTOM_COLORMAPS,
      multi: true,
      useValue: faultDetectionColorMap,
    },
    {
      provide: CUSTOM_COLORMAPS,
      multi: true,
      useValue: stationarityColorMap,
    },
  ],
  imports: [
    StoreModule.forFeature(dataValidationFeature),
    EffectsModule.forFeature([
      DataValidationEffects,
      DataValidationTimeSaturationEffects,
      DataValidationSamplingTestEffects,
      DataValidationStationarityEffects,
    ]),
    RealtimeModule.forFeature(DataValidationRealtimeRegistrationService),
  ],
})
export class DataValidationModule {}
