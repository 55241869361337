import { Routes } from '@angular/router';

import { EquipmentViewComponent } from './views/equipment-view/equipment-view.component';

export const EQUIPMENTS_ROUTES: Routes = [
  {
    path: ':id',
    component: EquipmentViewComponent,
  },
];
