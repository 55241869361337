import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BinaryFieldStorage, HttpWrapperService, ImageFieldStorage, IndexedDbRowId } from '@astrion-webtools/graph';
import environment from '@environments/environment.json';
import { Base64Data } from '@shared/interfaces/image-bytes';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataProxyApiService {
  constructor(
    private httpClient: HttpClient,
    private backgroundApi: HttpWrapperService
  ) {}

  private static BASE_DATA_PROXY_URL = `${environment.BASE_API_URL}/proxy`;

  getDataBytes(contentPath: string, storage: BinaryFieldStorage): Observable<IndexedDbRowId> {
    return this.backgroundApi.get<IndexedDbRowId>(
      `${DataProxyApiService.BASE_DATA_PROXY_URL}/computed/${contentPath}`,
      {
        storage,
      }
    );
  }

  getImageBlob(contentPath: string, storage: ImageFieldStorage): Observable<IndexedDbRowId> {
    return this.backgroundApi.get<IndexedDbRowId>(
      `${DataProxyApiService.BASE_DATA_PROXY_URL}/image/${contentPath}?blob=true`,
      {
        storage,
      }
    );
  }

  fetchImageAsBase64(contentPath: string): Observable<Base64Data> {
    return this.httpClient.request('get', `${DataProxyApiService.BASE_DATA_PROXY_URL}/image/${contentPath}`, {
      responseType: 'text',
    });
  }
}
