import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createReducer, on } from '@ngrx/store';
import { LoadingState } from '@shared/interfaces/loading-state';

import { DataValidation } from '../interface/data-validation';
import { DataValidationActions } from './data-validation.actions';
import { DATA_VALIDATION_INITIAL_STATE, DataValidationState } from './data-validation.state';
import { samplingTestReducer } from './sampling-test/data-validation.sampling-test.reducer';
import { stationarityReducer } from './stationarity/data-validation.stationarity.reducer';
import { timeSaturationReducer } from './time-saturation/data-validation.time-saturation.reducer';

export const reducer = createReducer(
  DATA_VALIDATION_INITIAL_STATE,
  on(
    DataValidationActions.dataValidationReset,
    (_: DataValidationState, { signalId }: { signalId: AStrionSignalId | null }): DataValidationState => ({
      ...DATA_VALIDATION_INITIAL_STATE,
      signalId,
    })
  ),
  on(
    DataValidationActions.dataValidationFetchRequested,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...DATA_VALIDATION_INITIAL_STATE,
        signalId,
        loadingState: LoadingState.Loading,
      };
    }
  ),
  on(
    DataValidationActions.dataValidationFetched,
    (
      state: DataValidationState,
      { signalId, dataValidation }: { signalId: AStrionSignalId; dataValidation: DataValidation }
    ): DataValidationState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...state,
        ...dataValidation,
      };
    }
  ),
  on(
    DataValidationActions.dataValidationNotFound,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...DATA_VALIDATION_INITIAL_STATE,
        loadingState: LoadingState.NotFound,
      };
    }
  ),
  on(
    DataValidationActions.dataValidationFetchFailed,
    (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...DATA_VALIDATION_INITIAL_STATE,
        loadingState: LoadingState.Error,
      };
    }
  ),
  ...timeSaturationReducer(),
  ...samplingTestReducer(),
  ...stationarityReducer()
);
