import { Component, computed, input, output } from '@angular/core';
import { OverviewCardComponent } from '@components/overview-card/overview-card.component';
import { Equipment } from '@features/equipments/shared/interfaces/equipment.interface';
import { TruncatableFieldComponent } from '@components/truncatable-field/truncatable-field.component';

@Component({
  selector: 'app-equipment-overview',
  standalone: true,
  imports: [OverviewCardComponent, TruncatableFieldComponent],
  templateUrl: './equipment-overview.component.html',
  styles: ``,
})
export class EquipmentOverviewComponent {
  equipment = input.required<Equipment>();
  canEdit = input.required<boolean>();
  sensorsCount = computed(() => this.equipment().sensors.length);

  editClicked = output();
}
