import { Injectable, Signal } from '@angular/core';
import { sensorGraphsFeature } from '@features/sensor-graphs/shared/store/sensor-graphs.feature';
import { SensorSignalsActions } from '@features/sensor-signals/shared/store/sensor-signals.actions';
import { TrendType } from '@features/sensor-trajectories/shared/interfaces/trajectory-trend.interface';
import {
  HarmonicTrajectory,
  PeakTrajectory,
  TrajectoriesList,
  Trajectory,
  TrajectoryType,
} from '@features/sensor-trajectories/shared/interfaces/trajectory.interface';
import { SensorTrajectoriesActions } from '@features/sensor-trajectories/shared/store/sensor-trajectories.actions';
import { sensorTrajectoriesFeature } from '@features/sensor-trajectories/shared/store/sensor-trajectories.feature';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { Store } from '@ngrx/store';

@Injectable()
export class TrajectoryService {
  constructor(private store: Store) {}

  setCurrentSensorTrackingAsDirty(sensorId: SensorId) {
    this.store.dispatch(SensorSignalsActions.flagSensorTrackingAsDirtyRequested({ sensorId }));
  }

  fetchTrajectories(sensorId: SensorId) {
    this.store.dispatch(SensorTrajectoriesActions.trajectoriesFetchRequested({ sensorId }));
  }

  selectPeakTrajectories() {
    return this.store.selectSignal(sensorTrajectoriesFeature.selectPeakTrajectories);
  }

  selectHarmonicTrajectories() {
    return this.store.selectSignal(sensorTrajectoriesFeature.selectHarmonicTrajectories);
  }

  selectTrajectoryOfType(trajectoryType: TrajectoryType): Signal<Trajectory[] | null> {
    switch (trajectoryType) {
      case TrajectoryType.PeakTrajectory:
        return this.selectPeakTrajectories();
      case TrajectoryType.HarmonicTrajectory:
        return this.selectHarmonicTrajectories();
      default:
        throw Error('Invalid trajectory type');
    }
  }

  selectTrajectories(): Signal<TrajectoriesList | null> {
    return this.store.selectSignal(sensorTrajectoriesFeature.selectTrajectories);
  }

  visualizeTrend(sensorId: SensorId, trendType: TrendType, trajectory: Trajectory) {
    this.store.dispatch(
      SensorTrajectoriesActions.trajectoriesTrendVisualizeRequested({ sensorId, trajectory, trendType })
    );
  }

  getPeakTrendContentPath(trajectory: PeakTrajectory, trendType: TrendType): string {
    switch (trendType) {
      case TrendType.Frequency:
        return trajectory.frequencyTrendMiniatureContentPath;
      case TrendType.Energy:
        return trajectory.energyTrendMiniatureContentPath;
      default:
        throw new Error('Invalid trend type for peak.');
    }
  }

  getHarmonicTrendContentPath(trajectory: HarmonicTrajectory, trendType: TrendType): string {
    switch (trendType) {
      case TrendType.Frequency:
        return trajectory.frequencyTrendMiniatureContentPath;
      case TrendType.Energy:
        return trajectory.energyTrendMiniatureContentPath;
      case TrendType.HarmonicsCount:
        return trajectory.harmonicsCountTrendMiniatureContentPath;
      case TrendType.AverageHarmonicEnergy:
        return trajectory.averageHarmonicEnergyTrendMiniatureContentPath;
      case TrendType.Regularity:
        return trajectory.regularityTrendMiniatureContentPath;
      case TrendType.THD:
        return trajectory.thdTrendMiniatureContentPath;
    }
  }

  selectVisualizedCurveIds() {
    return this.store.selectSignal(sensorGraphsFeature.selectCurveIdsSet);
  }
}
