import { Guid } from '@app-types/guid.type';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';

export type AStrionSignalId = Guid;

export interface AStrionSignal {
  id: AStrionSignalId;
  name: string;
  sensorId: SensorId;
  createdAt: Date;
  date?: Date;
  samplingFrequency: number;
  samplesCount: number;
  description: string;
}

export const getDuration = (signal: AStrionSignal): number => {
  return signal.samplesCount / signal.samplingFrequency;
};

export interface AStrionSignalPayload {
  name: string;
}

export interface AStrionSignalDto {
  id: AStrionSignalId;
  name: string;
  sensorId: SensorId;
  createdAt: string;
  date?: string;
  samplingFrequency: number;
  samplesCount: number;
  description: string;
}

export interface UploadResponseAStrionSignalDto {
  name: string;
  status: string;
  data?: AStrionSignalDto;
}
