import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-overview-card',
  templateUrl: './overview-card.component.html',
  standalone: true,
  imports: [MaterialModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewCardComponent {
  overviewTitle = input.required<string>();
  icon = input<string | undefined>();
  canEdit = input<boolean>(false);

  editClicked = output();
}
