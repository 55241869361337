@let _expanded = expanded();

<div class="py-2" [class]="_expanded ? 'px-4' : 'px-2'">
  @if (isLoggedIn()) {
    @if (user(); as user) {
      <div
        mat-icon-button
        class="primary flex cursor-pointer items-center gap-4 text-center"
        [matMenuTriggerFor]="userMenu"
        (menuOpened)="menuOpened.set(true)"
        (menuClosed)="menuOpened.set(false)">
        <mat-icon
          [matTooltip]="!_expanded ? 'Account' : ''"
          matTooltipPosition="after"
          class="h-[40px] w-[40px] flex-none text-4xl"
          >account_circle</mat-icon
        >

        @if (_expanded) {
          <mat-label mat-input class="flex-1">{{ user.username }}</mat-label>

          <mat-icon matSuffix class="flex-none">
            @if (menuOpened()) {
              unfold_less
            } @else {
              unfold_more
            }
          </mat-icon>
        }
      </div>
      <mat-menu #userMenu="matMenu">
        <ng-template matMenuContent>
          <div [style.width.px]="200">
            <button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon>Logout</button>
          </div>
        </ng-template>
      </mat-menu>
    }
  }
</div>
