import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { DataBytes } from '@shared/interfaces/data-bytes';
import { LoadingState } from '@shared/interfaces/loading-state';

import { PFAThresholds, PMC } from './pfa-pmc-thresholds';

export interface NoiseAreaDto {
  minAmplitudesContentPath: string;
  maxAmplitudesContentPath: string;
  freqMin: number;
  freqMax: number;
}

export interface FusionPeakDto {
  index: number;
  frequency: number;
  amplitude: number;
  meanField: number;
  rms: number;
  psd: number;
  type: string;
  pfa: number;
  localSNR: number;
  relativeBandWidth3dBRatio: number;
  totalQuadraticError: number;
  quadraticError3dB: number;
  bestCycleIndex: number;
  cyclesCount: number;
}

export interface FusionDataDto {
  cyclesCount: number;
  thresholds: PFAThresholds;
  noiseArea: NoiseAreaDto;
  fusionPeaks: FusionPeakDto[];
}

export interface FusionDto {
  progress: string;
  data?: FusionDataDto;
}

export interface NoiseArea {
  minAmplitudes: DataBytes;
  maxAmplitudes: DataBytes;
  freqMin: number;
  freqMax: number;
}

export interface FusionPeak {
  index: number;
  frequency: number;
  amplitude: number;
  meanField: number;
  rms: number;
  psd: number;
  type: string;
  pmc: PMC;
  localSNR: number;
  relativeBandWidth3dBRatio: number;
  totalQuadraticError: number;
  quadraticError3dB: number;
  bestCycleIndex: number;
  cyclesCount: number;
}

export interface FusionData {
  cyclesCount: number;
  thresholds: PFAThresholds;
  noiseArea: NoiseArea;
  peaks: FusionPeak[];
}

export interface Fusion {
  progress: ComputationStepProgress;
  loadingState: LoadingState;
  data?: FusionData;
}

export const defaultFusionWithLoadingState = (loadingState: LoadingState = LoadingState.Unknown): Fusion => ({
  progress: ComputationStepProgress.Unknown,
  loadingState,
});
