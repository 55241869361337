import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { GraphCurve, GraphInput, Line, LineStyle, LineType, Scaling } from '@astrion-webtools/graph';
import { GraphWrapperComponent } from '@components/graph-wrapper/graph-wrapper.component';
import { ShannonTestCriterion } from '@features/data-validation/shared/interface/sampling-test';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';

@Component({
  selector: 'app-shannon-test-graph',
  standalone: true,
  imports: [GraphWrapperComponent],
  templateUrl: './shannon-test-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShannonTestGraphComponent {
  filename = input.required<string>();

  linearTest = input.required<ShannonTestCriterion>();
  dbTest = input.required<ShannonTestCriterion>();
  noiseTest = input.required<ShannonTestCriterion>();

  dbScale = Scaling.dB;
  linearScale = Scaling.None;

  linearCurve = computed<GraphCurve | undefined>(() => {
    const linearTest = this.linearTest();

    const dataId = linearTest.spectrumAmplitudes.data;

    if (dataId == undefined) {
      return undefined;
    }

    return {
      id: 'linear',
      color: '#0A6847',
      data: {
        indexedDb: {
          id: dataId,
          valuesField: 'data',
          xMinField: 'xMin',
          xMaxField: 'xMax',
        },
      },
    };
  });

  noiseCurve = computed<GraphCurve | undefined>(() => {
    const noiseTest = this.noiseTest();

    const dataId = noiseTest.spectrumAmplitudes.data;

    if (dataId === undefined) {
      return undefined;
    }

    return {
      id: 'noise',
      color: '#952020',
      data: {
        indexedDb: {
          id: dataId,
          valuesField: 'data',
          xMinField: 'xMin',
          xMaxField: 'xMax',
        },
      },
    };
  });

  data = computed<GraphInput>(() => {
    const curves: GraphCurve[] = [];

    const linearCurve = this.linearCurve();
    const noiseCurve = this.noiseCurve();

    if (linearCurve !== undefined) {
      curves.push(linearCurve);
    }

    if (noiseCurve !== undefined) {
      curves.push(noiseCurve);
    }

    return {
      dbName: ASTRION_INDEXEDDB_NAME,
      storeName: ASTRION_INDEXEDDB_TABLES.samplingTests,
      curves,
    };
  });

  lines = computed<Line[]>(() => {
    const linearTest = this.linearTest();
    const dbTest = this.dbTest();
    const noiseTest = this.noiseTest();

    return [
      {
        type: LineType.Horizontal,
        value: linearTest.result.amplitude,
        style: LineStyle.Dash,
        color: '#0A6847',
      },
      {
        type: LineType.Vertical,
        value: linearTest.result.frequency,
        style: LineStyle.Dash,
        color: '#0A6847',
        legend: `Linear: ${linearTest.result.frequency.toFixed(2)} Hz`,
      },
      {
        type: LineType.Horizontal,
        value: dbTest.result.amplitude,
        scale: Scaling.dB,
        style: LineStyle.DashLong,
        color: '#3050ff',
      },
      {
        type: LineType.Vertical,
        value: dbTest.result.frequency,
        style: LineStyle.DashLong,
        color: '#3050ff',
        legend: `Decibel: ${dbTest.result.frequency.toFixed(2)} Hz`,
      },
      {
        type: LineType.Horizontal,
        value: noiseTest.result.amplitude,
        style: LineStyle.DashDot,
        color: '#952020',
      },
      {
        type: LineType.Vertical,
        value: noiseTest.result.frequency,
        style: LineStyle.DashDot,
        color: '#952020',
        legend: `Morpho: ${noiseTest.result.frequency.toFixed(2)} Hz`,
      },
    ];
  });
}
