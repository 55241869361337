<mat-card class="w-full bg-white">
  <mat-card-header class="my-3 flex w-full flex-row gap-2">
    <span class="grow-0 select-none text-xl">
      {{ subSectionTitle() }}
    </span>
    <div class="grow">
      <ng-content select="[section-header]" />
    </div>
  </mat-card-header>
  <mat-card-content class="mt-3">
    <ng-content />
  </mat-card-content>
</mat-card>
