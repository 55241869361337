import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';

import { DataValidation, DataValidationDto } from '../interface/data-validation';
import { mapSamplingTestFromDto } from './sampling-test-mapping';
import { mapStationarityFromDto } from './stationarity-mapping';
import { mapTimeSaturationFromDto } from './time-saturation-mapping';

export const mapDataValidationFromDto = (dto: DataValidationDto): DataValidation => ({
  saturationTest: mapTimeSaturationFromDto(dto.saturationTest),
  samplingTest: mapSamplingTestFromDto(dto.samplingTest, dto.samplingFrequency),
  stationarityTest: mapStationarityFromDto(dto.stationarityTest),
  progress: dto.computationDone ? ComputationStepProgress.Completed : ComputationStepProgress.InProgress,
  loadingState: LoadingState.Loaded,
  samplingFrequency: dto.samplingFrequency,
});
