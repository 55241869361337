@let _sensor = sensor();
@let _isLoading = isLoading();

<div class="flex h-full w-full flex-col content-stretch items-stretch p-0">
  <app-path-bar
    class="flex-none"
    [equipment]="equipment()"
    [sensor]="_sensor"
    (equipmentClicked)="navigateToEquipment($event)"
    (homeClicked)="navigateToRoot()" />
  @if (_sensor) {
    <app-sensor-overview
      [sensor]="_sensor"
      [samplingCondition]="samplingCondition()"
      [canEdit]="canEdit()"
      (editClicked)="edit(_sensor)" />
    <app-sensor class="flex-grow" [sensorSummary]="_sensor" [loading]="_isLoading" />
  } @else {
    @if (_isLoading) {
      <mat-progress-bar class="z-50 w-full" mode="indeterminate" />
    } @else {
      <app-entity-not-found entityName="sensor" [notFound]="true" />
    }
  }
</div>
