<div class="align-center flex h-full w-full flex-row items-center justify-center gap-2">
  @if (loaded()) {
    @if (inProgress()) {
      <app-loading-logo class="h-[48px] w-[48px] align-middle" speed="slow" />
      <span class="text-xl"
        >Computation still in progress.
        @if (!connectedToLiveUpdates()) {
          Try to refresh.
        }
      </span>
    } @else if (notComputed()) {
      <mat-icon class="text-blue-400">database_off</mat-icon>
      <span class="text-xl text-blue-400"
        >According to project configuration, results were not computed and will not be.</span
      >
    } @else if (unknownProgress()) {
      <mat-icon class="text-red-800">help_outline</mat-icon>
      <span class="text-xl text-red-800">Something went wrong, unknown computation progress.</span>
    }
  } @else if (loading()) {
    <app-loading-logo class="h-[48px] w-[48px] align-middle" speed="fast" />
    <span class="text-xl">Results loading…</span>
  } @else if (error()) {
    <mat-icon class="text-red-800">cloud_off</mat-icon>
    <span class="text-xl text-red-800">Something went wrong. Could not retrieve the data from the server.</span>
  } @else if (notFound()) {
    <mat-icon class="text-red-800">cloud_off</mat-icon>
    <span class="text-xl text-red-800">Results are not available on the server or are located in another project.</span>
  }
</div>
