import { IndexedDbRowId } from '@astrion-webtools/graph';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { emptyProps, props } from '@ngrx/store';

import { ShannonTest } from '../../interface/sampling-test';

export function samplingTestActions() {
  return {
    samplingTestFetchRequested: props<{ signalId: AStrionSignalId; samplingFrequency: number }>(),
    samplingTestFetched: props<{ signalId: AStrionSignalId; samplingTest: ShannonTest }>(),
    samplingTestNotFound: props<{ signalId: AStrionSignalId }>(),
    samplingTestFetchFailed: props<{ signalId: AStrionSignalId }>(),

    samplingTestLinearBytesFetchRequested: props<{
      signalId: AStrionSignalId;
      samplingFrequency: number;
      contentPath: string;
    }>(),
    samplingTestLinearBytesFetched: props<{ signalId: AStrionSignalId; dataId: IndexedDbRowId }>(),
    samplingTestLinearBytesNotFound: props<{ signalId: AStrionSignalId }>(),
    samplingTestLinearBytesFetchFailed: props<{ signalId: AStrionSignalId }>(),

    samplingTestdBBytesFetchRequested: props<{
      signalId: AStrionSignalId;
      samplingFrequency: number;
      contentPath: string;
    }>(),
    samplingTestdBBytesFetched: props<{ signalId: AStrionSignalId; dataId: IndexedDbRowId }>(),
    samplingTestdBBytesNotFound: props<{ signalId: AStrionSignalId }>(),
    samplingTestdBBytesFetchFailed: props<{ signalId: AStrionSignalId }>(),

    samplingTestNoiseBytesFetchRequested: props<{
      signalId: AStrionSignalId;
      samplingFrequency: number;
      contentPath: string;
    }>(),
    samplingTestNoiseBytesFetched: props<{ signalId: AStrionSignalId; dataId: IndexedDbRowId }>(),
    samplingTestNoiseBytesNotFound: props<{ signalId: AStrionSignalId }>(),
    samplingTestNoiseBytesFetchFailed: props<{ signalId: AStrionSignalId }>(),

    samplingTestBytesFetchRejected: emptyProps(),
  };
}
