import { NgModule } from '@angular/core';
import { RealtimeModule } from '@features/realtime/realtime.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EquipmentsEffects } from './shared/store/equipments.effects';
import { equipmentsFeature } from './shared/store/equipments.feature';
import { EquipmentRealtimeService } from './shared/realtime/equipments-realtime-service';

@NgModule({
  imports: [
    EffectsModule.forFeature([EquipmentsEffects]),
    StoreModule.forFeature(equipmentsFeature),
    RealtimeModule.forFeature(EquipmentRealtimeService),
  ],
  providers: [EquipmentRealtimeService],
})
export class EquipmentsModule {}
