import {
  HarmonicTrajectory,
  HarmonicTrajectoryDto,
  PeakTrajectory,
  PeakTrajectoryDto,
  SensorTrajectories,
  SensorTrajectoriesDto,
  TrajectoryBase,
  TrajectoryDtoBase,
  TrajectoryType,
} from '../interfaces/trajectory.interface';

const trajectoryName = (t: TrajectoryDtoBase) => (t.labels.length > 0 ? t.labels.map(l => l.name).join(', ') : t.name);

const mapBaseFromDto = (dto: TrajectoryDtoBase): TrajectoryBase => ({
  id: dto.id,
  name: trajectoryName(dto),
  frequency: dto.frequency,
  detectionPercentage: dto.detectionPercentage,
  frequencyTrendMiniatureContentPath: dto.frequencyTrendMiniatureContentPath,
  energyTrendMiniatureContentPath: dto.energyTrendMiniatureContentPath,
  isLabeled: dto.labels.length != 0,
  type: TrajectoryType.Unknown,
});

const mapPeakFromDto = (dto: PeakTrajectoryDto): PeakTrajectory => ({
  ...mapBaseFromDto(dto),
  type: TrajectoryType.PeakTrajectory,
});

const mapHarmonicsFromDto = (dto: HarmonicTrajectoryDto): HarmonicTrajectory => ({
  ...mapBaseFromDto(dto),
  type: TrajectoryType.HarmonicTrajectory,
  harmonicsCountTrendMiniatureContentPath: dto.harmonicsCountTrendMiniatureContentPath,
  averageHarmonicEnergyTrendMiniatureContentPath: dto.averageHarmonicEnergyTrendMiniatureContentPath,
  regularityTrendMiniatureContentPath: dto.regularityTrendMiniatureContentPath,
  thdTrendMiniatureContentPath: dto.thdTrendMiniatureContentPath,
});

export const mapTrajectoriesFromDto = (dto: SensorTrajectoriesDto): SensorTrajectories => ({
  ...dto,
  peakTrajectories: dto.peakTrajectories?.map(mapPeakFromDto) ?? null,
  harmonicTrajectories: dto.harmonicTrajectories?.map(mapHarmonicsFromDto) ?? null,
});
