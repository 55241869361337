import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { Equipment } from '@features/equipments/shared/interfaces/equipment.interface';
import { Sensor } from '@features/sensors/shared/interfaces/sensor.interface';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-path-bar',
  templateUrl: './path-bar.component.html',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .m3-headline-small-button {
        font-size: 24px !important;
        font-weight: 400 !important;
        --mdc-text-button-label-text-size: 24px;
        --mdc-text-button-label-text-weight: 400;
      }
      .m3-headline-small-icon {
        width: 24px !important;
        height: 24px !important;
        font-size: 24px !important;
      }
    `,
  ],
})
export class PathBarComponent {
  equipment = input<Equipment>();
  sensor = input<Sensor>();
  filename = input<string | null>(null);

  equipmentClicked = output<Equipment>();
  sensorClicked = output<Sensor>();
  homeClicked = output();

  loading = computed(() => this.equipment() === null);
  fullFilename = computed(() => this.filename() && this.loading());
}
