import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TruncatePipe } from '@tools/pipes/truncate.pipe';

@Component({
  selector: 'app-truncatable-field',
  templateUrl: './truncatable-field.component.html',
  standalone: true,
  imports: [TruncatePipe, MatTooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TruncatableFieldComponent {
  label = input.required<string>();
  content = input.required<string | null>();
  truncate = input.required<number>();

  displayText = computed(() => {
    return this.label() + ': ' + (this.content() || '-');
  });

  tooltip = computed<string | null>(() => {
    const displayText = this.displayText();
    if (displayText.length > this.truncate()) {
      return this.content();
    } else {
      return null;
    }
  });
}
