import { Injectable } from '@angular/core';
import { RealtimeClientService } from '@features/realtime/shared/services/realtime-client.service';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import { PeakIdentificationActions } from '../store/peak-identification.actions';
import { peakIdentificationFeature } from '../store/peak-identification.feature';
import { PeakIdentificationStepCompletedMessage } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class PeakIdentificationRealtimeRegistrationService {
  constructor(private store: Store) {}

  public registerMessageHandlers(realtimeClient: RealtimeClientService): void {
    realtimeClient.registerMessageHandlers([
      { messageType: 'CyclesComputed', callback: this.cyclesCompletedHandler },
      { messageType: 'FusionComputed', callback: this.fusionCompletedHandler },
      { messageType: 'HarmonicSeriesComputed', callback: this.harmonicSeriesCompletedHandler },
      { messageType: 'HarmonicSeriesCleared', callback: this.harmonicSeriesCompletedHandler },
    ]);
  }

  private cyclesCompletedHandler = async (msg: unknown): Promise<void> => {
    const computationStepCompletedMessage = msg as PeakIdentificationStepCompletedMessage;

    const receivedSignalId = computationStepCompletedMessage.signalId;
    const reportSignalId = await firstValueFrom(this.store.select(peakIdentificationFeature.selectSignalId));

    if (reportSignalId !== receivedSignalId) {
      return;
    }

    this.store.dispatch(PeakIdentificationActions.cyclesFetchRequested({ signalId: receivedSignalId }));
  };

  private fusionCompletedHandler = async (msg: unknown): Promise<void> => {
    const computationStepCompletedMessage = msg as PeakIdentificationStepCompletedMessage;

    const receivedSignalId = computationStepCompletedMessage.signalId;
    const reportSignalId = await firstValueFrom(this.store.select(peakIdentificationFeature.selectSignalId));

    if (reportSignalId !== receivedSignalId) {
      return;
    }

    this.store.dispatch(PeakIdentificationActions.fusionFetchRequested({ signalId: receivedSignalId }));
  };

  private harmonicSeriesCompletedHandler = async (msg: unknown): Promise<void> => {
    const computationStepCompletedMessage = msg as PeakIdentificationStepCompletedMessage;

    const receivedSignalId = computationStepCompletedMessage.signalId;
    const reportSignalId = await firstValueFrom(this.store.select(peakIdentificationFeature.selectSignalId));

    if (reportSignalId !== receivedSignalId) {
      return;
    }

    this.store.dispatch(PeakIdentificationActions.harmonicSeriesFetchRequested({ signalId: receivedSignalId }));
  };
}
