import { Component, input, output } from '@angular/core';
import { MaterialModule } from '@modules/material.module';
import { HomeButtonComponent } from './home-button/home-button.component';
import { ProjectSelectionComponent } from './project-selection/project-selection.component';
import { NavigationListComponent } from './navigation-list/navigation-list.component';
import { AccountMenuComponent } from './account-menu/account-menu.component';

@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [
    MaterialModule,
    HomeButtonComponent,
    ProjectSelectionComponent,
    NavigationListComponent,
    AccountMenuComponent,
  ],
  templateUrl: './sidenav.component.html',
})
export class SidenavComponent {
  expanded = input<boolean>(true);

  expansionButtonToggled = output<void>();
}
