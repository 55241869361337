import { ChangeDetectionStrategy, Component, computed, signal } from '@angular/core';
import { ResultUnavailableComponent } from '@components/result-unavailable/result-unavailable.component';
import { CyclesComponent } from '@features/peak-identification/components/cycles/cycles.component';
import { PeaksAndHarmonicSeriesComponent } from '@features/peak-identification/components/peaks-and-harmonic-series/peaks-and-harmonic-series.component';
import { peakIdentificationFeature } from '@features/peak-identification/shared/store/peak-identification.feature';
import {
  computationCompleted,
  ComputationStep,
  ComputationStepProgress,
} from '@features/signal-report/shared/interface/computation-step-progress';
import { REPORT_NAMES } from '@features/signal-report/shared/store/report-pages';
import { Store } from '@ngrx/store';
import { ReportAnchorDirective } from '@tools/directives/report-anchor.directive';
import { ReportSubSectionComponent } from '../report-sub-section/report-sub-section.component';
import { CyclesSelectionComponent } from '../../../peak-identification/components/cycles/cycles-selection/cycles-selection.component';
import { PeaksHarmonicsSelectionComponent } from '@features/peak-identification/components/peaks-and-harmonic-series/peaks-harmonics-selection/peaks-harmonics-selection.component';
import { signalReportFeature } from '@features/signal-report/shared/store/signal-report.feature';
import {
  ModeName,
  peakModeName,
} from '@features/peak-identification/components/peaks-and-harmonic-series/shared/interfaces';

@Component({
  selector: 'app-report-peak-identification',
  standalone: true,
  imports: [
    CyclesComponent,
    PeaksAndHarmonicSeriesComponent,
    ReportAnchorDirective,
    ResultUnavailableComponent,
    ReportSubSectionComponent,
    CyclesSelectionComponent,
    PeaksHarmonicsSelectionComponent,
  ],
  templateUrl: './report-peak-identification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportPeakIdentificationComponent {
  REPORT_NAMES = REPORT_NAMES;

  public filename = this.store.selectSignal(signalReportFeature.selectNameWithoutExtension);

  public cycles = this.store.selectSignal(peakIdentificationFeature.selectCycles);
  public fusion = this.store.selectSignal(peakIdentificationFeature.selectFusion);
  public harmonicSeries = this.store.selectSignal(peakIdentificationFeature.selectHarmonicSeries);

  public selectedMode = signal<ModeName>(peakModeName);

  public hasHarmonics = computed(() => this.harmonicSeries().progress !== ComputationStepProgress.NotComputed);

  public peaksAndHarmonicsSubsectionTitle = computed(() => {
    let title = 'Resulting peaks';

    if (this.hasHarmonics()) {
      title += ' and harmonic families';
    }

    return title;
  });

  public ready = (step: ComputationStep): boolean => computationCompleted(step);

  constructor(private store: Store) {}
}
