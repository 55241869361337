import { createFeature, createSelector } from '@ngrx/store';

import { reducer } from './sensor-trajectories.reducer';
import { name } from './sensor-trajectories.state';
import { TrajectoriesList } from '../interfaces/trajectory.interface';

export const sensorTrajectoriesFeature = createFeature({
  name,
  reducer,
  extraSelectors: ({ selectPeakTrajectories, selectHarmonicTrajectories }) => ({
    selectTrajectories: createSelector(
      selectPeakTrajectories,
      selectHarmonicTrajectories,
      (peakTrajectories, harmonicTrajectories): TrajectoriesList | null =>
        !!peakTrajectories || !!harmonicTrajectories
          ? {
              peakTrajectories: peakTrajectories,
              harmonicTrajectories: harmonicTrajectories,
            }
          : null
    ),
  }),
});
