<app-graph-wrapper
  [name]="name()"
  [xTitle]="xTitle()"
  [yTitle]="yTitle()"
  [data]="completedData()"
  [showGroupAxis]="showGroupAxis()"
  [graphHeight]="graphHeight()"
  [showLegend]="true"
  [showSettings]="true"
  [xAxisDataIsTimestamps]="true"
  (delete)="this.delete.emit($event)">
  <app-trajectory-graph-settings
    settings
    #settings
    [data]="completedData()"
    [groups]="groups()"
    [visibleGroups]="visibleGroups()"
    [(groupStates)]="groupStates"
    (curvesGrouped)="curvesGrouped($event)"
    (curvesUngrouped)="curvesUngrouped($event)"
    (removeCurve)="removeCurve.emit($event)"
    (focusedCurve)="focusedCurve.set($event)"
    (colorSelected)="onColorSelected($event)" />
</app-graph-wrapper>
