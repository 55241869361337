import { createFeature } from '@ngrx/store';

import { reducer } from './sensors.reducer';
import { extraSelectors } from './sensors.selectors';
import { name } from './sensors.state';

export const sensorsFeature = createFeature({
  name,
  reducer,
  extraSelectors,
});
