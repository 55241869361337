@let _showingAll = showingAll();

@if (cycles().length > 1) {
  <div class="flex flex-row justify-center gap-8">
    <mat-button-toggle-group
      class="h-[32px] items-center"
      [disabled]="_showingAll"
      [value]="activeCycleIndex()"
      (valueChange)="activeCycleIndex.set($event)">
      @for (cycle of cycles(); track cycle.index) {
        <mat-button-toggle [value]="cycle.index">Spectrum {{ cycle.index + 1 }}</mat-button-toggle>
      }
    </mat-button-toggle-group>

    <mat-slide-toggle class="my-auto" [checked]="_showingAll" (change)="showingAll.set($event.checked)">
      Show all
    </mat-slide-toggle>
  </div>
}
