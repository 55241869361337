<div class="flex w-full flex-col gap-4">
  <div class="flex flex-initial flex-col" [appReportAnchor]="REPORT_NAMES.overview.anchors.signal">
    @if (ready(signalBytes())) {
      <app-report-signal-graph [samplesId]="signalBytes()!.data!" [graphTitle]="signalGraphTitle()" />
    } @else {
      <app-result-unavailable [loadableData]="signalBytes() ? signalBytes()! : loadableItem()" class="h-32 w-full" />
    }
  </div>

  <div class="flex flex-initial flex-col" [appReportAnchor]="REPORT_NAMES.overview.anchors.metadata">
    @if (ready(loadableItem())) {
      <app-signal-overview-metadata class="flex-none" [metadata]="signalMetadata()!" />
    } @else {
      <app-result-unavailable [loadableData]="loadableItem()" class="h-32 w-full" />
    }
  </div>
</div>
