import { ItemDescription } from './item-description';

export const enum ProcessingStatus {
  Unknown = 0,
  InProgress = 1,
  Dirty = 2,
  Degraded = 3,
  Completed = 4,
}

export function parseProcessingState(status: ProcessingStatus | undefined): ItemDescription {
  switch (status) {
    case ProcessingStatus.Completed:
      return {
        icon: 'check_circle',
        color: '#749e31',
        tooltip: 'Computation complete',
      };
    case ProcessingStatus.Dirty:
      return {
        icon: 'schedule',
        color: '#165FD5',
        tooltip: 'Recomputation pending',
      };
    case ProcessingStatus.InProgress:
      return {
        icon: 'progress_activity',
        itemClass: 'animate-spin',
        color: '#4b6884',
        tooltip: 'Computation in progress',
      };
    case ProcessingStatus.Degraded:
      return {
        icon: 'running_with_errors',
        color: '#fe621d',
        tooltip: 'Computation not complete',
      };
    case ProcessingStatus.Unknown:
      return {
        icon: 'question_mark',
        color: '#c1bde3',
        tooltip: 'Status is unknown',
      };
    default:
      // Not loaded yet
      return {
        icon: 'pending',
        color: '#e0e0e0',
        tooltip: '',
      };
  }
}
