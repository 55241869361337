import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';

import { ValidationStepStatus, ValidationStepStatusDto } from './validation-step-status';

export enum ValidationFlagKind {
  nonStationnarity,
}

export interface SignalValidationFlags extends ValidationFlags {
  signalId: AStrionSignalId;
}

export interface ValidationFlags {
  nonStationnarity: PercentageFlag;
  shannon: ShannonFlag;
  timeSaturation: Flag;
}

export interface ShannonFlag extends Flag {
  signalTooShort?: boolean;
  dbSuccessed?: boolean;
  linearSuccessed?: boolean;
  noiseSuccessed?: boolean;
}

export interface PercentageFlag extends Flag {
  percentage?: number;
}

export interface Flag {
  status: ValidationStepStatus;
}

const unknownLabel = '';

export function getPercentageLabel(flag: PercentageFlag | undefined) {
  if (flag?.percentage !== undefined) {
    return `${flag.percentage}%`;
  }
  return unknownLabel;
}

export function getTimeSaturationLabel(flag: Flag | undefined) {
  if (!flag || flag.status === ValidationStepStatus.Unknown) {
    return unknownLabel;
  }
  return flag.status === ValidationStepStatus.Success ? 'No time saturation detected' : 'Time saturation detected';
}

export function getShannonLabel(flag: ShannonFlag | undefined) {
  if (!flag || flag.status === ValidationStepStatus.Unknown) {
    return unknownLabel;
  }

  const sampleToken = flag.dbSuccessed && flag.linearSuccessed ? 'Well sampled' : 'Badly sampled';
  const filterToken = flag.noiseSuccessed ? 'filtered' : 'not filtered';
  const aliasingToken =
    flag.linearSuccessed && flag.dbSuccessed
      ? 'no aliasing'
      : flag.noiseSuccessed
        ? `possible ${flag.linearSuccessed ? '' : 'strong '}power aliasing`
        : 'strong power aliasing';

  return flag.signalTooShort ? 'Signal too short' : `${sampleToken}, ${filterToken}, ${aliasingToken}`;
}

const UNKNOWN_FLAG = { status: ValidationStepStatus.Unknown };
export const UNKNOWN_FLAGS = {
  nonStationnarity: UNKNOWN_FLAG,
  shannon: UNKNOWN_FLAG,
  timeSaturation: UNKNOWN_FLAG,
};

export interface ValidationFlagsDto {
  signalId: AStrionSignalId;
  nonStationnarity: PercentageFlagDto;
  shannon: FlagDto;
  timeSaturation: FlagDto;
}

export interface FlagDto {
  status: ValidationStepStatusDto;
}

export interface PercentageFlagDto extends FlagDto {
  percentage?: number;
}

export interface ShannonFlagDto extends FlagDto {
  signalTooShort?: boolean;
  dbSuccessed?: boolean;
  linearSuccessed?: boolean;
  noiseSuccessed?: boolean;
}
