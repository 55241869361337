import { Component, computed, input } from '@angular/core';
import { MaterialModule } from '@modules/material.module';

export interface TileData {
  title: string;
  content: string;
}

@Component({
  selector: 'app-metadata-tile',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './metadata-tile.component.html',
})
export class MetadataTileComponent {
  public data = input.required<TileData>();

  public tileTitle = computed(() => this.data().title);
  public tileContent = computed(() => this.data().content);
}
