<div class="flex h-full w-full items-center justify-center bg-slate-50 p-0">
  <div class="text-center">
    @if (error()) {
      <h1 class="text-4xl font-medium">Error</h1>
      <p class="m-6 text-xl font-medium">
        Something went wrong. The {{ entityName() }} could not be loaded from the server.
      </p>
    } @else if (notFound()) {
      <h1 class="text-4xl font-medium">Not Found</h1>
      <p class="m-6 text-xl font-medium">
        The {{ entityName() }} could not be found, either it is not on the server or it is in another project.
      </p>
    }
    <a mat-raised-button extended [routerLink]="['/']">
      <mat-icon>home</mat-icon>
      Get back to project root
    </a>
  </div>
</div>
