<div class="flex w-full flex-col">
  <app-peaks-and-harmonics-graph
    #graph
    [filename]="filename()"
    [inHarmonicsMode]="harmonicsMode()"
    [fusion]="fusion()"
    [harmonicSeries]="coloredHarmonicSeries()"
    [selectedSeries]="selectedSeries()"
    class="w-full" />

  @if (harmonicsMode()) {
    <app-harmonics-table
      class="mt-2 h-[60vh]"
      [harmonicSeries]="coloredHarmonicSeries()"
      (selectionChanged)="selectedSeries.set($event)" />
  } @else {
    <app-peaks-table
      class="mt-2 h-[60vh]"
      [peaks]="peaks()"
      [filterPredicate]="graph.peaksVisibilityFilter()"
      [additionalColumns]="peaksTableAdditionalColumns()" />
  }
</div>
