import { Component, input } from '@angular/core';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-hidden-harmonics-warning',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './hidden-harmonics-warning.component.html',
})
export class HiddenHarmonicsWarningComponent {
  public hiddenHarmonicsCount = input.required<number>();
}
