import { Injectable } from '@angular/core';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';

import { DataValidationApiService } from '../../services/data-validation-api.service';
import { mapTimeSaturationFromDto } from '../../utils/time-saturation-mapping';
import { DataValidationActions } from '../data-validation.actions';

@Injectable()
export class DataValidationTimeSaturationEffects {
  constructor(
    private actions$: Actions,
    private api: DataValidationApiService
  ) {}

  emitTimeSaturationTestFetchedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.dataValidationFetched),
      map(({ signalId, dataValidation }) =>
        DataValidationActions.timeSaturationTestFetched({
          signalId,
          saturationTest: dataValidation.saturationTest,
        })
      )
    );
  });

  tryFetchSignalTimeSaturationEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.timeSaturationTestFetchRequested),
      switchMap(({ signalId }) =>
        this.api.getTimeSaturationTestData(signalId).pipe(
          map(timeSaturationDto =>
            DataValidationActions.timeSaturationTestFetched({
              signalId,
              saturationTest: mapTimeSaturationFromDto(timeSaturationDto),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? DataValidationActions.timeSaturationTestNotFound({ signalId })
              : DataValidationActions.timeSaturationTestFetchFailed({ signalId });
          })
        )
      )
    );
  });
}
