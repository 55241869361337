@let _sensor = sensor();
@let _canEdit = canEdit();

<span class="m3-title-large flex-none select-none">Sensor overview</span>
<div class="mt-4 flex flex-row gap-2">
  <app-overview-card
    class="w-[480px]"
    overviewTitle="Information"
    [canEdit]="_canEdit"
    (editClicked)="editClicked.emit()">
    <div class="text-sm">
      <app-truncatable-field id="type" label="Type" [content]="_sensor.sensorType" [truncate]="30" />
      <app-truncatable-field id="collectPoint" label="Collect Point" [content]="_sensor.collectPoint" [truncate]="30" />
    </div>
    <div class="text-sm">
      <app-truncatable-field id="component" label="Component" [content]="_sensor.component" [truncate]="30" />
      <app-truncatable-field id="orientation" label="Orientation" [content]="_sensor.orientation" [truncate]="30" />
    </div>
  </app-overview-card>
  <app-overview-card class="w-[281px]" overviewTitle="Number of signals" icon="stacked_line_chart">
    <div data-testid="signals-count" class="m3-headline-large">{{ _sensor.signalsCount }}</div>
  </app-overview-card>
  @if (samplingCondition(); as _samplingCondition) {
    <app-overview-card overviewTitle="Data information" class="ml-auto w-[310px]">
      <div class="m3-body-medium flex w-full flex-row flex-wrap justify-between">
        <div>Samples: {{ _samplingCondition.sampleCount | number }}</div>
        <div>Duration: {{ _samplingCondition.duration | duration }}</div>
        <div>Sample frequency: {{ _samplingCondition.frequency | number }} Hz</div>
      </div>
    </app-overview-card>
  }
</div>
