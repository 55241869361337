import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MaterialModule } from '@modules/material.module';
import { FilterModel } from '@shared/interfaces/filter-model';

export interface FilterDialogData {
  min: number;
  max: number;
  filter?: FilterModel;
}

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MaterialModule, FormsModule, MatSliderModule],
})
export class FilterDialogComponent {
  filter: FilterModel;
  minimum: number;
  maximum: number;

  constructor(
    private dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FilterDialogData
  ) {
    this.minimum = Math.round(this.data.min);
    this.maximum = Math.ceil(this.data.max);
    this.filter = data.filter ? { ...data.filter } : { min: this.minimum, max: this.maximum };
  }

  closeWithFilter() {
    this.dialogRef.close({ min: this.filter.min ?? this.minimum, max: this.filter.max ?? this.maximum });
  }
}
