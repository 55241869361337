<div class="relative h-full w-full">
  @if (canUpload()) {
    <div
      class="absolute inset-0 z-0 flex flex-col items-center justify-center gap-2 rounded-lg border-2 border-dashed border-slate-400 bg-black bg-opacity-20 p-1"
      [ngStyle]="{ opacity: opacity() }"
      [ngClass]="{ 'primary-icon': dragging(), 'text-slate-950': !dragging() }">
      <mat-icon class="h-[70px] w-[70px] text-[70px]">upload_file</mat-icon>
      <span class="text-xl">Drop signal(s) to upload.</span>
    </div>
  } @else {
    <div
      class="absolute inset-0 z-0 flex flex-col items-center justify-center gap-2 rounded-lg border-2 border-dashed border-slate-400 bg-black bg-opacity-10 p-1">
      <mat-icon class="error-icon h-[70px] w-[70px] text-[70px]">block</mat-icon>
      <span class="text-xl">You can't upload here.</span>
    </div>
  }
  <div
    class="z-1 absolute inset-0"
    (dragenter)="onDragEnter()"
    (dragover)="$event.preventDefault()"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"></div>
</div>
