import { Component, computed, input, signal } from '@angular/core';
import { FusionData, FusionPeak } from '@features/peak-identification/shared/interface/fusion';
import { HarmonicSerie } from '@features/peak-identification/shared/interface/harmonic-series';
import { PeaksTableComponent, TablePeakColumnDefinition } from '../peaks-table/peaks-table.component';
import {
  PeaksGraphInteractiveLegendComponent,
  SimplifiedPeak,
} from '../peaks-graph-interactive-legend/peaks-graph-interactive-legend.component';
import { MaterialModule } from '@modules/material.module';
import { GraphWrapperBaseComponent } from '@components/graph-wrapper-base/graph-wrapper-base.component';
import { HarmonicsMarkersComponent } from './harmonics-markers/harmonics-markers.component';
import { GlGraphComponent } from '@astrion-webtools/graph';
import { CommonModule } from '@angular/common';
import { harmonicsModeName, peakModeName, ModeName } from './shared/interfaces';
import { HiddenHarmonicsWarningComponent } from './hidden-harmonics-warning/hidden-harmonics-warning.component';
import { addColorToHarmonicSeries } from './utils/harmonic-utilities';
import { getPeaksTableAdditionalColumns } from './utils/peak-utilities';
import { HarmonicsTableComponent } from './harmonics-table/harmonics-table.component';
import { PeaksAndHarmonicsGraphComponent } from './peaks-and-harmonics-graph/peaks-and-harmonics-graph.component';

@Component({
  selector: 'app-peaks-and-harmonic-series',
  standalone: true,
  imports: [
    CommonModule,
    PeaksTableComponent,
    MaterialModule,
    GlGraphComponent,
    HarmonicsMarkersComponent,
    GraphWrapperBaseComponent,
    PeaksGraphInteractiveLegendComponent,
    HiddenHarmonicsWarningComponent,
    HarmonicsTableComponent,
    PeaksAndHarmonicsGraphComponent,
  ],
  templateUrl: './peaks-and-harmonic-series.component.html',
})
export class PeaksAndHarmonicSeriesComponent {
  public readonly availableModes = [
    { value: peakModeName, name: 'Peaks', checked: signal<boolean>(true) },
    { value: harmonicsModeName, name: 'Harmonic families', checked: signal<boolean>(false) },
  ];

  public filename = input.required<string>();

  public harmonicSeries = input.required<HarmonicSerie[]>();
  public fusion = input.required<FusionData>();

  public selectedMode = input.required<ModeName>();

  public peaksVisibilityFilter = signal<(peak: SimplifiedPeak) => boolean>(() => true);

  public selectedSeries = signal<HarmonicSerie[]>([]);

  public harmonicsMode = computed(() => this.selectedMode() === harmonicsModeName);

  public coloredHarmonicSeries = computed(() => addColorToHarmonicSeries(this.harmonicSeries()));

  public peaks = computed((): FusionPeak[] => this.fusion().peaks);

  public peaksTableAdditionalColumns = computed((): TablePeakColumnDefinition[] =>
    getPeaksTableAdditionalColumns(this.fusion())
  );
}
