import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { ProcessingStatus } from '@shared/interfaces/processing-status';
import { recordFrom } from '@tools/utilities/record-utilities';

import { AStrionSignalsAllStatusDto, AStrionSignalsStatusMap } from './astrion-signals-status.interface';

export const string2Status = (s: string | undefined): ProcessingStatus => {
  switch (s) {
    case 'Completed':
      return ProcessingStatus.Completed;
    case 'InProgress':
      return ProcessingStatus.InProgress;
    case 'Degraded':
    case undefined:
      return ProcessingStatus.Degraded;
    case 'Dirty':
      return ProcessingStatus.Dirty;
    default:
      return ProcessingStatus.Unknown;
  }
};

const combineStatuses = (statuses: ProcessingStatus[]): ProcessingStatus =>
  statuses.reduce((prev, cur) => Math.min(prev, cur), ProcessingStatus.Completed);

export const dto2Map = (dtos: AStrionSignalsAllStatusDto): AStrionSignalsStatusMap => {
  const dvStatuses = dtos.dataValidationStatus?.statuses ?? [];
  const piStatuses = dtos.peakIdentificationStatus?.statuses ?? [];
  const validationFlags = dtos.validationFlags ?? [];

  const ids: AStrionSignalId[] = [
    ...new Set<AStrionSignalId>([
      ...dvStatuses.map(s => s.signalId),
      ...piStatuses.map(s => s.signalId),
      ...validationFlags.map(s => s.signalId),
    ]),
  ];

  return recordFrom(
    ids.map((id: AStrionSignalId) => [
      id as AStrionSignalId,
      {
        status: combineStatuses([
          string2Status(dvStatuses.find(s => s.signalId == id)?.status),
          string2Status(piStatuses.find(s => s.signalId == id)?.status),
        ]),
        validationFlags: validationFlags.find(s => s.signalId == id),
      },
    ])
  );
};
