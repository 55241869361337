import { Injectable, Signal } from '@angular/core';
import { GraphCurve, GraphInput } from '@astrion-webtools/graph';
import { Store } from '@ngrx/store';

import { SensorGraphsActions } from '../store/sensor-graphs.actions';
import { sensorGraphsFeature } from '../store/sensor-graphs.feature';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';

@Injectable({
  providedIn: 'root',
})
export class SensorGraphsService {
  constructor(private store: Store) {}

  addCurve(sensorId: SensorId, curveId: string, curve: GraphCurve) {
    this.store.dispatch(SensorGraphsActions.addCurve({ sensorId, curveId, curve }));
  }

  clearCurves(sensorId: SensorId) {
    this.store.dispatch(SensorGraphsActions.clearCurves({ sensorId }));
  }

  removeCurve(sensorId: SensorId, curveId: string) {
    this.store.dispatch(SensorGraphsActions.removeCurve({ sensorId, curveId }));
  }

  graphInputSignal(sensorId: SensorId): Signal<GraphInput> {
    return this.store.selectSignal(sensorGraphsFeature.selectGraphInput(sensorId));
  }

  curveNameToIdSignal(sensorId: SensorId): Signal<Record<string, string>> {
    return this.store.selectSignal(sensorGraphsFeature.selectCurveNameToId(sensorId));
  }
}
