import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createActionGroup, props } from '@ngrx/store';

import { DataValidation } from '../interface/data-validation';
import { samplingTestActions } from './sampling-test/data-validation.sampling-test.actions';
import { stationarityActions } from './stationarity/data-validation.stationarity.actions';
import { timeSaturationActions } from './time-saturation/data-validation.time-saturation.actions';

export const DataValidationActions = createActionGroup({
  source: 'DataValidation',
  events: {
    dataValidationReset: props<{ signalId: AStrionSignalId | null }>(),

    dataValidationFetchRequested: props<{ signalId: AStrionSignalId }>(),
    dataValidationFetched: props<{ signalId: AStrionSignalId; dataValidation: DataValidation }>(),
    dataValidationNotFound: props<{ signalId: AStrionSignalId }>(),
    dataValidationFetchFailed: props<{ signalId: AStrionSignalId }>(),

    ...timeSaturationActions(),
    ...samplingTestActions(),
    ...stationarityActions(),
  },
});
