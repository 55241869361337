import { Guid } from '@astrion-webtools/graph';
import { EquipmentId } from '@features/equipments/shared/interfaces/equipment.interface';

interface SensorProperties {
  name: string;
  sensorType: string;
  model: string | null;
  component: string | null;
  collectPoint: string | null;
  orientation: string | null;
}

export type SensorId = Guid;

export interface SensorDto extends SensorProperties {
  id: SensorId;
  equipmentId: EquipmentId;
  signalsCount: number;
}

export interface SensorPayload extends SensorProperties {
  id?: SensorId;
  equipmentId: EquipmentId;
}

export interface Sensor extends SensorProperties {
  id: SensorId;
  equipmentId: EquipmentId;
  signalsCount: number;
}

export interface SensorSummary {
  id: SensorId;
  name: string;
  signalsCount: number;
}

export const mapSensorFromDto = (dto: SensorDto): Sensor => ({
  ...dto,
});
