import { Routes } from '@angular/router';

import { OnePageSignalReportComponent } from './views/one-page-signal-report/one-page-signal-report.component';

export const SIGNAL_REPORT_ROUTES: Routes = [
  {
    path: ':id',
    component: OnePageSignalReportComponent,
    data: {
      signalReport: true,
    },
  },
];
