@let _projects = projects();

<div class="flex h-full w-full flex-col items-center justify-center gap-2">
  @if (_projects && _projects.length > 0) {
    <h4 class="text-2xl font-extrabold">Choose a project</h4>

    <div class="flex flex-row items-baseline justify-center gap-2">
      <mat-form-field>
        <mat-label>Project</mat-label>
        <mat-select [(value)]="selectedProject">
          @for (project of _projects; track project.name) {
            <mat-option [value]="project">
              {{ project.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <button class="primary-button ml-auto" mat-flat-button [disabled]="!selectedProject" (click)="chooseProject()">
        Choose
      </button>
    </div>
  } @else {
    <h4 class="text-2xl font-extrabold">You don't have access to any project.</h4>
  }
</div>
