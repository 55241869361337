@let _expanded = expanded();

<div class="py-2">
  @if (_expanded) {
    <div
      mat-icon-button
      class="primary flex h-14 cursor-pointer justify-between gap-4 rounded border border-solid p-4 pr-2 text-center"
      [matMenuTriggerFor]="projectsMenu"
      (menuOpened)="listOpened.set(true)"
      (menuClosed)="listOpened.set(false)">
      <span class="text-lg">{{ chosenProject()?.name }}</span>

      <mat-icon matSuffix>
        @if (listOpened()) {
          arrow_drop_up
        } @else {
          arrow_drop_down
        }
      </mat-icon>
    </div>
  } @else {
    <div mat-icon-button class="cursor-pointer items-center text-center" [matMenuTriggerFor]="projectsMenu">
      <mat-icon matTooltip="Change project" matTooltipPosition="after">folder_managed</mat-icon>
    </div>
  }
  <mat-menu #projectsMenu="matMenu">
    <ng-template matMenuContent>
      <mat-nav-list [style.width.px]="230">
        @let _chosenProjectId = chosenProject()?.id ?? -1;

        @for (project of projects(); track project.id) {
          @let _chosenProject = _chosenProjectId === project.id;

          <mat-list-item [activated]="_chosenProject" (click)="changeProject(project.id)" class="rounded-none">
            <span matListItemTitle>{{ project.name }}</span>
            @if (_chosenProject) {
              <div matListItemMeta><mat-icon>check</mat-icon></div>
            }
          </mat-list-item>
        }
      </mat-nav-list>
    </ng-template>
  </mat-menu>
</div>
