import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { on } from '@ngrx/store';
import { loaded, LoadingState } from '@shared/interfaces/loading-state';

import { defaultSaturationTestWithLoadingState, TimeSaturation } from '../../interface/time-saturation';
import { DataValidationActions } from '../data-validation.actions';
import { DataValidationState } from '../data-validation.state';

export function timeSaturationReducer() {
  return [
    on(
      DataValidationActions.timeSaturationTestFetchRequested,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state)) {
          return state;
        }

        return {
          ...state,
          saturationTest: defaultSaturationTestWithLoadingState(LoadingState.Loading),
        };
      }
    ),
    on(
      DataValidationActions.timeSaturationTestFetched,
      (
        state: DataValidationState,
        { signalId, saturationTest }: { signalId: AStrionSignalId; saturationTest: TimeSaturation }
      ): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state)) {
          return state;
        }

        return {
          ...state,
          saturationTest,
        };
      }
    ),
    on(
      DataValidationActions.timeSaturationTestNotFound,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state)) {
          return state;
        }

        return {
          ...state,
          saturationTest: defaultSaturationTestWithLoadingState(LoadingState.NotFound),
        };
      }
    ),
    on(
      DataValidationActions.timeSaturationTestFetchFailed,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state)) {
          return state;
        }

        return {
          ...state,
          saturationTest: defaultSaturationTestWithLoadingState(LoadingState.Error),
        };
      }
    ),
  ];
}
