import { ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core';
import { GraphCurve, GraphInput, Scaling, SegmentedLine } from '@astrion-webtools/graph';
import { Cycle, Peak } from '@features/peak-identification/shared/interface/cycles';
import { peakToLine } from '@features/peak-identification/shared/utils/peak-utils';
import { MaterialModule } from '@modules/material.module';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';
import { loaded } from '@shared/interfaces/loading-state';

import { FormsModule } from '@angular/forms';
import { VerticalGraphsWrapperComponent } from '@components/vertical-graphs-wrapper/vertical-graphs-wrapper.component';
import { ContentStyle } from '../content-toggle/content-toggle.component';
import {
  CurvesOptions,
  PeaksGraphInteractiveLegendComponent,
  SimplifiedPeak,
} from '../peaks-graph-interactive-legend/peaks-graph-interactive-legend.component';
import { PeaksTableComponent } from '../peaks-table/peaks-table.component';
import { EstimationArraysComponent } from './estimation-arrays/estimation-arrays.component';

@Component({
  selector: 'app-cycles',
  standalone: true,
  imports: [
    MaterialModule,
    FormsModule,
    EstimationArraysComponent,
    PeaksGraphInteractiveLegendComponent,
    PeaksTableComponent,
    VerticalGraphsWrapperComponent,
  ],
  templateUrl: './cycles.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CyclesComponent {
  filename = input.required<string>();
  cycles = input.required<Cycle[]>();

  activeCycleIndex = input.required<number>();
  showingAll = input.required<boolean>();

  showSpectrum = signal<boolean>(true);
  showNoiseCurve = signal<boolean>(true);

  dbScale = signal(true);
  yScale = computed(() => (this.dbScale() ? Scaling.dB : Scaling.None));

  yTitle = computed(() => (this.dbScale() ? 'PSD (dB)' : 'PSD'));

  get spectrumStyle(): ContentStyle {
    return {
      color: '#2FA7E4',
    };
  }

  get noiseCurveStyle(): ContentStyle {
    return {
      color: '#C72FE4',
    };
  }

  get curveOptions(): CurvesOptions {
    return {
      name: 'Curves',
      first: {
        name: 'Spectrum',
        style: this.spectrumStyle,
        state: this.showSpectrum,
      },
      additional: [
        {
          name: 'Noise',
          style: this.noiseCurveStyle,
          state: this.showNoiseCurve,
        },
      ],
    };
  }

  peaksFilter = signal<(peak: SimplifiedPeak) => boolean>(() => true);

  cyclesLines = computed(() =>
    this.cycles().map(cycle => {
      return cycle.peaks.filter(this.peaksFilter()).map(peak => peakToLine(peak, false));
    })
  );

  allLoaded = computed<boolean>(() => {
    const cycles = this.cycles();
    return (
      cycles.length !== 0 && cycles.every(cycle => loaded(cycle.spectrum.amplitudes) && loaded(cycle.noise.amplitudes))
    );
  });

  cyclesData = computed<GraphInput[]>(() => {
    const showSpectrum = this.showSpectrum();
    const showNoise = this.showNoiseCurve();

    return this.cycles().map((cycle): GraphInput => {
      const curves: GraphCurve[] = [];
      if (showSpectrum && loaded(cycle.spectrum.amplitudes)) {
        curves.push({
          id: 'Spectrum',
          color: this.spectrumStyle.color,
          data: {
            indexedDb: {
              id: cycle.spectrum.amplitudes.data!,
              valuesField: 'data',
              xMinField: 'freqMin',
              xMaxField: 'freqMax',
            },
          },
        });
      }

      if (showNoise && loaded(cycle.noise.amplitudes)) {
        curves.push({
          id: 'Noise',
          color: this.noiseCurveStyle.color,
          data: {
            indexedDb: {
              id: cycle.noise.amplitudes.data!,
              valuesField: 'data',
              xMinField: 'freqMin',
              xMaxField: 'freqMax',
            },
          },
        });
      }

      return {
        dbName: ASTRION_INDEXEDDB_NAME,
        storeName: ASTRION_INDEXEDDB_TABLES.cycles,
        curves,
      };
    });
  });

  currentCyclesData = computed<GraphInput[]>(() => {
    if (!this.allLoaded()) {
      return [];
    }

    const cyclesData = this.cyclesData();
    if (this.showingAll()) {
      return cyclesData;
    } else {
      return [cyclesData[this.activeCycleIndex()]];
    }
  });

  currentCyclePeaks = computed<Peak[]>(() => {
    return this.cycles()[this.activeCycleIndex()].peaks;
  });

  currentCyclesLines = computed<SegmentedLine[][]>(() => {
    if (!this.allLoaded()) {
      return [];
    }

    const cyclesLines = this.cyclesLines();
    if (this.showingAll()) {
      return cyclesLines;
    } else {
      return [cyclesLines[this.activeCycleIndex()]];
    }
  });

  yMeanTicks = computed(() => (this.showingAll() ? 4 : 7));
  graphHeight = computed(() => (this.showingAll() ? `${15 * this.currentCyclesData().length}vh` : '60vh'));

  displayAdditionalData = computed(() => {
    if (this.showingAll()) {
      return false;
    }

    const cycles = this.cycles();

    return cycles.length != 0 && !!cycles[0].spectrum.data;
  });

  currentCycleSpectrumData = computed(() => {
    return this.cycles()[this.activeCycleIndex()].spectrum.data!;
  });

  currentCycleNoiseData = computed(() => {
    return this.cycles()[this.activeCycleIndex()].noise.data!;
  });
}
