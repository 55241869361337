@let _expanded = expanded();

<div class="flex h-full flex-col justify-between">
  <mat-nav-list>
    @for (buttonData of buttons; track buttonData.text) {
      <app-navigation-list-button
        mat-list-item
        [buttonData]="buttonData"
        [expanded]="_expanded"
        [routerLink]="[buttonData.link]"
        routerLinkActive
        #rla="routerLinkActive"
        [isActivated]="rla.isActive" />
    }
  </mat-nav-list>

  <mat-nav-list>
    @for (buttonData of secondaryButtons; track buttonData.text) {
      <app-navigation-list-button
        mat-list-item
        [buttonData]="buttonData"
        [expanded]="_expanded"
        (click)="buttonData.execute()" />
    }
  </mat-nav-list>
</div>
