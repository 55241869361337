import { Injectable } from '@angular/core';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DataProxyApiService } from '@services/data-proxy-api.service';
import { map, switchMap } from 'rxjs';

import { DataValidationApiService } from '../../services/data-validation-api.service';
import { mapStationarityFromDto } from '../../utils/stationarity-mapping';
import {
  stationarityImagesStorageDescriptor,
  stationarityProjectionBytesStorageDescriptor,
  stationarityVarianceBytesStorageDescriptor,
} from '../../utils/storage-descriptors';
import { DataValidationActions } from '../data-validation.actions';

@Injectable()
export class DataValidationStationarityEffects {
  constructor(
    private actions$: Actions,
    private api: DataValidationApiService,
    private dataProxyApi: DataProxyApiService
  ) {}

  emitStationarityTestFetchedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.dataValidationFetched),
      map(({ signalId, dataValidation }) =>
        DataValidationActions.stationarityTestFetched({
          signalId,
          stationarityTest: dataValidation.stationarityTest,
        })
      )
    );
  });

  tryFetchStationarityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.stationarityTestFetchRequested),
      switchMap(({ signalId }) =>
        this.api.getStationarityTestData(signalId).pipe(
          map(stationarityDto =>
            DataValidationActions.stationarityTestFetched({
              signalId,
              stationarityTest: mapStationarityFromDto(stationarityDto),
            })
          ),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? DataValidationActions.stationarityTestNotFound({ signalId })
              : DataValidationActions.stationarityTestFetchFailed({ signalId });
          })
        )
      )
    );
  });

  emitVarianceBytesFetchRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.stationarityTestFetched),
      map(({ signalId, stationarityTest }) =>
        stationarityTest.data === undefined
          ? DataValidationActions.stationarityTestBytesFetchRejected()
          : DataValidationActions.stationarityDataVarianceBytesFetchRequested({
              signalId,
              minFrequency: stationarityTest.data!.minFrequency,
              maxFrequency: stationarityTest.data!.maxFrequency,
              contentPath: stationarityTest.data!.stationarityData.variance.contentPath,
            })
      )
    );
  });

  emitProjectionBytesFetchRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.stationarityTestFetched),
      map(({ signalId, stationarityTest }) =>
        stationarityTest.data === undefined
          ? DataValidationActions.stationarityTestBytesFetchRejected()
          : DataValidationActions.stationarityDataProjectionBytesFetchRequested({
              signalId,
              duration: stationarityTest.data!.duration,
              maxProjection:
                stationarityTest.data!.stationarityData.maxValue *
                (stationarityTest.data!.spectrogram.segmentUpperBound -
                  stationarityTest.data!.spectrogram.segmentLowerBound),
              contentPath: stationarityTest.data!.stationarityData.projection.contentPath,
            })
      )
    );
  });

  emitDataImageBytesFetchRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.stationarityTestFetched),
      map(({ signalId, stationarityTest }) =>
        stationarityTest.data === undefined
          ? DataValidationActions.stationarityTestBytesFetchRejected()
          : DataValidationActions.stationarityDataImageBytesFetchRequested({
              signalId,
              contentPath: stationarityTest.data!.stationarityData.image.contentPath,
              duration: stationarityTest.data!.duration,
              maxFrequency: stationarityTest.data!.maxFrequency,
              minValue: stationarityTest.data!.stationarityData.minValue,
              maxValue: stationarityTest.data!.stationarityData.maxValue,
            })
      )
    );
  });

  emitSpectrogramImageBytesFetchRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.stationarityTestFetched),
      map(({ signalId, stationarityTest }) =>
        stationarityTest.data === undefined
          ? DataValidationActions.stationarityTestBytesFetchRejected()
          : DataValidationActions.stationaritySpectrogramImageBytesFetchRequested({
              signalId,
              contentPath: stationarityTest.data!.spectrogram.image.contentPath,
              duration: stationarityTest.data!.duration,
              maxFrequency: stationarityTest.data!.maxFrequency,
              psdMin: stationarityTest.data!.spectrogram.psdMin,
              psdMax: stationarityTest.data!.spectrogram.psdMax,
            })
      )
    );
  });

  tryFetchVarianceBytesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.stationarityDataVarianceBytesFetchRequested),
      switchMap(({ signalId, minFrequency, maxFrequency, contentPath }) =>
        this.dataProxyApi
          .getDataBytes(
            contentPath,
            stationarityVarianceBytesStorageDescriptor(`${signalId}_variance`, minFrequency, maxFrequency)
          )
          .pipe(
            map(dbRowId => DataValidationActions.stationarityDataVarianceBytesFetched({ signalId, dataId: dbRowId })),
            catchApiError(false, apiError => {
              return apiError.httpError.status == 404
                ? DataValidationActions.stationarityDataVarianceBytesNotFound({ signalId })
                : DataValidationActions.stationarityDataVarianceBytesFetchFailed({ signalId });
            })
          )
      )
    );
  });

  tryFetchProjectionBytesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.stationarityDataProjectionBytesFetchRequested),
      switchMap(({ signalId, duration, maxProjection, contentPath }) =>
        this.dataProxyApi
          .getDataBytes(
            contentPath,
            stationarityProjectionBytesStorageDescriptor(`${signalId}_projection`, duration, maxProjection)
          )
          .pipe(
            map(dbRowId => DataValidationActions.stationarityDataProjectionBytesFetched({ signalId, dataId: dbRowId })),
            catchApiError(false, apiError => {
              return apiError.httpError.status == 404
                ? DataValidationActions.stationarityDataProjectionBytesNotFound({ signalId })
                : DataValidationActions.stationarityDataProjectionBytesFetchFailed({ signalId });
            })
          )
      )
    );
  });

  tryFetchDataImageBytesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.stationarityDataImageBytesFetchRequested),
      switchMap(({ signalId, contentPath, duration, maxFrequency, minValue, maxValue }) =>
        this.dataProxyApi
          .getImageBlob(
            contentPath,
            stationarityImagesStorageDescriptor(`${signalId}_image`, minValue, maxValue, duration, maxFrequency, 0, 4)
          )
          .pipe(
            map(dataId => DataValidationActions.stationarityDataImageBytesFetched({ signalId, dataId })),
            catchApiError(false, apiError => {
              return apiError.httpError.status == 404
                ? DataValidationActions.stationarityDataImageBytesNotFound({ signalId })
                : DataValidationActions.stationarityDataImageBytesFetchFailed({ signalId });
            })
          )
      )
    );
  });

  tryFetchSpectrogramImageBytesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DataValidationActions.stationaritySpectrogramImageBytesFetchRequested),
      switchMap(({ signalId, contentPath, duration, maxFrequency, psdMin, psdMax }) =>
        this.dataProxyApi
          .getImageBlob(
            contentPath,
            stationarityImagesStorageDescriptor(
              `${signalId}_spectrogram`,
              10 * Math.log10(psdMin),
              10 * Math.log10(psdMax),
              duration,
              maxFrequency
            )
          )
          .pipe(
            map(dataId => DataValidationActions.stationaritySpectrogramImageBytesFetched({ signalId, dataId })),
            catchApiError(false, apiError => {
              return apiError.httpError.status == 404
                ? DataValidationActions.stationaritySpectrogramImageBytesNotFound({ signalId })
                : DataValidationActions.stationaritySpectrogramImageBytesFetchFailed({ signalId });
            })
          )
      )
    );
  });
}
