import { BaseSelectors } from '@app-types/extra-selectors-factory';
import { createSelector } from '@ngrx/store';
import { selectRouteParam } from '@store/router.selectors';

import { SensorId } from '../interfaces/sensor.interface';
import { name, SensorsState } from './sensors.state';

export const extraSelectors = ({ selectSensors }: BaseSelectors<typeof name, SensorsState>) => {
  const selectCurrentSensorId = createSelector(selectRouteParam('id'), id => {
    return id as unknown as SensorId | undefined;
  });
  const selectCurrentSensor = createSelector(selectCurrentSensorId, selectSensors, (id, sensors) =>
    sensors.find(s => s.id === id)
  );

  const selectSensor = (sensorId: SensorId) =>
    createSelector(selectSensors, sensors => sensors.find(s => s.id == sensorId));

  return {
    selectSensor,
    selectCurrentSensorId,
    selectCurrentSensor,
  };
};
