@if (signalId(); as signalId) {
  <app-path-bar
    [equipment]="equipment()"
    [sensor]="sensor()"
    [filename]="reportTitle()"
    class="flex-initial"
    (equipmentClicked)="navigateToEquipment($event)"
    (sensorClicked)="navigateToSensor($event)"
    (homeClicked)="navigateToRoot()">
    <button mat-icon-button matTooltip="Refresh report data" class="primary-button" (click)="onRefreshClicked()">
      <mat-icon>refresh</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="Download signal"
      aria-label="Button that allows to download signal of the current report."
      [disabled]="loading()"
      class="primary-button"
      (click)="downloadSignal()">
      <mat-icon>download_file</mat-icon>
    </button>
  </app-path-bar>
  @if (loading()) {
    <mat-progress-bar class="flex-none" mode="indeterminate" />
  }
  <mat-drawer-container class="mb-6 mt-8 flex h-[calc(100%-104px)] w-full flex-row p-0">
    <mat-drawer mode="side" opened class="mb-[8px] h-[calc(100%-8px)] w-[64px]">
      <app-report-stepper [steps]="steps" (stepClicked)="goToStep($event)" />
    </mat-drawer>
    <mat-drawer-content class="flex flex-grow flex-col overflow-x-hidden" #container>
      <app-scrollable-bordered-container
        class="flex flex-grow flex-col gap-6"
        id="report-content"
        (contentScrolled)="onScroll()"
        [fadeBottom]="true"
        [fadeTop]="true">
        <app-report-section
          [sectionData]="overviewSection"
          [appReportAnchor]="overviewSection.anchorId"
          (expandedChange)="onSectionExpansionToggled()">
          <app-report-overview />
        </app-report-section>
        <app-report-section
          [sectionData]="spectralAnalysisSection"
          [appReportAnchor]="spectralAnalysisSection.anchorId"
          (expandedChange)="onSectionExpansionToggled()">
          <app-report-peak-identification />
        </app-report-section>
        <app-report-section
          [sectionData]="dataValidationSection"
          [appReportAnchor]="dataValidationSection.anchorId"
          (expandedChange)="onSectionExpansionToggled()">
          <app-report-data-validation />
        </app-report-section>
      </app-scrollable-bordered-container>
    </mat-drawer-content>
  </mat-drawer-container>
}
