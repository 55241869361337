<div class="stationarity flex w-full flex-col">
  <app-status-banner class="mb-3 w-full flex-none" [status]="stationarity().status" [message]="statusMessage()" />

  <div class="mx-3 grid grid-cols-5 gap-y-2">
    <app-stationarity-heatmap
      id="time-frequency-heatmap"
      class="col-span-2 h-[57vh]"
      graphTitle="Time-Frequency"
      [heatmap]="timeFrequencyHeatmap()" />
    <app-stationarity-heatmap
      id="time-frequency-non-stationarity-heatmap"
      class="col-span-2 h-[57vh]"
      graphTitle="Time-Frequency detection"
      [heatmap]="nonStationarityHeatmap()" />
    <app-stationarity-variance class="col-span-1 h-[57vh]" [variance]="variance()" [kappa]="kappa()" />

    <app-color-scale
      class="col-span-2 h-[1.5vh]"
      id="TimeFrequencyScale"
      [colormap]="timeFrequencyColormap()"
      [min]="timeFrequencyMinValue()"
      [max]="timeFrequencyMaxvalue()"
      unit="dB" />
    <app-color-scale
      class="col-span-2 h-[1.5vh]"
      id="NonStationarityScale"
      [colormap]="nonStationarityColormap()"
      [min]="nonStationarityMinValue()"
      [max]="nonStationarityMaxValue()"
      unit="" />

    <app-stationarity-detection-projection class="col-span-2 col-start-3 mt-8 h-[20vh]" [projection]="projection()" />
  </div>
</div>
