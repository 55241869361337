import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import environment from '@environments/environment.json';
import { Observable } from 'rxjs';

import { EquipmentDto, EquipmentId, EquipmentPayload } from '../interfaces/equipment.interface';

@Injectable({
  providedIn: 'root',
})
export class EquipmentsApiService {
  constructor(private httpClient: HttpClient) {}

  getEquipments(): Observable<EquipmentDto[]> {
    return this.httpClient.get<EquipmentDto[]>(`${environment.BASE_API_URL}/signal-management/equipments`);
  }

  deleteEquipment(equipmentId: EquipmentId): Observable<void> {
    return this.httpClient.delete<void>(`${environment.BASE_API_URL}/signal-management/equipments/${equipmentId}`);
  }

  createEquipment(equipmentPayload: EquipmentPayload): Observable<EquipmentDto> {
    return this.httpClient.post<EquipmentDto>(
      `${environment.BASE_API_URL}/signal-management/equipments`,
      equipmentPayload
    );
  }

  updateEquipment(equipmentPayload: EquipmentPayload): Observable<EquipmentDto> {
    return this.httpClient.put<EquipmentDto>(
      `${environment.BASE_API_URL}/signal-management/equipments`,
      equipmentPayload
    );
  }
}
