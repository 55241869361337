import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  HostListener,
  input,
  OnInit,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { MaterialModule } from '@modules/material.module';
import { StepComponent } from '../step/step.component';

const growClass = 'step-divider-grow';
const shrinkClass = 'step-divider-shrink';
const smallClass = 'step-divider-small';
const tallClass = 'step-divider-tall';

export interface Step {
  anchorId: string;
  icon: string;
  name: string;
}

const dividerYMargin = 8;

@Component({
  selector: 'app-report-stepper',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MaterialModule, StepComponent],
  templateUrl: './report-stepper.component.html',
  styles: `
    @keyframes growHeight {
      from {
        height: var(--start-height, 50px);
      }
      to {
        height: var(--end-height, 50px);
      }
    }
    .step-divider-grow {
      animation: growHeight 200ms ease-in-out forwards;
    }
    @keyframes shrinkHeight {
      from {
        height: var(--end-height, 50px);
      }
      to {
        height: var(--start-height, 50px);
      }
    }
    .step-divider-shrink {
      animation: shrinkHeight 200ms ease-in-out forwards;
    }
    .step-divider-small {
      height: var(--start-height, 50px);
    }
    .step-divider-tall {
      height: var(--end-height, 50px);
    }
  `,
})
/**
 * In addition to input/output, you have to call setScrolledStep when it has scrolled in parent.
 */
export class ReportStepperComponent implements OnInit, AfterViewInit {
  steps = input.required<Step[]>();
  minDividerHeight = input(50);

  stepClicked = output<Step>();

  activeStep = signal<Step | undefined>(undefined);
  anyStepElement = viewChild.required(StepComponent, { read: ElementRef });
  dividerClasses = signal<string[]>([]);

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    const [firstStep] = this.steps();
    if (firstStep) {
      this.changeActiveStep(firstStep, false);
    }
  }

  ngAfterViewInit(): void {
    this.setHeights();
  }

  @HostListener('window:resize')
  public setHeights() {
    const minDividerHeight = this.minDividerHeight();
    const maxDividerHeight =
      getHeight(this.elementRef) -
      this.marginsHeight() -
      getHeight(this.anyStepElement()) * this.steps().length -
      minDividerHeight * (this.numberOfDivider() - 1);

    getNativeElement(this.elementRef).style.setProperty('--start-height', `${minDividerHeight}px`);
    getNativeElement(this.elementRef).style.setProperty('--end-height', `${maxDividerHeight}px`);
  }

  stepClick(step: Step) {
    this.stepClicked.emit(step);
  }

  setScrolledStep(step: Step) {
    this.changeActiveStep(step, true);
  }

  private changeActiveStep(step: Step, animated: boolean) {
    if (step === this.activeStep()) {
      return;
    }

    const stepIndex = this.steps().indexOf(step);
    const dividerClasses = this.newDividerClassesArray(animated);
    if (stepIndex < dividerClasses.length) {
      dividerClasses[stepIndex] = this.tallClasses(animated);
    }
    this.dividerClasses.set(dividerClasses);
    this.activeStep.set(step);
  }

  private marginsHeight = computed(() => dividerYMargin * this.numberOfDivider() * 2);

  private numberOfDivider() {
    const steps = this.steps();
    return steps.length > 0 ? steps.length - 1 : 0;
  }

  private newDividerClassesArray(animated: boolean) {
    return new Array<string>(this.numberOfDivider()).fill(
      `${animated ? shrinkClass : smallClass} ${this.dividerMarginClass()}`
    );
  }

  private tallClasses(animated: boolean) {
    return `${animated ? growClass : tallClass} ${this.dividerMarginClass()}`;
  }

  private dividerMarginClass = () => `my-[${dividerYMargin}px]`;
}

const getHeight = (element: ElementRef) => getNativeElement(element).getBoundingClientRect().height;
const getNativeElement = (element: ElementRef) => element.nativeElement as HTMLElement;
