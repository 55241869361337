import { AStrionSignal } from './astrion-signal.interface';

export enum AStrionSignalUploadStatus {
  Unknown = 'Unknown',
  Success = 'Success',
  Error = 'Error',
  InvalidDataType = 'InvalidDataType',
  InvalidDataContent = 'InvalidDataContent',
  InvalidFileContent = 'InvalidFileContent',
  InvalidSamplingFrequency = 'InvalidSamplingFrequency',
  TooManySamples = 'TooManySamples',
  DifferentSamplingCondition = 'DifferentSamplingCondition',
}

export const code2status = (code: string) => {
  return Object.values(AStrionSignalUploadStatus as unknown as string[]).includes(code)
    ? (code as unknown as AStrionSignalUploadStatus)
    : AStrionSignalUploadStatus.Unknown;
};

export function isSuccess(status: AStrionSignalUploadStatus): boolean {
  return status === AStrionSignalUploadStatus.Success;
}

export interface AStrionSignalUploadResult {
  name: string;
  status: AStrionSignalUploadStatus;
  signal: AStrionSignal | null;
}
