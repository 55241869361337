@if (itemDescription(); as itemDescription) {
  <mat-icon
    class="w-full text-center"
    [ngClass]="itemDescription.itemClass"
    [ngStyle]="{ color: itemDescription.color }"
    [matTooltip]="itemDescription.tooltip"
    [matTooltipDisabled]="itemDescription.tooltip.length === 0"
    [matTooltipShowDelay]="500"
    matTooltipPosition="above">
    {{ itemDescription.icon }}
  </mat-icon>
}
