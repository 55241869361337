import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import environment from '@environments/environment.json';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';

import { AStrionSignalsStatusPayload } from '@features/signals-status/shared/interface/astrion-signals-status.interface';
import { ValidationFlagsDto } from '../interface/validation-flags';

@Injectable()
export class ValidationFlagsApiService {
  private base_url: string;
  constructor(private httpClient: HttpClient) {
    this.base_url = `${environment.BASE_API_URL}/data-validation/validation-flags`;
  }

  getValidationFlags(signalId: AStrionSignalId) {
    return this.httpClient.get<ValidationFlagsDto>(`${this.base_url}/${signalId}`);
  }

  getValidationFlagsList(signalIds: AStrionSignalId[]) {
    return this.httpClient.post<ValidationFlagsDto[]>(
      `${environment.BASE_API_URL}/data-validation/validation-flags/status`,
      {
        signalIds,
      } as AStrionSignalsStatusPayload
    );
  }
}
