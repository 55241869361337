@let _equipments = equipments();

<div class="flex h-full w-full flex-col content-stretch items-stretch p-0">
  <app-path-bar />
  <app-operation-overview [equipments]="_equipments" />
  <app-operation
    class="flex flex-grow flex-col"
    [isLoading]="isLoading()"
    [canEdit]="canEdit()"
    [equipments]="_equipments"
    (refreshClicked)="refresh()"
    (equipmentClicked)="equipmentClicked($event)" />
</div>
