import { Component, input, model, viewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MaterialModule } from '@modules/material.module';

export interface SectionData {
  anchorId: string;
  name: string;
  icon: string;
}

@Component({
  selector: 'app-report-section',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './report-section.component.html',
})
export class ReportSectionComponent {
  public sectionData = input.required<SectionData>();

  public expansionPanel = viewChild.required(MatExpansionPanel);

  public expanded = model<boolean>(true);

  public expand = () => {
    this.expansionPanel().open();
  };

  public collapse = () => {
    this.expansionPanel().close();
  };
}
