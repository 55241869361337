import { BaseSelectors } from '@app-types/extra-selectors-factory';
import { createSelector } from '@ngrx/store';
import { LoadingState } from '@shared/interfaces/loading-state';
import { selectRouteParam } from '@store/router.selectors';

import { EquipmentId } from '../interfaces/equipment.interface';
import { EquipmentsState, name } from './equipments.state';

export const extraSelectors = ({
  selectEquipments,
  selectLoadingState,
}: BaseSelectors<typeof name, EquipmentsState>) => {
  const selectCurrentEquipmentId = createSelector(selectRouteParam('id'), id => {
    return id as unknown as EquipmentId | undefined;
  });
  const selectCurrentEquipment = createSelector(selectCurrentEquipmentId, selectEquipments, (id, equipments) =>
    equipments.find(s => s.id === id)
  );

  const selectEquipment = (equipmentId: EquipmentId) =>
    createSelector(selectEquipments, equipments => equipments.find(s => s.id == equipmentId));

  return {
    selectIsLoading: createSelector(selectLoadingState, loadingState => loadingState === LoadingState.Loading),
    selectInitialized: createSelector(selectLoadingState, loadingState => loadingState === LoadingState.Loaded),
    selectEquipment,
    selectCurrentEquipmentId,
    selectCurrentEquipment,
  };
};
