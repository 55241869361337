<mat-dialog-content class="flex flex-col">
  @for (option of filter.valuedOptions; track option.id) {
    <mat-checkbox [(ngModel)]="option.value"> {{ option.name }}</mat-checkbox>
  }
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button class="secondary-button" [mat-dialog-close]>Clear filter</button>
  <span class="grow"></span>
  <button mat-raised-button class="primary-button focused" (click)="closeWithFilter()">OK</button>
</mat-dialog-actions>
