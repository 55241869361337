<mat-dialog-content class="flex flex-col">
  <mat-form-field
    ><mat-label>Minimum</mat-label><input type="number" matInput [(ngModel)]="filter.min" />
  </mat-form-field>

  <mat-form-field
    ><mat-label>Maximum</mat-label><input type="number" matInput [(ngModel)]="filter.max" />
  </mat-form-field>

  <mat-slider discrete showTickMarks [min]="minimum" [max]="maximum">
    <input matSliderStartThumb [(ngModel)]="filter.min" />
    <input matSliderEndThumb [(ngModel)]="filter.max" />
  </mat-slider>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button class="secondary-button" [mat-dialog-close]>Clear filter</button>
  <span class="grow"></span>
  <button mat-raised-button class="primary-button focused" (click)="closeWithFilter()">OK</button>
</mat-dialog-actions>
