import { createFeature } from '@ngrx/store';

import { reducer } from './equipments.reducer';
import { extraSelectors } from './equipments.selectors';
import { name } from './equipments.state';

export const equipmentsFeature = createFeature({
  name,
  reducer,
  extraSelectors,
});
