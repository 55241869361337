import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-step',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MaterialModule],
  templateUrl: './step.component.html',
  styles: `
    .inactive-step {
      opacity: 0.5;
      transition: opacity 400ms ease;

      &:hover {
        opacity: 1 !important;
      }
    }
  `,
})
export class StepComponent {
  icon = input.required<string>();
  active = input.required<boolean>();

  stepClasses = computed(() => (this.active() ? 'cursor-default' : 'cursor-pointer inactive-step'));
}
