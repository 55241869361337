import { GraphCurve } from '@astrion-webtools/graph';

import { TrajectoryTrendDto, TrendType } from '../interfaces/trajectory-trend.interface';

export function getTrajectoryTrendCurve(trajectoryTrend: TrajectoryTrendDto): GraphCurve {
  return {
    id: trajectoryTrend.id,
    displayName: trajectoryTrend.name,
    data: {
      indexedDb: {
        id: trajectoryTrend.interlacedValues,
        valuesField: 'interlacedValues',
      },
    },
  };
}

const trendTypeNameMap = new Map<TrendType, string>([
  [TrendType.Frequency, 'frequency'],
  [TrendType.Energy, 'energy'],
  [TrendType.HarmonicsCount, 'harmonics-count'],
  [TrendType.AverageHarmonicEnergy, 'average-harmonic-energy'],
  [TrendType.Regularity, 'regularity'],
  [TrendType.THD, 'total-harmonic-distortion'],
]);

export const trendTypeName = (type: TrendType): string => trendTypeNameMap.get(type)!;
