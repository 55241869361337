<div class="flex h-full w-full flex-row justify-center">
  <div class="mr-2 flex h-full flex-col justify-center">
    <span>{{ min() }} {{ unit() }}</span>
  </div>
  <div class="h-full w-[60%] border border-solid border-gray-300">
    <lib-colormap-legend [id]="id()" [colormap]="colormap()" />
  </div>
  <div class="ml-2 flex h-full flex-col justify-center">
    <span> {{ max() }} {{ unit() }} </span>
  </div>
</div>
