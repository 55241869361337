import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SensorDialogComponent } from '@features/sensors/components/sensor-dialog/sensor-dialog.component';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';

import { SensorsActions } from '../store/sensors.actions';
import { EquipmentId } from '@features/equipments/shared/interfaces/equipment.interface';
import { Sensor } from '../interfaces/sensor.interface';

@Injectable()
export class SensorDialogService {
  constructor(
    private dialog: MatDialog,
    private store: Store
  ) {}

  async openCreate(equipmentId: EquipmentId): Promise<void> {
    const sensor = await lastValueFrom(
      this.dialog
        .open(SensorDialogComponent, {
          data: { equipmentId },
          role: 'dialog',
          autoFocus: '.focused',
          width: '560px',
        })
        .afterClosed()
    );
    if (sensor) {
      this.store.dispatch(SensorsActions.sensorCreateRequested({ sensor }));
    }
  }

  async openUpdate(sensor: Sensor): Promise<void> {
    const updatedSensor = await lastValueFrom(
      this.dialog
        .open(SensorDialogComponent, {
          data: { sensor, equipmentId: sensor.equipmentId },
          role: 'dialog',
          autoFocus: '.focused',
          width: '560px',
        })
        .afterClosed()
    );
    if (updatedSensor) {
      this.store.dispatch(SensorsActions.sensorUpdateRequested({ sensor: updatedSensor }));
    }
  }
}
