import {
  ComputationStepProgress,
  stringToComputationStepProgress,
} from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';

import {
  ShannonTest,
  ShannonTestCriterion,
  ShannonTestCriterionDto,
  ShannonTestDto,
  ShannonTestResult,
} from '../interface/sampling-test';
import { ValidationStepStatus } from '../interface/validation-step-status';

const mapSamplingTestCriterionFromDto = (dto: ShannonTestCriterionDto): ShannonTestCriterion => {
  return {
    result: dto.result,
    spectrumAmplitudes: {
      contentPath: dto.spectrumAmplitudesContentPath,
      loadingState: LoadingState.Unknown,
    },
  };
};

export function mapSamplingTestFromDto(dto: ShannonTestDto, samplingFrequency: number): ShannonTest {
  const shannonTest: ShannonTest = {
    progress: stringToComputationStepProgress(dto.progress),
    loadingState: LoadingState.Loaded,
    data: undefined,
  };

  if (shannonTest.progress === ComputationStepProgress.Completed) {
    const dtoData = dto.data!;

    const resultsReduceMethod = (): ShannonTestResult => ({
      signalTooShort: dtoData.signalTooShort,
      linearSucceeded: dtoData.linearTestSummary.result.succeeded,
      dbSucceeded: dtoData.dbTestSummary.result.succeeded,
      noiseSucceeded: dtoData.noiseTestSummary.result.succeeded,
    });

    const results = resultsReduceMethod();

    const hasWarnings =
      results.signalTooShort || !results.linearSucceeded || !results.dbSucceeded || !results.noiseSucceeded;

    const status = hasWarnings ? ValidationStepStatus.Warning : ValidationStepStatus.Success;

    shannonTest.data = {
      ...dtoData,
      status,
      samplingFrequency,
      linearTestSummary: mapSamplingTestCriterionFromDto(dtoData.linearTestSummary),
      dbTestSummary: mapSamplingTestCriterionFromDto(dtoData.dbTestSummary),
      noiseTestSummary: mapSamplingTestCriterionFromDto(dtoData.noiseTestSummary),
      results: resultsReduceMethod,
    };
  }

  return shannonTest;
}
