@let _filename = filename();

<div class="flex w-full flex-col gap-8">
  <div
    class="flex flex-initial flex-col items-center"
    [appReportAnchor]="REPORT_NAMES.peakIdentification.anchors.cycles">
    @if (ready(cycles())) {
      <app-report-sub-section subSectionTitle="Spectrums" class="w-full">
        <app-cycles-selection
          [cycles]="cycles().data!"
          #cyclesSelection
          section-header
          class="ml-6 flex justify-start" />
        <app-cycles
          [filename]="_filename"
          [cycles]="cycles().data!"
          [activeCycleIndex]="cyclesSelection.activeCycleIndex()"
          [showingAll]="cyclesSelection.showingAll()"
          class="w-full" />
      </app-report-sub-section>
    } @else {
      <app-result-unavailable [loadableData]="cycles()" class="h-32 w-full" />
    }
  </div>
  <div class="flex flex-initial flex-col items-center">
    @if (ready(fusion()) && ready(harmonicSeries())) {
      <app-report-sub-section [subSectionTitle]="peaksAndHarmonicsSubsectionTitle()" class="w-full">
        @if (hasHarmonics()) {
          <app-peaks-harmonics-selection
            [selectedMode]="selectedMode()"
            (selectedModeChange)="selectedMode.set($event)"
            section-header
            class="ml-6 mt-[-2px] flex justify-start" />
        }
        <app-peaks-and-harmonic-series
          [filename]="_filename"
          [harmonicSeries]="harmonicSeries().data!"
          [fusion]="fusion().data!"
          [selectedMode]="selectedMode()"
          class="w-[95%]" />
      </app-report-sub-section>
    } @else {
      <app-result-unavailable [loadableData]="fusion()" class="h-32 w-full" />
    }
  </div>
</div>
