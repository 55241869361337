import { IndexedDbRowId } from '@astrion-webtools/graph';
import { Trajectory } from './trajectory.interface';

export interface TrajectoryTrendDto {
  id: string;
  name: string;
  interlacedValues: IndexedDbRowId;
}

export interface TrajectoryTrend {
  name: string;
  interlacedValues: IndexedDbRowId;
}

export enum TrendType {
  Frequency = 0,
  Energy,
  HarmonicsCount,
  AverageHarmonicEnergy,
  Regularity,
  THD,
}

export const trendCompositeKey = (trajectory: Trajectory, trendType: TrendType) =>
  `${trajectory.id}-${trajectory.type}-${trendType}`;
