@let _equipment = equipment();
@let _isLoading = isLoading();

<div class="flex h-full w-full flex-col content-stretch items-stretch p-0">
  <app-path-bar [equipment]="_equipment" (homeClicked)="homeClicked()" />

  @if (_equipment) {
    <app-equipment-overview [equipment]="_equipment" [canEdit]="canEdit()" (editClicked)="edit(_equipment)" />
    <app-equipment
      class="flex flex-grow flex-col"
      [isLoading]="_isLoading"
      [canEdit]="canEdit()"
      [equipment]="_equipment"
      (refreshClicked)="refresh()"
      (sensorClicked)="sensorClicked($event)" />
  } @else {
    @if (_isLoading) {
      <mat-progress-bar class="z-50 w-full" mode="indeterminate" />
    } @else {
      <app-entity-not-found entityName="equipment" [notFound]="true" />
    }
  }
</div>
