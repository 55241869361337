import { NgModule } from '@angular/core';
import { RealtimeModule } from '@features/realtime/realtime.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SensorSignalsRealtimeRegistrationService } from './shared/realtime/sensor-signals-realtime-service';
import { SensorSignalsEffects } from './shared/store/sensor-signals.effects';
import { sensorSignalsFeature } from './shared/store/sensor-signals.feature';

@NgModule({
  imports: [
    EffectsModule.forFeature([SensorSignalsEffects]),
    StoreModule.forFeature(sensorSignalsFeature),
    RealtimeModule.forFeature(SensorSignalsRealtimeRegistrationService),
  ],
})
export class SensorSignalsModule {}
