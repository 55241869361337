import { FilterModel } from '@shared/interfaces/filter-model';
import { Trajectory } from '../interfaces/trajectory.interface';

export function filterByFrequency(trajectories: Trajectory[] | null, filter: FilterModel | undefined) {
  if (filter && trajectories) {
    return trajectories.filter(d => filter.min <= d.frequency && d.frequency <= filter.max);
  }
  return trajectories;
}

export function filterByDetectionPercentage(trajectories: Trajectory[] | null, filter: FilterModel | undefined) {
  if (filter && trajectories) {
    return trajectories.filter(d => filter.min <= d.detectionPercentage && d.detectionPercentage <= filter.max);
  }
  return trajectories;
}
