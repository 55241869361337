import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { GraphInput, IndexedDbRowId } from '@astrion-webtools/graph';
import { GraphWrapperComponent } from '@components/graph-wrapper/graph-wrapper.component';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';

@Component({
  selector: 'app-report-signal-graph',
  standalone: true,
  imports: [GraphWrapperComponent],
  templateUrl: './report-signal-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportSignalGraphComponent {
  samplesId = input.required<IndexedDbRowId>();
  graphTitle = input.required<string>();

  graphData = computed<GraphInput>(
    () =>
      ({
        dbName: ASTRION_INDEXEDDB_NAME,
        storeName: ASTRION_INDEXEDDB_TABLES.signals,
        curves: [
          {
            id: 'Signal',
            data: {
              indexedDb: {
                id: this.samplesId(),
                valuesField: 'samples',
                xMinField: 'xMin',
                xMaxField: 'xMax',
              },
            },
          },
        ],
      }) as GraphInput
  );
}
