<div class="flex w-full flex-col gap-4">
  <mat-card class="w-full bg-[#F7FAFA]">
    <mat-card-content>
      <span class="text-sm font-normal">
        {{ metadata().description }}
      </span>
    </mat-card-content>
  </mat-card>
  <div class="flex w-full flex-row flex-wrap gap-4">
    @for (tileData of tilesData(); track tileData.content) {
      <app-metadata-tile [data]="tileData" />
    }
  </div>
</div>
