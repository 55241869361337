import { Progress } from '@app-types/progress.interface';
import { createReducer, on } from '@ngrx/store';

import { AStrionSignalUploadResult } from '../interface/astrion-signal-upload-result.interface';
import { SignalsActions } from './signals.actions';
import { SIGNALS_INITIAL_STATE, SignalsState } from './signals.state';

export const reducer = createReducer(
  SIGNALS_INITIAL_STATE,
  on(
    SignalsActions.signalsUploadStarted,
    (state: SignalsState): SignalsState => ({
      ...state,
      uploadProgress: { total: 0, progress: 0 },
      lastUpload: [],
      uploadOver: false,
      uploadCancel: false,
    })
  ),
  on(
    SignalsActions.signalsUploadMadeProgress,
    (state: SignalsState, progress: Progress): SignalsState => ({
      ...state,
      uploadProgress: progress,
      uploadOver: progress.progress >= progress.total,
      uploadCancel: false,
    })
  ),
  on(
    SignalsActions.signalsUploadFinished,
    (state: SignalsState, { uploads }: { uploads: AStrionSignalUploadResult[] }): SignalsState => ({
      ...state,
      lastUpload: [...uploads],
      uploadProgress: null,
      uploadCancel: false,
      uploadOver: false,
    })
  ),
  on(
    SignalsActions.signalsUploadFailed,
    (state: SignalsState): SignalsState => ({
      ...state,
      uploadProgress: null,
      uploadCancel: false,
      uploadOver: false,
    })
  ),
  on(
    SignalsActions.signalsUploadCancellationRequested,
    (state: SignalsState): SignalsState => ({
      ...state,
      uploadCancel: !!state.uploadProgress && !state.uploadOver,
    })
  ),
  on(
    SignalsActions.signalsUploadCanceled,
    (state: SignalsState): SignalsState => ({
      ...state,
      uploadProgress: null,
      uploadCancel: false,
    })
  )
);
