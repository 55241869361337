export const faultDetectionColorMap = {
  name: 'FaultDetectionProjection',
  values: new Uint8Array([255, 255, 255, 0, 1, 1, 255, 255, 1, 255, 128, 255, 255, 255, 1, 255, 255, 1, 128, 255]),
};

export const stationarityColorMap = {
  name: 'LegacyStationaritySpectrogram',
  values: new Uint8Array([
    67, 67, 246, 255, 67, 70, 246, 255, 67, 74, 246, 255, 67, 77, 246, 255, 67, 81, 246, 255, 67, 84, 246, 255, 67, 88,
    246, 255, 67, 91, 246, 255, 67, 95, 246, 255, 67, 98, 246, 255, 67, 102, 246, 255, 67, 105, 246, 255, 67, 109, 246,
    255, 67, 112, 246, 255, 67, 116, 246, 255, 67, 119, 246, 255, 67, 123, 246, 255, 67, 126, 246, 255, 67, 130, 246,
    255, 67, 133, 246, 255, 67, 137, 246, 255, 67, 140, 246, 255, 67, 144, 246, 255, 67, 147, 246, 255, 67, 151, 246,
    255, 67, 154, 246, 255, 67, 158, 246, 255, 67, 161, 246, 255, 67, 165, 246, 255, 67, 168, 246, 255, 67, 172, 246,
    255, 67, 175, 246, 255, 67, 179, 246, 255, 67, 182, 246, 255, 67, 186, 246, 255, 67, 189, 246, 255, 67, 193, 246,
    255, 67, 196, 246, 255, 67, 200, 246, 255, 67, 203, 246, 255, 67, 207, 246, 255, 67, 210, 246, 255, 67, 214, 246,
    255, 67, 217, 246, 255, 67, 221, 246, 255, 67, 224, 246, 255, 67, 228, 246, 255, 67, 231, 246, 255, 67, 235, 246,
    255, 67, 238, 246, 255, 67, 242, 246, 255, 67, 246, 245, 255, 67, 246, 242, 255, 67, 246, 238, 255, 67, 246, 235,
    255, 67, 246, 231, 255, 67, 246, 228, 255, 67, 246, 224, 255, 67, 246, 221, 255, 67, 246, 217, 255, 67, 246, 214,
    255, 67, 246, 210, 255, 67, 246, 207, 255, 67, 246, 203, 255, 67, 246, 200, 255, 67, 246, 196, 255, 67, 246, 193,
    255, 67, 246, 189, 255, 67, 246, 186, 255, 67, 246, 182, 255, 67, 246, 179, 255, 67, 246, 175, 255, 67, 246, 172,
    255, 67, 246, 168, 255, 67, 246, 165, 255, 67, 246, 161, 255, 67, 246, 158, 255, 67, 246, 154, 255, 67, 246, 151,
    255, 67, 246, 147, 255, 67, 246, 144, 255, 67, 246, 140, 255, 67, 246, 137, 255, 67, 246, 133, 255, 67, 246, 130,
    255, 67, 246, 126, 255, 67, 246, 123, 255, 67, 246, 119, 255, 67, 246, 116, 255, 67, 246, 112, 255, 67, 246, 109,
    255, 67, 246, 105, 255, 67, 246, 102, 255, 67, 246, 98, 255, 67, 246, 95, 255, 67, 246, 91, 255, 67, 246, 88, 255,
    67, 246, 84, 255, 67, 246, 81, 255, 67, 246, 77, 255, 67, 246, 74, 255, 67, 246, 70, 255, 67, 246, 67, 255, 70, 246,
    67, 255, 74, 246, 67, 255, 77, 246, 67, 255, 81, 246, 67, 255, 84, 246, 67, 255, 88, 246, 67, 255, 91, 246, 67, 255,
    95, 246, 67, 255, 98, 246, 67, 255, 102, 246, 67, 255, 105, 246, 67, 255, 109, 246, 67, 255, 112, 246, 67, 255, 116,
    246, 67, 255, 119, 246, 67, 255, 123, 246, 67, 255, 126, 246, 67, 255, 130, 246, 67, 255, 133, 246, 67, 255, 137,
    246, 67, 255, 140, 246, 67, 255, 144, 246, 67, 255, 147, 246, 67, 255, 151, 246, 67, 255, 154, 246, 67, 255, 158,
    246, 67, 255, 161, 246, 67, 255, 165, 246, 67, 255, 168, 246, 67, 255, 172, 246, 67, 255, 175, 246, 67, 255, 179,
    246, 67, 255, 182, 246, 67, 255, 186, 246, 67, 255, 189, 246, 67, 255, 193, 246, 67, 255, 196, 246, 67, 255, 200,
    246, 67, 255, 203, 246, 67, 255, 207, 246, 67, 255, 210, 246, 67, 255, 214, 246, 67, 255, 217, 246, 67, 255, 221,
    246, 67, 255, 224, 246, 67, 255, 228, 246, 67, 255, 231, 246, 67, 255, 235, 246, 67, 255, 238, 246, 67, 255, 242,
    246, 67, 255, 246, 245, 67, 255, 246, 242, 67, 255, 246, 238, 67, 255, 246, 235, 67, 255, 246, 231, 67, 255, 246,
    228, 67, 255, 246, 224, 67, 255, 246, 221, 67, 255, 246, 217, 67, 255, 246, 214, 67, 255, 246, 210, 67, 255, 246,
    207, 67, 255, 246, 203, 67, 255, 246, 200, 67, 255, 246, 196, 67, 255, 246, 193, 67, 255, 246, 189, 67, 255, 246,
    186, 67, 255, 246, 182, 67, 255, 246, 179, 67, 255, 246, 175, 67, 255, 246, 172, 67, 255, 246, 168, 67, 255, 246,
    165, 67, 255, 246, 161, 67, 255, 246, 158, 67, 255, 246, 154, 67, 255, 246, 151, 67, 255, 246, 147, 67, 255, 246,
    144, 67, 255, 246, 140, 67, 255, 246, 137, 67, 255, 246, 133, 67, 255, 246, 130, 67, 255, 246, 126, 67, 255, 246,
    123, 67, 255, 246, 119, 67, 255, 246, 116, 67, 255, 246, 112, 67, 255, 246, 109, 67, 255, 246, 105, 67, 255, 246,
    102, 67, 255, 246, 98, 67, 255, 246, 95, 67, 255, 246, 91, 67, 255, 246, 88, 67, 255, 246, 84, 67, 255, 246, 81, 67,
    255, 246, 77, 67, 255, 246, 74, 67, 255, 246, 70, 67, 255, 246, 67, 67, 255, 246, 67, 70, 255, 246, 67, 74, 255,
    246, 67, 77, 255, 246, 67, 81, 255, 246, 67, 84, 255, 246, 67, 88, 255, 246, 67, 91, 255, 246, 67, 95, 255, 246, 67,
    98, 255, 246, 67, 102, 255, 246, 67, 105, 255, 246, 67, 109, 255, 246, 67, 112, 255, 246, 67, 116, 255, 246, 67,
    119, 255, 246, 67, 123, 255, 246, 67, 126, 255, 246, 67, 130, 255, 246, 67, 133, 255, 246, 67, 137, 255, 246, 67,
    140, 255, 246, 67, 144, 255, 246, 67, 147, 255, 246, 67, 151, 255, 246, 67, 154, 255, 246, 67, 158, 255, 246, 67,
    161, 255, 246, 67, 165, 255, 246, 67, 168, 255, 246, 67, 172, 255, 246, 67, 175, 255, 246, 67, 179, 255, 246, 67,
    182, 255, 246, 67, 186, 255, 246, 67, 189, 255, 246, 67, 193, 255, 246, 67, 196, 255, 246, 67, 200, 255, 246, 67,
    203, 255, 246, 67, 207, 255, 246, 67, 210, 255, 246, 67, 214, 255, 246, 67, 217, 255, 246, 67, 221, 255, 246, 67,
    224, 255, 246, 67, 228, 255, 246, 67, 231, 255, 246, 67, 235, 255, 246, 67, 238, 255, 246, 67, 242, 255, 246, 67,
    246, 255,
  ]),
};
