import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { OverviewCardComponent } from '@components/overview-card/overview-card.component';
import { SensorGraphsComponent } from '@features/sensor-graphs/components/sensor-graphs/sensor-graphs.component';
import { SensorSignalsComponent } from '@features/sensor-signals/components/sensor-signals/sensor-signals.component';
import { SensorTrajectoriesComponent } from '@features/sensor-trajectories/components/sensor-trajectories/sensor-trajectories.component';
import { SensorSummary } from '@features/sensors/shared/interfaces/sensor.interface';
import { AStrionSignal } from '@features/signals/shared/interface/astrion-signal.interface';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';

enum SensorTabId {
  Signals,
  Trajectories,
  Graphs,
}

const signalTab = { id: SensorTabId.Signals, name: 'Signals', fragment: undefined };
const trajectoryTab = { id: SensorTabId.Trajectories, name: 'Trajectories', fragment: 'trajectories' };
const graphTab = { id: SensorTabId.Graphs, name: 'Graph', fragment: 'graph' };

@Component({
  selector: 'app-sensor',
  standalone: true,
  imports: [
    MaterialModule,
    RouterModule,
    CommonModule,
    SensorSignalsComponent,
    SensorGraphsComponent,
    SensorTrajectoriesComponent,
    OverviewCardComponent,
  ],
  templateUrl: './sensor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SensorComponent implements OnInit {
  sensorSummary = input.required<SensorSummary>();
  loading = input<boolean>(false);

  tabs = [signalTab, trajectoryTab, graphTab];
  name = computed(() => this.sensorSummary().name);
  signalCount = computed(() => this.sensorSummary().signalsCount);
  signalsTabSelected = this.isSelected(SensorTabId.Signals);
  trajectoriesTabSelected = this.isSelected(SensorTabId.Trajectories);
  graphsTabSelected = this.isSelected(SensorTabId.Graphs);

  private selectedTab = computed(() => {
    const fragment = this.fragment();
    const tab = this.tabs.find(t => t.fragment === fragment);
    return tab ?? signalTab;
  });
  private fragment = toSignal(this.activatedRoute.fragment);

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(
      SignalsActions.sensorSignalsFetchRequested({
        sensorId: this.sensorSummary().id,
      })
    );
  }

  navigateToGraph() {
    this.router.navigate([], { fragment: graphTab.fragment });
  }

  navigateToReport(signal: AStrionSignal) {
    this.router.navigateByUrl(`/signals/report/${signal.id}`);
  }

  isSelected(tabId: SensorTabId) {
    return computed(() => this.selectedTab().id === tabId);
  }
}
