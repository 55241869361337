import { IndexedDbRowId } from '@astrion-webtools/graph';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { on } from '@ngrx/store';
import { loaded, LoadingState } from '@shared/interfaces/loading-state';

import { defaultStationarityTestWithLoadingState, Stationarity } from '../../interface/stationarity';
import { DataValidationActions } from '../data-validation.actions';
import { DataValidationState } from '../data-validation.state';

export function stationarityReducer() {
  return [
    on(
      DataValidationActions.stationarityTestFetchRequested,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: defaultStationarityTestWithLoadingState(LoadingState.Loading),
        };
      }
    ),
    on(
      DataValidationActions.stationarityTestFetched,
      (
        state: DataValidationState,
        { signalId, stationarityTest }: { signalId: AStrionSignalId; stationarityTest: Stationarity }
      ): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state)) {
          return state;
        }

        return {
          ...state,
          stationarityTest,
        };
      }
    ),
    on(
      DataValidationActions.stationarityTestNotFound,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: defaultStationarityTestWithLoadingState(LoadingState.NotFound),
        };
      }
    ),
    on(
      DataValidationActions.stationarityTestFetchFailed,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: defaultStationarityTestWithLoadingState(LoadingState.Error),
        };
      }
    ),
    on(
      DataValidationActions.stationarityDataVarianceBytesFetchRequested,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.stationarityTest)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: {
            ...state.stationarityTest,
            data: {
              ...state.stationarityTest.data!,
              stationarityData: {
                ...state.stationarityTest.data!.stationarityData,
                variance: {
                  ...state.stationarityTest.data!.stationarityData.variance,
                  loadingState: LoadingState.Loading,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.stationarityDataVarianceBytesFetched,
      (
        state: DataValidationState,
        { signalId, dataId }: { signalId: AStrionSignalId; dataId: IndexedDbRowId }
      ): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: {
            ...state.stationarityTest,
            data: {
              ...state.stationarityTest.data!,
              stationarityData: {
                ...state.stationarityTest.data!.stationarityData,
                variance: {
                  ...state.stationarityTest.data!.stationarityData.variance,
                  loadingState: LoadingState.Loaded,
                  data: dataId,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.stationarityDataVarianceBytesNotFound,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: {
            ...state.stationarityTest,
            data: {
              ...state.stationarityTest.data!,
              stationarityData: {
                ...state.stationarityTest.data!.stationarityData,
                variance: {
                  ...state.stationarityTest.data!.stationarityData.variance,
                  loadingState: LoadingState.NotFound,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.stationarityDataVarianceBytesFetchFailed,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: {
            ...state.stationarityTest,
            data: {
              ...state.stationarityTest.data!,
              stationarityData: {
                ...state.stationarityTest.data!.stationarityData,
                variance: {
                  ...state.stationarityTest.data!.stationarityData.variance,
                  loadingState: LoadingState.Error,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.stationarityDataProjectionBytesFetchRequested,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.stationarityTest)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: {
            ...state.stationarityTest,
            data: {
              ...state.stationarityTest.data!,
              stationarityData: {
                ...state.stationarityTest.data!.stationarityData,
                projection: {
                  ...state.stationarityTest.data!.stationarityData.projection,
                  loadingState: LoadingState.Loading,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.stationarityDataProjectionBytesFetched,
      (
        state: DataValidationState,
        { signalId, dataId }: { signalId: AStrionSignalId; dataId: IndexedDbRowId }
      ): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: {
            ...state.stationarityTest,
            data: {
              ...state.stationarityTest.data!,
              stationarityData: {
                ...state.stationarityTest.data!.stationarityData,
                projection: {
                  ...state.stationarityTest.data!.stationarityData.projection,
                  loadingState: LoadingState.Loaded,
                  data: dataId,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.stationarityDataProjectionBytesNotFound,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: {
            ...state.stationarityTest,
            data: {
              ...state.stationarityTest.data!,
              stationarityData: {
                ...state.stationarityTest.data!.stationarityData,
                projection: {
                  ...state.stationarityTest.data!.stationarityData.projection,
                  loadingState: LoadingState.NotFound,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.stationarityDataProjectionBytesFetchFailed,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: {
            ...state.stationarityTest,
            data: {
              ...state.stationarityTest.data!,
              stationarityData: {
                ...state.stationarityTest.data!.stationarityData,
                projection: {
                  ...state.stationarityTest.data!.stationarityData.projection,
                  loadingState: LoadingState.Error,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.stationarityDataImageBytesFetchRequested,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.stationarityTest)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: {
            ...state.stationarityTest,
            data: {
              ...state.stationarityTest.data!,
              stationarityData: {
                ...state.stationarityTest.data!.stationarityData,
                image: {
                  ...state.stationarityTest.data!.stationarityData.image,
                  loadingState: LoadingState.Loading,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.stationarityDataImageBytesFetched,
      (
        state: DataValidationState,
        { signalId, dataId }: { signalId: AStrionSignalId; dataId: IndexedDbRowId }
      ): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: {
            ...state.stationarityTest,
            data: {
              ...state.stationarityTest.data!,
              stationarityData: {
                ...state.stationarityTest.data!.stationarityData,
                image: {
                  ...state.stationarityTest.data!.stationarityData.image,
                  loadingState: LoadingState.Loaded,
                  data: dataId,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.stationarityDataImageBytesNotFound,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: {
            ...state.stationarityTest,
            data: {
              ...state.stationarityTest.data!,
              stationarityData: {
                ...state.stationarityTest.data!.stationarityData,
                image: {
                  ...state.stationarityTest.data!.stationarityData.image,
                  loadingState: LoadingState.NotFound,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.stationarityDataImageBytesFetchFailed,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: {
            ...state.stationarityTest,
            data: {
              ...state.stationarityTest.data!,
              stationarityData: {
                ...state.stationarityTest.data!.stationarityData,
                image: {
                  ...state.stationarityTest.data!.stationarityData.image,
                  loadingState: LoadingState.Error,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.stationaritySpectrogramImageBytesFetchRequested,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.stationarityTest)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: {
            ...state.stationarityTest,
            data: {
              ...state.stationarityTest.data!,
              spectrogram: {
                ...state.stationarityTest.data!.spectrogram,
                image: {
                  ...state.stationarityTest.data!.spectrogram.image,
                  loadingState: LoadingState.Loading,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.stationaritySpectrogramImageBytesFetched,
      (
        state: DataValidationState,
        { signalId, dataId }: { signalId: AStrionSignalId; dataId: IndexedDbRowId }
      ): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: {
            ...state.stationarityTest,
            data: {
              ...state.stationarityTest.data!,
              spectrogram: {
                ...state.stationarityTest.data!.spectrogram,
                image: {
                  ...state.stationarityTest.data!.spectrogram.image,
                  loadingState: LoadingState.Loaded,
                  data: dataId,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.stationaritySpectrogramImageBytesNotFound,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: {
            ...state.stationarityTest,
            data: {
              ...state.stationarityTest.data!,
              spectrogram: {
                ...state.stationarityTest.data!.spectrogram,
                image: {
                  ...state.stationarityTest.data!.spectrogram.image,
                  loadingState: LoadingState.NotFound,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.stationaritySpectrogramImageBytesFetchFailed,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          stationarityTest: {
            ...state.stationarityTest,
            data: {
              ...state.stationarityTest.data!,
              spectrogram: {
                ...state.stationarityTest.data!.spectrogram,
                image: {
                  ...state.stationarityTest.data!.spectrogram.image,
                  loadingState: LoadingState.Error,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
  ];
}
