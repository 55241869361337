import { Component, computed, input, output, signal, viewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CsvExportOptions, GlGraphComponent } from '@astrion-webtools/graph';
import { MaterialModule } from '@modules/material.module';
import { download } from '@tools/utilities/download';
import { firstValueFrom } from 'rxjs';
import { GraphWrapperBaseExportDialogComponent } from './graph-wrapper-base-export-dialog/graph-wrapper-base-export-dialog.component';
import { GraphWrapperBaseLegendComponent } from './graph-wrapper-base-legend/graph-wrapper-base-legend.component';
import { CurveLegend } from './graph-wrapper-base.interface';
import { GraphWrapperDrawerComponent } from './graph-wrapper-drawer/graph-wrapper-drawer.component';

@Component({
  selector: 'app-graph-wrapper-base',
  standalone: true,
  imports: [MaterialModule, GraphWrapperBaseLegendComponent, GraphWrapperDrawerComponent],
  templateUrl: './graph-wrapper-base.component.html',
})
export class GraphWrapperBaseComponent {
  xTitle = input.required<string>();
  graphHeight = input<string | undefined>(undefined);
  hasData = input.required<boolean>();
  graph = input.required<GlGraphComponent | undefined>();
  name = input.required<string>();
  showDownload = input(true);
  showLegend = input(false);
  showDelete = input(false);
  showSettings = input(false);
  showInfos = input(false);
  collapsable = input(true);

  downloadDisabled = input(false);
  legend = input<CurveLegend[] | undefined>(undefined);

  delete = output();

  panel = viewChild.required<MatExpansionPanel>('panel');
  menuOpen = signal<boolean>(false);

  drawerShowSettings = signal<boolean>(true);
  drawerShowInfos = computed(() => !this.drawerShowSettings());

  constructor(private exportDialog: MatDialog) {}

  onSettings(event: Event) {
    event.stopPropagation();
    if (this.drawerShowSettings() && this.menuOpen()) {
      this.menuOpen.set(false);
      return;
    }
    this.drawerShowSettings.set(true);
    this.menuOpen.set(true);
  }

  onInfos(event: Event) {
    event.stopPropagation();
    if (this.drawerShowInfos() && this.menuOpen()) {
      this.menuOpen.set(false);
      return;
    }
    this.drawerShowSettings.set(false);
    this.menuOpen.set(true);
  }

  onDelete(event: Event) {
    this.delete.emit();
    event.stopPropagation();
  }

  onTogglePanel(event: Event) {
    this.expanded.update(e => !e);
    event.stopPropagation();
  }

  onDownload = async (event: Event) => {
    event.stopPropagation();
    const graph = this.graph();
    if (!graph) {
      return;
    }
    const options: CsvExportOptions = await firstValueFrom(
      this.exportDialog
        .open(GraphWrapperBaseExportDialogComponent, { restoreFocus: false, data: { xTitle: this.xTitle() } })
        .afterClosed()
    );
    if (options) {
      const csvText = await graph.exportCsv(options);
      download('export.csv', csvText);
    }
  };

  expanded = signal(true);
}
