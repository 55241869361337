import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { LoadingLogoComponent } from '@components/loading-logo/loading-logo.component';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-result-unavailable-display',
  standalone: true,
  imports: [MaterialModule, LoadingLogoComponent],
  templateUrl: './result-unavailable-display.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultUnavailableDisplayComponent {
  loaded = input.required<boolean>();
  inProgress = input.required<boolean>();
  notComputed = input<boolean>(false);
  connectedToLiveUpdates = input.required<boolean>();
  unknownProgress = input.required<boolean>();
  loading = input.required<boolean>();
  error = input.required<boolean>();
  notFound = input.required<boolean>();
}
