import {
  FlagDto,
  PercentageFlag,
  PercentageFlagDto,
  ShannonFlag,
  ShannonFlagDto,
  SignalValidationFlags,
  ValidationFlags,
  ValidationFlagsDto,
} from '../interface/validation-flags';
import { ValidationStepStatus, ValidationStepStatusDto } from '../interface/validation-step-status';

export function validationFlagsFromDto(dto: ValidationFlagsDto): ValidationFlags {
  return {
    nonStationnarity: percentageFlagFromDto(dto.nonStationnarity),
    shannon: shannonFlagFromDto(dto.shannon),
    timeSaturation: flagFromDto(dto.timeSaturation),
  };
}

export function signalsValidationFlagsFromDtos(dtos: ValidationFlagsDto[]): SignalValidationFlags[] {
  return dtos.map(dto => ({
    signalId: dto.signalId,
    nonStationnarity: percentageFlagFromDto(dto.nonStationnarity),
    shannon: shannonFlagFromDto(dto.shannon),
    timeSaturation: flagFromDto(dto.timeSaturation),
  }));
}

function shannonFlagFromDto(dto: ShannonFlagDto): ShannonFlag {
  return {
    ...flagFromDto(dto),
    signalTooShort: dto.signalTooShort,
    dbSuccessed: dto.dbSuccessed,
    linearSuccessed: dto.linearSuccessed,
    noiseSuccessed: dto.noiseSuccessed,
  };
}

function percentageFlagFromDto(dto: PercentageFlagDto): PercentageFlag {
  return {
    ...flagFromDto(dto),
    percentage: dto.percentage,
  };
}

function flagFromDto(dto: FlagDto) {
  return {
    status: validationStepStatusFromDto(dto.status),
  };
}

function validationStepStatusFromDto(dto: ValidationStepStatusDto) {
  return ValidationStepStatus[ValidationStepStatusDto[dto] as keyof typeof ValidationStepStatus];
}

export function dtoFromValidationStepStatus(dto: ValidationStepStatus) {
  return ValidationStepStatusDto[ValidationStepStatus[dto] as keyof typeof ValidationStepStatusDto];
}
