import {
  computationCompleted,
  stringToComputationStepProgress,
} from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';

import { HarmonicSeries, HarmonicSeriesDto } from '../interface/harmonic-series';

export function mapHarmonicSeriesFromDto(dto: HarmonicSeriesDto): HarmonicSeries {
  const harmonicSeries: HarmonicSeries = {
    progress: stringToComputationStepProgress(dto.progress),
    loadingState: LoadingState.Loaded,
    data: undefined,
  };

  if (computationCompleted(harmonicSeries) && dto.data !== undefined) {
    harmonicSeries.data = dto.data!.map(serie => ({
      ...serie,
      harmonicsCount: serie.harmonics.length,
    }));
  }

  return harmonicSeries;
}
