import { GraphCurve } from '@astrion-webtools/graph';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';

export const name = 'sensor-graphs';

export interface SensorGraphsState {
  sensorsCurves: Record<SensorId, Record<string, GraphCurve>>;
}

export const SENSOR_GRAPHS_INITIAL_STATE: SensorGraphsState = {
  sensorsCurves: {},
};
