import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MetadataTileComponent, TileData } from '@features/signal-overview/metadata-tile/metadata-tile.component';
import { SignalMetadata } from '@features/signal-overview/shared/interface/signal-metadata';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-signal-overview-metadata',
  standalone: true,
  imports: [CommonModule, MaterialModule, MetadataTileComponent],
  providers: [DatePipe],
  templateUrl: './signal-overview-metadata.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignalOverviewMetadataComponent {
  public metadata = input.required<SignalMetadata>();

  constructor(private datePipe: DatePipe) {}

  public tilesData = computed((): TileData[] => {
    const metadata = this.metadata();

    const frequencyBandContent = `${metadata.frequencyBand.lowerBound} - ${metadata.frequencyBand.upperBound} Hz (${metadata.frequencyBand.lowerBoundPercentage} - ${metadata.frequencyBand.upperBoundPercentage} %)`;

    return [
      {
        title: 'Sampling frequency',
        content: `${metadata.samplingFrequency} Hz`,
      },
      {
        title: 'Duration',
        content: `${metadata.duration} s`,
      },
      {
        title: 'Samples count',
        content: `${metadata.samplesCount}`,
      },
      {
        title: 'Mean amplitude',
        content: `${metadata.mean?.toFixed(4) ?? 0}`,
      },
      {
        title: 'Rotation speed',
        content: `${metadata.rpm ? metadata.rpm + ' RPM' : 'N/A'}`,
      },
      {
        title: 'Frequency band',
        content: frequencyBandContent,
      },
      {
        title: 'Data type',
        content: `${metadata.dataType}`,
      },
      {
        title: 'Date',
        content: `${metadata.date ? this.datePipe.transform(metadata.date!, 'YYYY/MM/dd HH:mm:ss') : 'N/A'}`,
      },
    ];
  });
}
