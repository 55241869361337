import { CurveId, GraphCurve } from '@astrion-webtools/graph';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { createActionGroup, props } from '@ngrx/store';

export const SensorGraphsActions = createActionGroup({
  source: 'SensorGraphs',
  events: {
    clearCurves: props<{ sensorId: SensorId }>(),
    removeCurve: props<{ sensorId: SensorId; curveId: string }>(),
    addCurve: props<{ sensorId: SensorId; curveId: CurveId; curve: GraphCurve }>(),
  },
});
