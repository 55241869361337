import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { props } from '@ngrx/store';

import { TimeSaturation } from '../../interface/time-saturation';

export function timeSaturationActions() {
  return {
    timeSaturationTestFetchRequested: props<{ signalId: AStrionSignalId }>(),
    timeSaturationTestFetched: props<{ signalId: AStrionSignalId; saturationTest: TimeSaturation }>(),
    timeSaturationTestNotFound: props<{ signalId: AStrionSignalId }>(),
    timeSaturationTestFetchFailed: props<{ signalId: AStrionSignalId }>(),
  };
}
