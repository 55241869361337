import { BaseSelectors } from '@app-types/extra-selectors-factory';
import { DataValidation } from '@features/data-validation/shared/interface/data-validation';
import { ValidationStepStatus } from '@features/data-validation/shared/interface/validation-step-status';
import {
  DataValidationState,
  featureName as dataValidationFeatureName,
} from '@features/data-validation/shared/store/data-validation.state';
import { PeakIdentification } from '@features/peak-identification/shared/interface/peak-identification';
import {
  featureName as peakIdentificationFeatureName,
  PeakIdentificationState,
} from '@features/peak-identification/shared/store/peak-identification.state';
import { sensorsFeature } from '@features/sensors/shared/store/sensors.feature';
import {
  featureName as signalOverviewFeatureName,
  SignalOverviewState,
} from '@features/signal-overview/shared/store/signal-overview.state';
import { AStrionSignal, AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState } from '@shared/interfaces/loading-state';

import { ReportAnchor, ReportPage, ReportPages } from '../interface/report-page';
import { REPORT_NAMES, REPORT_PAGES } from './report-pages';
import { featureName, SignalReportState } from './signal-report.state';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { equipmentsFeature } from '@features/equipments/shared/store/equipments.feature';

type OverviewSteps = Record<string, { loadingState: LoadingState }>;

const updateOverviewAnchor = (anchor: ReportAnchor, overviewSteps: OverviewSteps): ReportAnchor => {
  if (!(anchor.fragment in overviewSteps)) {
    return { ...anchor };
  }

  const validationStepData = overviewSteps[anchor.fragment];

  return {
    ...anchor,
    loadingState: validationStepData.loadingState,
  };
};

const updateOverviewPage = (page: ReportPage, loadingState: LoadingState): ReportPage => {
  const overviewSteps: OverviewSteps = {
    [REPORT_NAMES.overview.anchors.signal]: { loadingState },
    [REPORT_NAMES.overview.anchors.metadata]: { loadingState },
  };

  return {
    ...page,
    anchors: page.anchors.map(anchor => updateOverviewAnchor(anchor, overviewSteps)),
    loadingState,
  };
};

type DataValidationSteps = Record<string, { status: ValidationStepStatus; loadingState: LoadingState }>;
type PeakIdentificationSteps = Record<string, { loadingState: LoadingState }>;

const statusVisualComponents = (
  status: ValidationStepStatus
): { secondaryIcon: string; secondaryIconColor: string } => {
  switch (status) {
    case ValidationStepStatus.Success:
      return { secondaryIcon: 'check_box', secondaryIconColor: '#16a34a' };
    case ValidationStepStatus.Warning:
      return { secondaryIcon: 'warning', secondaryIconColor: '#F9B233' };
    case ValidationStepStatus.Failure:
      return { secondaryIcon: 'report', secondaryIconColor: '#B61615' };
    case ValidationStepStatus.Neutral:
      return { secondaryIcon: 'info', secondaryIconColor: '#2563EB' };
    default:
      return { secondaryIcon: 'help_outline', secondaryIconColor: '#006C75' };
  }
};

const updateDataValidationAnchor = (anchor: ReportAnchor, validationSteps: DataValidationSteps): ReportAnchor => {
  if (!(anchor.fragment in validationSteps)) {
    return { ...anchor };
  }

  const validationStepData = validationSteps[anchor.fragment];

  let transformedAnchor = {
    ...anchor,
    loadingState: validationStepData.loadingState,
  };

  if (transformedAnchor.loadingState === LoadingState.Loaded) {
    transformedAnchor = {
      ...transformedAnchor,
      ...statusVisualComponents(validationStepData.status),
    };
  }

  return transformedAnchor;
};

const updateDataValidationPage = (page: ReportPage, dataValidation: DataValidation): ReportPage => {
  const validationSteps: DataValidationSteps = {
    [REPORT_NAMES.dataValidation.anchors.timeSaturation]: {
      status: dataValidation.saturationTest.data?.status ?? ValidationStepStatus.Unknown,
      loadingState: dataValidation.saturationTest.loadingState,
    },
    [REPORT_NAMES.dataValidation.anchors.shannonTest]: {
      status: dataValidation.samplingTest.data?.status ?? ValidationStepStatus.Unknown,
      loadingState: dataValidation.samplingTest.loadingState,
    },
    [REPORT_NAMES.dataValidation.anchors.stationarity]: {
      status: dataValidation.stationarityTest.data?.status ?? ValidationStepStatus.Unknown,
      loadingState: dataValidation.stationarityTest.loadingState,
    },
  };

  return {
    ...page,
    notifications: Object.values(validationSteps).reduce(
      (prevNotifications, validationStep) =>
        validationStep.status === ValidationStepStatus.Failure || validationStep.status === ValidationStepStatus.Warning
          ? {
              count: prevNotifications.count + 1,
              hasError: prevNotifications.hasError || validationStep.status === ValidationStepStatus.Failure,
            }
          : prevNotifications,
      {
        count: 0,
        hasError: false,
      } as { count: number; hasError: boolean }
    ),
    anchors: page.anchors.map(anchor => updateDataValidationAnchor(anchor, validationSteps)),
    loadingState: dataValidation.loadingState,
  };
};

const updatePeakIdentificationAnchor = (
  anchor: ReportAnchor,
  peakIdentificationSteps: PeakIdentificationSteps
): ReportAnchor => {
  if (!(anchor.fragment in peakIdentificationSteps)) {
    return { ...anchor };
  }

  const validationStepData = peakIdentificationSteps[anchor.fragment];

  return {
    ...anchor,
    loadingState: validationStepData.loadingState,
  };
};

const updatePeakIdentificationPage = (page: ReportPage, peakIdentification: PeakIdentification): ReportPage => {
  const peakIdentificationSteps: PeakIdentificationSteps = {
    [REPORT_NAMES.peakIdentification.anchors.cycles]: peakIdentification?.cycles,
    [REPORT_NAMES.peakIdentification.anchors.fusion]: peakIdentification?.fusion,
    [REPORT_NAMES.peakIdentification.anchors.harmonics]: peakIdentification?.harmonicSeries,
  };

  return {
    ...page,
    anchors: page.anchors.map(anchor => updatePeakIdentificationAnchor(anchor, peakIdentificationSteps)),
    loadingState: peakIdentification.loadingState,
  };
};

const selectSignalOverview = createFeatureSelector<SignalOverviewState>(signalOverviewFeatureName);
const selectDataValidation = createFeatureSelector<DataValidationState>(dataValidationFeatureName);
const selectPeakIdentification = createFeatureSelector<PeakIdentificationState>(peakIdentificationFeatureName);

export const extraSelectors = ({
  selectName,
  selectSignalId,
  selectSensorId,
}: BaseSelectors<typeof featureName, SignalReportState>) => ({
  selectReportPages: createSelector(
    selectSignalOverview,
    selectDataValidation,
    selectPeakIdentification,
    (
      signalOverview: SignalOverviewState,
      dataValidation: DataValidationState,
      peakIdentification: PeakIdentificationState
    ): ReportPages =>
      REPORT_PAGES.map(page => {
        switch (page.path) {
          case REPORT_NAMES.overview.path: {
            return updateOverviewPage(page, signalOverview.loadingState);
          }
          case REPORT_NAMES.dataValidation.path: {
            return updateDataValidationPage(page, dataValidation);
          }
          case REPORT_NAMES.peakIdentification.path: {
            return updatePeakIdentificationPage(page, peakIdentification);
          }
          default: {
            return page;
          }
        }
      })
  ),

  selectSignalSensor: createSelector(selectSensorId, sensorsFeature.selectSensors, (sensorId, sensors) => {
    return sensors.find(s => s.id === sensorId);
  }),

  selectSignalEquipment: createSelector(selectSensorId, equipmentsFeature.selectEquipments, (sensorId, equipments) => {
    return equipments.find(e => e.sensors.find(s => s.id === sensorId));
  }),

  selectSignal: createSelector(
    selectName,
    selectSignalId,
    selectSensorId,
    selectSignalOverview,
    (
      name: string,
      signalId: AStrionSignalId | null,
      sensorId: SensorId | null,
      signalOverview: SignalOverviewState
    ): AStrionSignal | undefined => {
      if (signalId === null || sensorId === null || signalOverview.signalData === undefined) {
        return undefined;
      }

      const metadata = signalOverview.signalData.metadata;

      return {
        id: signalId,
        name,
        sensorId,
        createdAt: metadata.createdAt,
        date: metadata.date,
        samplingFrequency: metadata.samplingFrequency,
        samplesCount: metadata.samplesCount,
        description: metadata.description,
      };
    }
  ),

  selectNameWithoutExtension: createSelector(selectName, (name: string): string => {
    return name.slice(0, name.indexOf('.AStrion'));
  }),
});
