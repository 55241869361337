import { Injectable } from '@angular/core';
import { HttpWrapperService } from '@astrion-webtools/graph';
import environment from '@environments/environment.json';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { Observable } from 'rxjs';

import { DataValidationDto } from '../interface/data-validation';
import { ShannonTestDto } from '../interface/sampling-test';
import { StationarityDto } from '../interface/stationarity';
import { TimeSaturationDto } from '../interface/time-saturation';

@Injectable({
  providedIn: 'root',
})
export class DataValidationApiService {
  constructor(private api: HttpWrapperService) {}

  getDataValidation(signalId: AStrionSignalId): Observable<DataValidationDto> {
    return this.api.get<DataValidationDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/data-validation/${signalId}`
    );
  }

  getTimeSaturationTestData(signalId: AStrionSignalId): Observable<TimeSaturationDto> {
    return this.api.get<TimeSaturationDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/data-validation/time-saturation-test/${signalId}`
    );
  }

  getSamplingTestData(signalId: AStrionSignalId): Observable<ShannonTestDto> {
    return this.api.get<ShannonTestDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/data-validation/sampling-test/${signalId}`
    );
  }

  getStationarityTestData(signalId: AStrionSignalId): Observable<StationarityDto> {
    return this.api.get<StationarityDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/data-validation/stationarity-test/${signalId}`
    );
  }
}
