import { Component, input } from '@angular/core';
import { MaterialModule } from '@modules/material.module';
import {
  NavigationListButtonComponent,
  NavigationListButtonData,
} from './navigation-list-button/navigation-list-button.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { authFeature } from '@features/auth/shared/store/auth.feature';
import { ApplicationDataComponent } from '@components/application-data/application-data.component';

interface NavigationButtonData extends NavigationListButtonData {
  link: string;
}

interface ActionButtonData extends NavigationListButtonData {
  execute: () => void;
}

@Component({
  selector: 'app-navigation-list',
  standalone: true,
  imports: [MaterialModule, NavigationListButtonComponent, RouterLink, RouterLinkActive],
  templateUrl: './navigation-list.component.html',
})
export class NavigationListComponent {
  expanded = input.required<boolean>();

  canSeeApplicationData = this._store.selectSignal(authFeature.selectIsAstriisUser);

  buttons: NavigationButtonData[] = [
    {
      icon: 'dashboard',
      text: 'Dashboard',
      link: '/',
    },
  ];

  secondaryButtons: ActionButtonData[] = !this.canSeeApplicationData()
    ? []
    : [
        {
          icon: 'info',
          text: 'About',
          execute: () => {
            this._dialog.open(ApplicationDataComponent);
          },
        },
      ];

  constructor(
    private _dialog: MatDialog,
    private _store: Store
  ) {}
}
