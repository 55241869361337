import { NgModule } from '@angular/core';
import { RealtimeModule } from '@features/realtime/realtime.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SensorsRealtimeService } from './shared/realtime/sensors-realtime-service';
import { SensorsEffects } from './shared/store/sensors.effects';
import { sensorsFeature } from './shared/store/sensors.feature';

@NgModule({
  imports: [
    EffectsModule.forFeature([SensorsEffects]),
    StoreModule.forFeature(sensorsFeature),
    RealtimeModule.forFeature(SensorsRealtimeService),
  ],
  providers: [SensorsRealtimeService],
})
export class SensorsModule {}
