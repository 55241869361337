import { LoadingState } from '@shared/interfaces/loading-state';

import { Equipment } from '../interfaces/equipment.interface';

export const name = 'equipments';

export interface EquipmentsState {
  loadingState: LoadingState;
  equipments: Equipment[];
}

export const EQUIPMENTS_INITIAL_STATE: EquipmentsState = {
  loadingState: LoadingState.Unknown,
  equipments: [],
};
