<div class="relative w-full p-0" [style.height]="height()">
  <div class="absolute left-0 top-0 h-full" [@openCloseContent]="openClose()">
    <ng-content select="[content]" />
  </div>
  <div
    class="absolute top-0 h-full w-[30%] overflow-y-auto overflow-x-hidden border-l border-l-slate-400 p-4"
    [@openCloseMenu]="openClose()">
    <ng-content select="[menu]" />
  </div>
</div>
