import { IndexedDbRowId } from '@astrion-webtools/graph';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createReducer, on } from '@ngrx/store';
import { loaded, LoadingState } from '@shared/interfaces/loading-state';

import { Cycle, Cycles, defaultCyclesWithLoadingState } from '../interface/cycles';
import { defaultFusionWithLoadingState, Fusion } from '../interface/fusion';
import { defaultHarmonicSeriesWithLoadingState, HarmonicSeries } from '../interface/harmonic-series';
import { PeakIdentification } from '../interface/peak-identification';
import { PeakIdentificationActions } from './peak-identification.actions';
import { PEAK_IDENTIFICATION_INITIAL_STATE, PeakIdentificationState } from './peak-identification.state';

const recreateCyclesData = (originalData: Cycle[], cycleIndex: number, newCycleData: Cycle) => {
  const newData = [...originalData.slice(0, cycleIndex), newCycleData, ...originalData.slice(cycleIndex + 1)];

  return newData;
};

export const reducer = createReducer(
  PEAK_IDENTIFICATION_INITIAL_STATE,
  on(
    PeakIdentificationActions.peakIdentificationReset,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId | null }): PeakIdentificationState => ({
      ...PEAK_IDENTIFICATION_INITIAL_STATE,
      signalId,
    })
  ),
  on(
    PeakIdentificationActions.peakIdentificationFetchRequested,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...PEAK_IDENTIFICATION_INITIAL_STATE,
        signalId,
        loadingState: LoadingState.Loading,
      };
    }
  ),
  on(
    PeakIdentificationActions.peakIdentificationFetched,
    (
      state: PeakIdentificationState,
      { signalId, peakIdentification }: { signalId: AStrionSignalId; peakIdentification: PeakIdentification }
    ): PeakIdentificationState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...state,
        ...peakIdentification,
      };
    }
  ),
  on(
    PeakIdentificationActions.peakIdentificationNotFound,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...PEAK_IDENTIFICATION_INITIAL_STATE,
        loadingState: LoadingState.NotFound,
      };
    }
  ),
  on(
    PeakIdentificationActions.peakIdentificationFetchFailed,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...PEAK_IDENTIFICATION_INITIAL_STATE,
        loadingState: LoadingState.Error,
      };
    }
  ),
  on(
    PeakIdentificationActions.cyclesFetchRequested,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        cycles: defaultCyclesWithLoadingState(LoadingState.Loading),
      };
    }
  ),
  on(
    PeakIdentificationActions.cyclesFetched,
    (
      state: PeakIdentificationState,
      { signalId, cycles }: { signalId: AStrionSignalId; cycles: Cycles }
    ): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        cycles,
      };
    }
  ),
  on(
    PeakIdentificationActions.cyclesNotFound,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        cycles: defaultCyclesWithLoadingState(LoadingState.NotFound),
      };
    }
  ),
  on(
    PeakIdentificationActions.cyclesFetchFailed,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        cycles: defaultCyclesWithLoadingState(LoadingState.Error),
      };
    }
  ),
  on(
    PeakIdentificationActions.cycleSpectrumBytesFetchRequested,
    (
      state: PeakIdentificationState,
      { signalId, cycleIndex }: { signalId: AStrionSignalId; cycleIndex: number }
    ): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.cycles)) {
        return state;
      }

      if (cycleIndex >= state.cycles.data!.length) {
        return state;
      }

      const cyclesData = state.cycles.data!;
      const indexedCycleData = cyclesData[cycleIndex];
      const newCycleData = {
        ...indexedCycleData,
        spectrum: {
          ...indexedCycleData.spectrum,
          amplitudes: {
            ...indexedCycleData.spectrum.amplitudes,
            loadingState: LoadingState.Loading,
            data: undefined,
          },
        },
      };

      return {
        ...state,
        cycles: {
          ...state.cycles,
          data: recreateCyclesData(cyclesData, cycleIndex, newCycleData),
        },
      };
    }
  ),
  on(
    PeakIdentificationActions.cycleSpectrumBytesFetched,
    (
      state: PeakIdentificationState,
      { signalId, cycleIndex, dataId }: { signalId: AStrionSignalId; cycleIndex: number; dataId: IndexedDbRowId }
    ): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.cycles)) {
        return state;
      }

      if (cycleIndex >= state.cycles.data!.length) {
        return state;
      }

      const cyclesData = state.cycles.data!;
      const indexedCycleData = cyclesData[cycleIndex];
      const newCycleData = {
        ...indexedCycleData,
        spectrum: {
          ...indexedCycleData.spectrum,
          amplitudes: {
            ...indexedCycleData.spectrum.amplitudes,
            loadingState: LoadingState.Loaded,
            data: dataId,
          },
        },
      };

      return {
        ...state,
        cycles: {
          ...state.cycles,
          data: recreateCyclesData(cyclesData, cycleIndex, newCycleData),
        },
      };
    }
  ),
  on(
    PeakIdentificationActions.cycleSpectrumBytesNotFound,
    (
      state: PeakIdentificationState,
      { signalId, cycleIndex }: { signalId: AStrionSignalId; cycleIndex: number }
    ): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.cycles)) {
        return state;
      }

      if (cycleIndex >= state.cycles.data!.length) {
        return state;
      }

      const cyclesData = state.cycles.data!;
      const indexedCycleData = cyclesData[cycleIndex];
      const newCycleData = {
        ...indexedCycleData,
        spectrum: {
          ...indexedCycleData.spectrum,
          amplitudes: {
            ...indexedCycleData.spectrum.amplitudes,
            loadingState: LoadingState.NotFound,
            data: undefined,
          },
        },
      };

      return {
        ...state,
        cycles: {
          ...state.cycles,
          data: recreateCyclesData(cyclesData, cycleIndex, newCycleData),
        },
      };
    }
  ),
  on(
    PeakIdentificationActions.cycleSpectrumBytesFetchFailed,
    (
      state: PeakIdentificationState,
      { signalId, cycleIndex }: { signalId: AStrionSignalId; cycleIndex: number }
    ): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.cycles)) {
        return state;
      }

      if (cycleIndex >= state.cycles.data!.length) {
        return state;
      }

      const cyclesData = state.cycles.data!;
      const indexedCycleData = cyclesData[cycleIndex];
      const newCycleData = {
        ...indexedCycleData,
        spectrum: {
          ...indexedCycleData.spectrum,
          amplitudes: {
            ...indexedCycleData.spectrum.amplitudes,
            loadingState: LoadingState.Error,
            data: undefined,
          },
        },
      };

      return {
        ...state,
        cycles: {
          ...state.cycles,
          data: recreateCyclesData(cyclesData, cycleIndex, newCycleData),
        },
      };
    }
  ),
  on(
    PeakIdentificationActions.cycleNoiseBytesFetchRequested,
    (
      state: PeakIdentificationState,
      { signalId, cycleIndex }: { signalId: AStrionSignalId; cycleIndex: number }
    ): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.cycles)) {
        return state;
      }

      if (cycleIndex >= state.cycles.data!.length) {
        return state;
      }

      const cyclesData = state.cycles.data!;
      const indexedCycleData = cyclesData[cycleIndex];
      const newCycleData = {
        ...indexedCycleData,
        noise: {
          ...indexedCycleData.noise,
          amplitudes: {
            ...indexedCycleData.noise.amplitudes,
            loadingState: LoadingState.Loading,
            data: undefined,
          },
        },
      };

      return {
        ...state,
        cycles: {
          ...state.cycles,
          data: recreateCyclesData(cyclesData, cycleIndex, newCycleData),
        },
      };
    }
  ),
  on(
    PeakIdentificationActions.cycleNoiseBytesFetched,
    (
      state: PeakIdentificationState,
      { signalId, cycleIndex, dataId }: { signalId: AStrionSignalId; cycleIndex: number; dataId: IndexedDbRowId }
    ): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.cycles)) {
        return state;
      }

      if (cycleIndex >= state.cycles.data!.length) {
        return state;
      }

      const cyclesData = state.cycles.data!;
      const indexedCycleData = cyclesData[cycleIndex];
      const newCycleData = {
        ...indexedCycleData,
        noise: {
          ...indexedCycleData.noise,
          amplitudes: {
            ...indexedCycleData.noise.amplitudes,
            loadingState: LoadingState.Loaded,
            data: dataId,
          },
        },
      };

      return {
        ...state,
        cycles: {
          ...state.cycles,
          data: recreateCyclesData(cyclesData, cycleIndex, newCycleData),
        },
      };
    }
  ),
  on(
    PeakIdentificationActions.cycleNoiseBytesNotFound,
    (
      state: PeakIdentificationState,
      { signalId, cycleIndex }: { signalId: AStrionSignalId; cycleIndex: number }
    ): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.cycles)) {
        return state;
      }

      if (cycleIndex >= state.cycles.data!.length) {
        return state;
      }

      const cyclesData = state.cycles.data!;
      const indexedCycleData = cyclesData[cycleIndex];
      const newCycleData = {
        ...indexedCycleData,
        noise: {
          ...indexedCycleData.noise,
          amplitudes: {
            ...indexedCycleData.noise.amplitudes,
            loadingState: LoadingState.NotFound,
            data: undefined,
          },
        },
      };

      return {
        ...state,
        cycles: {
          ...state.cycles,
          data: recreateCyclesData(cyclesData, cycleIndex, newCycleData),
        },
      };
    }
  ),
  on(
    PeakIdentificationActions.cycleNoiseBytesFetchFailed,
    (
      state: PeakIdentificationState,
      { signalId, cycleIndex }: { signalId: AStrionSignalId; cycleIndex: number }
    ): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.cycles)) {
        return state;
      }

      if (cycleIndex >= state.cycles.data!.length) {
        return state;
      }

      const cyclesData = state.cycles.data!;
      const indexedCycleData = cyclesData[cycleIndex];
      const newCycleData = {
        ...indexedCycleData,
        noise: {
          ...indexedCycleData.noise,
          amplitudes: {
            ...indexedCycleData.noise.amplitudes,
            loadingState: LoadingState.Error,
            data: undefined,
          },
        },
      };

      return {
        ...state,
        cycles: {
          ...state.cycles,
          data: recreateCyclesData(cyclesData, cycleIndex, newCycleData),
        },
      };
    }
  ),
  on(
    PeakIdentificationActions.fusionFetchRequested,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        fusion: defaultFusionWithLoadingState(LoadingState.Loading),
      };
    }
  ),
  on(
    PeakIdentificationActions.fusionFetched,
    (
      state: PeakIdentificationState,
      { signalId, fusion }: { signalId: AStrionSignalId; fusion: Fusion }
    ): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        fusion,
      };
    }
  ),
  on(
    PeakIdentificationActions.fusionNotFound,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        fusion: defaultFusionWithLoadingState(LoadingState.NotFound),
      };
    }
  ),
  on(
    PeakIdentificationActions.fusionFetchFailed,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        fusion: defaultFusionWithLoadingState(LoadingState.Error),
      };
    }
  ),
  on(
    PeakIdentificationActions.fusionNoiseMinBytesFetchRequested,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.fusion)) {
        return state;
      }

      return {
        ...state,
        fusion: {
          ...state.fusion,
          data: {
            ...state.fusion.data!,
            noiseArea: {
              ...state.fusion.data!.noiseArea,
              minAmplitudes: {
                ...state.fusion.data!.noiseArea.minAmplitudes,
                loadingState: LoadingState.Loading,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    PeakIdentificationActions.fusionNoiseMinBytesFetched,
    (
      state: PeakIdentificationState,
      { signalId, dataId }: { signalId: AStrionSignalId; dataId: IndexedDbRowId }
    ): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.fusion)) {
        return state;
      }

      return {
        ...state,
        fusion: {
          ...state.fusion,
          data: {
            ...state.fusion.data!,
            noiseArea: {
              ...state.fusion.data!.noiseArea,
              minAmplitudes: {
                ...state.fusion.data!.noiseArea.minAmplitudes,
                loadingState: LoadingState.Loaded,
                data: dataId,
              },
            },
          },
        },
      };
    }
  ),
  on(
    PeakIdentificationActions.fusionNoiseMinBytesNotFound,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.fusion)) {
        return state;
      }

      return {
        ...state,
        fusion: {
          ...state.fusion,
          data: {
            ...state.fusion.data!,
            noiseArea: {
              ...state.fusion.data!.noiseArea,
              minAmplitudes: {
                ...state.fusion.data!.noiseArea.minAmplitudes,
                loadingState: LoadingState.NotFound,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    PeakIdentificationActions.fusionNoiseMinBytesFetchFailed,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.fusion)) {
        return state;
      }

      return {
        ...state,
        fusion: {
          ...state.fusion,
          data: {
            ...state.fusion.data!,
            noiseArea: {
              ...state.fusion.data!.noiseArea,
              minAmplitudes: {
                ...state.fusion.data!.noiseArea.minAmplitudes,
                loadingState: LoadingState.Error,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    PeakIdentificationActions.fusionNoiseMaxBytesFetchRequested,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.fusion)) {
        return state;
      }

      return {
        ...state,
        fusion: {
          ...state.fusion,
          data: {
            ...state.fusion.data!,
            noiseArea: {
              ...state.fusion.data!.noiseArea,
              maxAmplitudes: {
                ...state.fusion.data!.noiseArea.maxAmplitudes,
                loadingState: LoadingState.Loading,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    PeakIdentificationActions.fusionNoiseMaxBytesFetched,
    (
      state: PeakIdentificationState,
      { signalId, dataId }: { signalId: AStrionSignalId; dataId: IndexedDbRowId }
    ): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.fusion)) {
        return state;
      }

      return {
        ...state,
        fusion: {
          ...state.fusion,
          data: {
            ...state.fusion.data!,
            noiseArea: {
              ...state.fusion.data!.noiseArea,
              maxAmplitudes: {
                ...state.fusion.data!.noiseArea.maxAmplitudes,
                loadingState: LoadingState.Loaded,
                data: dataId,
              },
            },
          },
        },
      };
    }
  ),
  on(
    PeakIdentificationActions.fusionNoiseMaxBytesNotFound,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.fusion)) {
        return state;
      }

      return {
        ...state,
        fusion: {
          ...state.fusion,
          data: {
            ...state.fusion.data!,
            noiseArea: {
              ...state.fusion.data!.noiseArea,
              maxAmplitudes: {
                ...state.fusion.data!.noiseArea.maxAmplitudes,
                loadingState: LoadingState.NotFound,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    PeakIdentificationActions.fusionNoiseMaxBytesFetchFailed,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.fusion)) {
        return state;
      }

      return {
        ...state,
        fusion: {
          ...state.fusion,
          data: {
            ...state.fusion.data!,
            noiseArea: {
              ...state.fusion.data!.noiseArea,
              maxAmplitudes: {
                ...state.fusion.data!.noiseArea.maxAmplitudes,
                loadingState: LoadingState.Error,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    PeakIdentificationActions.harmonicSeriesFetchRequested,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        harmonicSeries: defaultHarmonicSeriesWithLoadingState(LoadingState.Loading),
      };
    }
  ),
  on(
    PeakIdentificationActions.harmonicSeriesFetched,
    (
      state: PeakIdentificationState,
      { signalId, harmonicSeries }: { signalId: AStrionSignalId; harmonicSeries: HarmonicSeries }
    ): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        harmonicSeries,
      };
    }
  ),
  on(
    PeakIdentificationActions.harmonicSeriesNotFound,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        harmonicSeries: defaultHarmonicSeriesWithLoadingState(LoadingState.NotFound),
      };
    }
  ),
  on(
    PeakIdentificationActions.harmonicSeriesFetchFailed,
    (state: PeakIdentificationState, { signalId }: { signalId: AStrionSignalId }): PeakIdentificationState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        harmonicSeries: defaultHarmonicSeriesWithLoadingState(LoadingState.Error),
      };
    }
  )
);
