/*
All these utilities consider input as immutable.
*/

export function recordFrom<K extends string | number | symbol, T>(entries: [K, T][]): Record<K, T> {
  return Object.fromEntries(entries) as Record<K, T>;
}

export function recordEntries<K extends string | number | symbol, T>(record: Record<K, T>): [K, T][] {
  return Object.entries(record) as [K, T][];
}

export function recordMap<K extends string | number | symbol, T, L extends string | number | symbol = K, U = T>(
  record: Readonly<Record<K, T>>,
  map: (key: K, val: T) => [L, U]
): Record<L, U> {
  return recordFrom(recordEntries(record).map(([key, val]) => map(key, val)));
}

export function recordFilter<K extends string | number | symbol, T>(
  record: Readonly<Record<K, T>>,
  filter: (key: K, val: T) => boolean
): Record<K, T> {
  return recordFrom(recordEntries(record).filter(([key, val]) => filter(key, val)));
}

export function recordFilterAndMap<
  K extends string | number | symbol,
  T,
  L extends string | number | symbol = K,
  U = T,
>(record: Record<K, T>, filter: (key: K, val: T) => boolean, map: (key: K, val: T) => [L, U]): Record<L, U> {
  return recordFrom(
    recordEntries(record)
      .filter(([key, val]) => filter(key, val))
      .map(([key, val]) => map(key, val))
  );
}

export function recordDelete<K extends string | number | symbol, T>(
  record: Readonly<Record<K, T>>,
  keyToRemove: K
): Record<K, T> {
  return recordFilter(record, k => k !== keyToRemove);
}

export function recordDeleteValue<K extends string | number | symbol, T>(
  record: Readonly<Record<K, T>>,
  valueToRemove: T
): Record<K, T> {
  return recordFilter(record, (_, v) => v !== valueToRemove);
}

export function recordDeleteMultiple<K extends string | number | symbol, T>(
  record: Readonly<Record<K, T>>,
  toDelete: Set<K> | Array<K>
): Record<K, T> {
  if (!(toDelete instanceof Set)) {
    toDelete = new Set(toDelete);
  }
  return recordFilter(record, k => !toDelete.has(k));
}

export function recordDeleteMultipleValues<K extends string | number | symbol, T>(
  record: Readonly<Record<K, T>>,
  toDelete: Set<T> | Array<T>
): Record<K, T> {
  if (!(toDelete instanceof Set)) {
    toDelete = new Set(toDelete);
  }
  return recordFilter(record, (_, v) => !toDelete.has(v));
}

// To avoid triggering change if no actual change
export function recordDeleteIfExists<K extends string | number | symbol, T>(
  record: Readonly<Record<K, T>>,
  keyToRemove: K
): Record<K, T> {
  return record[keyToRemove] !== undefined ? recordFilter(record, k => k !== keyToRemove) : record;
}

export function recordIntersectKeys<K extends string | number | symbol, T>(
  record: Readonly<Record<K, T>>,
  newKeys: Set<K>
): Record<K, T> {
  return recordFilter(record, k => newKeys.has(k as K));
}
