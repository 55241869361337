import { Routes } from '@angular/router';

import { SensorViewComponent } from './views/sensor-view/sensor-view.component';

export const SENSORS_ROUTES: Routes = [
  {
    path: ':id',
    component: SensorViewComponent,
  },
];
