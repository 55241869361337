import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  GlGraphComponent,
  GraphInput,
  GraphObject,
  HightlightIndices,
  Line,
  Scaling,
  SegmentedLine,
} from '@astrion-webtools/graph';
import { GraphWrapperBaseComponent } from '@components/graph-wrapper-base/graph-wrapper-base.component';
import { MaterialModule } from '@modules/material.module';
import { CurveLegend } from '../graph-wrapper-base/graph-wrapper-base.interface';

@Component({
  selector: 'app-graph-wrapper',
  standalone: true,
  imports: [MaterialModule, GraphWrapperBaseComponent, GlGraphComponent],
  templateUrl: './graph-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphWrapperComponent {
  name = input.required<string>();
  xTitle = input.required<string>();
  yTitle = input.required<string>();
  data = input.required<GraphInput | undefined>();
  showGroupAxis = input<string | undefined | null>(undefined);
  graphHeight = input('70vh');
  highlightIndices = input<HightlightIndices | HightlightIndices[] | undefined>(undefined);
  lines = input<(SegmentedLine | Line)[] | undefined>(undefined);
  yScale = input<Scaling | undefined>(undefined);
  xAxisDataIsTimestamps = input(false);
  syncWithVerticalLines = input<boolean | undefined>(undefined);
  syncWithHorizontalLines = input<boolean | undefined>(undefined);

  showSettings = input(false);
  showDownload = input(true);
  showLegend = input(false);
  showDelete = input(true);
  collapsable = input(true);

  graphId = crypto.randomUUID();

  delete = output();

  defaultColor = '#408fff';

  hasData = computed(() => {
    const data = this.data();
    return (
      !!data && ((data.curves?.length ?? 0) > 0 || (data.areas?.length ?? 0) > 0 || (data.heatmaps?.length ?? 0) > 0)
    );
  });

  legend = computed<CurveLegend[] | undefined>(() => {
    const data = this.data();
    if (!this.showLegend() || !data) {
      return undefined;
    }
    const curves: GraphObject[] = [...(data.curves ?? []), ...(data.areas ?? [])];
    return curves
      .map(curve => ({
        id: curve.id,
        displayName: curve.displayName ?? curve.id,
        color: curve.color ?? this.defaultColor,
        axisGroup: curve.axisGroup,
        display: curve.display ?? true,
      }))
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
  });

  constructor(private exportDialog: MatDialog) {}
}
