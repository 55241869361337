import { Component, input } from '@angular/core';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-report-sub-section',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './report-sub-section.component.html',
})
export class ReportSubSectionComponent {
  public subSectionTitle = input.required<string>();
}
