import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { AStrionSignal, AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { AStrionSignalStatus } from '@features/signals-status/shared/interface/astrion-signals-status.interface';
import { LoadingState } from '@shared/interfaces/loading-state';

export const name = 'sensor-signals';

export interface SensorSignalsState {
  loadingState: LoadingState;
  sensorId: SensorId | null;
  signals: AStrionSignal[];
  statuses: Record<AStrionSignalId, AStrionSignalStatus | undefined>;
}

export const SENSOR_SIGNALS_INITIAL_STATE: SensorSignalsState = {
  loadingState: LoadingState.Unknown,
  sensorId: null,
  signals: [],
  statuses: {},
};
