<app-graph-wrapper
  class="h-full w-full"
  id="sampling-test"
  [name]="filename()"
  [data]="data()"
  [lines]="lines()"
  [yScale]="dbScale"
  xTitle="Frequency (Hz)"
  yTitle="PSD (dB)"
  graphHeight="63vh"
  [showDelete]="false"
  [collapsable]="false" />
