import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { SensorId } from '../interfaces/sensor.interface';
import { Sensor, SensorPayload } from '../interfaces/sensor.interface';
import { EquipmentId } from '@features/equipments/shared/interfaces/equipment.interface';

export const SensorsActions = createActionGroup({
  source: 'Sensors',
  events: {
    noop: emptyProps(),

    sensorCreateRequested: props<{ sensor: SensorPayload }>(),
    sensorCreated: props<{ sensor: Sensor }>(),
    sensorCreateFailed: emptyProps(),

    sensorUpdateRequested: props<{ sensor: SensorPayload }>(),
    sensorUpdated: props<{ sensor: Sensor }>(),
    sensorUpdateFailed: emptyProps(),

    sensorDeleteRequested: props<{ id: SensorId; equipmentId: EquipmentId }>(),
    sensorDeleted: props<{ id: SensorId; equipmentId: EquipmentId }>(),
    sensorDeleteFailed: emptyProps(),
  },
});
