import { DecimalPipe } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { OverviewCardComponent } from '@components/overview-card/overview-card.component';
import { TruncatableFieldComponent } from '@components/truncatable-field/truncatable-field.component';
import { SamplingCondition } from '@features/sensor-signals/shared/interfaces/sampling-condition.interface';
import { Sensor } from '@features/sensors/shared/interfaces/sensor.interface';
import { DurationPipe } from '@tools/pipes/duration.pipe';

@Component({
  selector: 'app-sensor-overview',
  standalone: true,
  imports: [OverviewCardComponent, DurationPipe, DecimalPipe, TruncatableFieldComponent],
  templateUrl: './sensor-overview.component.html',
  styles: ``,
})
export class SensorOverviewComponent {
  sensor = input.required<Sensor>();
  canEdit = input.required<boolean>();
  samplingCondition = input.required<SamplingCondition | null>();

  editClicked = output();
}
