import { Component, input } from '@angular/core';
import { Colormap, ColormapLegendComponent } from '@astrion-webtools/graph';

@Component({
  selector: 'app-color-scale',
  standalone: true,
  imports: [ColormapLegendComponent],
  templateUrl: './color-scale.component.html',
})
export class ColorScaleComponent {
  id = input.required<string>();
  colormap = input.required<Colormap | string>();
  min = input.required<number>();
  max = input.required<number>();
  unit = input.required<string>();
}
