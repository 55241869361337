import { IndexedDbRowId } from '@astrion-webtools/graph';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { on } from '@ngrx/store';
import { loaded, LoadingState } from '@shared/interfaces/loading-state';

import { defaultSamplingTestWithLoadingState, ShannonTest } from '../../interface/sampling-test';
import { DataValidationActions } from '../data-validation.actions';
import { DataValidationState } from '../data-validation.state';

export function samplingTestReducer() {
  return [
    on(
      DataValidationActions.samplingTestFetchRequested,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state)) {
          return state;
        }

        return {
          ...state,
          samplingTest: defaultSamplingTestWithLoadingState(LoadingState.Loading),
        };
      }
    ),
    on(
      DataValidationActions.samplingTestFetched,
      (
        state: DataValidationState,
        { signalId, samplingTest }: { signalId: AStrionSignalId; samplingTest: ShannonTest }
      ): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state)) {
          return state;
        }

        return {
          ...state,
          samplingTest,
        };
      }
    ),
    on(
      DataValidationActions.samplingTestNotFound,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state)) {
          return state;
        }

        return {
          ...state,
          samplingTest: defaultSamplingTestWithLoadingState(LoadingState.NotFound),
        };
      }
    ),
    on(
      DataValidationActions.samplingTestFetchFailed,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state)) {
          return state;
        }

        return {
          ...state,
          samplingTest: defaultSamplingTestWithLoadingState(LoadingState.Error),
        };
      }
    ),
    on(
      DataValidationActions.samplingTestLinearBytesFetchRequested,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          samplingTest: {
            ...state.samplingTest,
            data: {
              ...state.samplingTest.data!,
              linearTestSummary: {
                ...state.samplingTest.data!.linearTestSummary,
                spectrumAmplitudes: {
                  ...state.samplingTest.data!.linearTestSummary.spectrumAmplitudes,
                  loadingState: LoadingState.Loading,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.samplingTestLinearBytesFetched,
      (
        state: DataValidationState,
        { signalId, dataId }: { signalId: AStrionSignalId; dataId: IndexedDbRowId }
      ): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          samplingTest: {
            ...state.samplingTest,
            data: {
              ...state.samplingTest.data!,
              linearTestSummary: {
                ...state.samplingTest.data!.linearTestSummary,
                spectrumAmplitudes: {
                  ...state.samplingTest.data!.linearTestSummary.spectrumAmplitudes,
                  loadingState: LoadingState.Loaded,
                  data: dataId,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.samplingTestLinearBytesNotFound,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          samplingTest: {
            ...state.samplingTest,
            data: {
              ...state.samplingTest.data!,
              linearTestSummary: {
                ...state.samplingTest.data!.linearTestSummary,
                spectrumAmplitudes: {
                  ...state.samplingTest.data!.linearTestSummary.spectrumAmplitudes,
                  loadingState: LoadingState.NotFound,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.samplingTestLinearBytesFetchFailed,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          samplingTest: {
            ...state.samplingTest,
            data: {
              ...state.samplingTest.data!,
              linearTestSummary: {
                ...state.samplingTest.data!.linearTestSummary,
                spectrumAmplitudes: {
                  ...state.samplingTest.data!.linearTestSummary.spectrumAmplitudes,
                  loadingState: LoadingState.Error,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.samplingTestdBBytesFetchRequested,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          samplingTest: {
            ...state.samplingTest,
            data: {
              ...state.samplingTest.data!,
              dbTestSummary: {
                ...state.samplingTest.data!.dbTestSummary,
                spectrumAmplitudes: {
                  ...state.samplingTest.data!.dbTestSummary.spectrumAmplitudes,
                  loadingState: LoadingState.Loading,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.samplingTestdBBytesFetched,
      (
        state: DataValidationState,
        { signalId, dataId }: { signalId: AStrionSignalId; dataId: IndexedDbRowId }
      ): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          samplingTest: {
            ...state.samplingTest,
            data: {
              ...state.samplingTest.data!,
              dbTestSummary: {
                ...state.samplingTest.data!.dbTestSummary,
                spectrumAmplitudes: {
                  ...state.samplingTest.data!.dbTestSummary.spectrumAmplitudes,
                  loadingState: LoadingState.Loaded,
                  data: dataId,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.samplingTestdBBytesNotFound,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          samplingTest: {
            ...state.samplingTest,
            data: {
              ...state.samplingTest.data!,
              dbTestSummary: {
                ...state.samplingTest.data!.dbTestSummary,
                spectrumAmplitudes: {
                  ...state.samplingTest.data!.dbTestSummary.spectrumAmplitudes,
                  loadingState: LoadingState.NotFound,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.samplingTestdBBytesFetchFailed,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          samplingTest: {
            ...state.samplingTest,
            data: {
              ...state.samplingTest.data!,
              dbTestSummary: {
                ...state.samplingTest.data!.dbTestSummary,
                spectrumAmplitudes: {
                  ...state.samplingTest.data!.dbTestSummary.spectrumAmplitudes,
                  loadingState: LoadingState.Error,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.samplingTestNoiseBytesFetchRequested,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          samplingTest: {
            ...state.samplingTest,
            data: {
              ...state.samplingTest.data!,
              noiseTestSummary: {
                ...state.samplingTest.data!.noiseTestSummary,
                spectrumAmplitudes: {
                  ...state.samplingTest.data!.noiseTestSummary.spectrumAmplitudes,
                  loadingState: LoadingState.Loading,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.samplingTestNoiseBytesFetched,
      (
        state: DataValidationState,
        { signalId, dataId }: { signalId: AStrionSignalId; dataId: IndexedDbRowId }
      ): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          samplingTest: {
            ...state.samplingTest,
            data: {
              ...state.samplingTest.data!,
              noiseTestSummary: {
                ...state.samplingTest.data!.noiseTestSummary,
                spectrumAmplitudes: {
                  ...state.samplingTest.data!.noiseTestSummary.spectrumAmplitudes,
                  loadingState: LoadingState.Loaded,
                  data: dataId,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.samplingTestNoiseBytesNotFound,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          samplingTest: {
            ...state.samplingTest,
            data: {
              ...state.samplingTest.data!,
              noiseTestSummary: {
                ...state.samplingTest.data!.noiseTestSummary,
                spectrumAmplitudes: {
                  ...state.samplingTest.data!.noiseTestSummary.spectrumAmplitudes,
                  loadingState: LoadingState.NotFound,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
    on(
      DataValidationActions.samplingTestNoiseBytesFetchFailed,
      (state: DataValidationState, { signalId }: { signalId: AStrionSignalId }): DataValidationState => {
        if (signalId !== state.signalId || !loaded(state) || !loaded(state.samplingTest)) {
          return state;
        }

        return {
          ...state,
          samplingTest: {
            ...state.samplingTest,
            data: {
              ...state.samplingTest.data!,
              noiseTestSummary: {
                ...state.samplingTest.data!.noiseTestSummary,
                spectrumAmplitudes: {
                  ...state.samplingTest.data!.noiseTestSummary.spectrumAmplitudes,
                  loadingState: LoadingState.Error,
                  data: undefined,
                },
              },
            },
          },
        };
      }
    ),
  ];
}
