import { createReducer, on } from '@ngrx/store';
import { recordDelete } from '@tools/utilities/record-utilities';

import { SensorGraphsActions } from './sensor-graphs.actions';
import { SENSOR_GRAPHS_INITIAL_STATE, SensorGraphsState } from './sensor-graphs.state';

export const reducer = createReducer(
  SENSOR_GRAPHS_INITIAL_STATE,
  on(
    SensorGraphsActions.clearCurves,
    (state, { sensorId }): SensorGraphsState => ({
      ...state,
      sensorsCurves: { ...state.sensorsCurves, [sensorId]: {} },
    })
  ),
  on(
    SensorGraphsActions.addCurve,
    (state, { curveId, curve, sensorId }): SensorGraphsState => ({
      ...state,
      sensorsCurves: {
        ...state.sensorsCurves,
        [sensorId]: { ...state.sensorsCurves[sensorId], [curveId]: curve },
      },
    })
  ),
  on(
    SensorGraphsActions.removeCurve,
    (state, { curveId, sensorId }): SensorGraphsState => ({
      ...state,
      sensorsCurves: {
        ...state.sensorsCurves,
        [sensorId]: recordDelete(state.sensorsCurves[sensorId], curveId),
      },
    })
  )
);
