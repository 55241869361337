import { Component, computed, input, signal } from '@angular/core';
import {
  AxisRange,
  GlGraphComponent,
  GraphBoundedArea,
  GraphCanvasDimensions,
  GraphInput,
  Scaling,
} from '@astrion-webtools/graph';
import { GraphWrapperBaseComponent } from '@components/graph-wrapper-base/graph-wrapper-base.component';
import { loaded } from '@shared/interfaces/loading-state';
import { ContentStyle } from '../../content-toggle/content-toggle.component';
import {
  CurvesOptions,
  PeaksGraphInteractiveLegendComponent,
  SimplifiedPeak,
} from '../../peaks-graph-interactive-legend/peaks-graph-interactive-legend.component';
import { FusionData, FusionPeak } from '@features/peak-identification/shared/interface/fusion';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';
import { HarmonicsMarkersComponent } from '../harmonics-markers/harmonics-markers.component';
import { CommonModule } from '@angular/common';
import {
  addColorToHarmonicSeries,
  computeEmphasizedHarmonics,
  computeSortedSelectedPeakIndices,
  identifyHarmonics,
  identifyNonHarmonicPeaks,
  sortHarmonicsBySelectionStatus,
} from '../utils/harmonic-utilities';
import { HarmonicSerie } from '@features/peak-identification/shared/interface/harmonic-series';
import { peakToLine } from '@features/peak-identification/shared/utils/peak-utils';
import { HiddenHarmonicsWarningComponent } from '../hidden-harmonics-warning/hidden-harmonics-warning.component';
import { ColoredHarmonicSerie } from '../shared/interfaces';

const NoiseAreaStyle: ContentStyle = {
  color: '#078bf8bb',
};

@Component({
  selector: 'app-peaks-and-harmonics-graph',
  standalone: true,
  imports: [
    CommonModule,
    GraphWrapperBaseComponent,
    GlGraphComponent,
    HarmonicsMarkersComponent,
    HiddenHarmonicsWarningComponent,
    PeaksGraphInteractiveLegendComponent,
  ],
  templateUrl: './peaks-and-harmonics-graph.component.html',
})
export class PeaksAndHarmonicsGraphComponent {
  public readonly curveOptions: CurvesOptions = {
    name: 'Curves',
    first: {
      name: 'Noise',
      style: NoiseAreaStyle,
      state: signal<boolean>(true),
    },
  };

  public filename = input.required<string>();
  public inHarmonicsMode = input.required<boolean>();

  public fusion = input.required<FusionData>();
  public harmonicSeries = input.required<ColoredHarmonicSerie[]>();

  public selectedSeries = input<HarmonicSerie[]>([]);
  public peaksVisibilityFilter = signal<(peak: SimplifiedPeak) => boolean>(() => true);

  public dbScale = signal(true);

  public graphCanvasDimensions = signal<GraphCanvasDimensions>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  public visibleFrequenciesRange = signal<AxisRange>({
    min: 0,
    max: 1,
  });

  public peaks = computed((): FusionPeak[] => this.fusion().peaks);

  public yTitle = computed(() => (this.dbScale() ? 'PSD (dB)' : 'PSD'));
  public yScale = computed(() => (this.dbScale() ? Scaling.dB : Scaling.None));

  public graphData = computed<GraphInput>(() => {
    const noiseArea = this.fusion().noiseArea;

    let area: GraphBoundedArea | undefined = undefined;

    if (loaded(noiseArea.minAmplitudes) && loaded(noiseArea.maxAmplitudes)) {
      area = {
        id: 'noiseArea',
        color: NoiseAreaStyle.color,
        dataScale: Scaling.dB,
        minCurve: {
          id: 'min',
          data: {
            indexedDb: {
              id: noiseArea.minAmplitudes.data!,
              valuesField: 'data',
              xMinField: 'freqMin',
              xMaxField: 'freqMax',
            },
          },
        },
        maxCurve: {
          id: 'max',
          data: {
            indexedDb: {
              id: noiseArea.maxAmplitudes.data!,
              valuesField: 'data',
              xMinField: 'freqMin',
              xMaxField: 'freqMax',
            },
          },
        },
      };
    }

    return {
      dbName: ASTRION_INDEXEDDB_NAME,
      storeName: ASTRION_INDEXEDDB_TABLES.fusion,
      areas: area === undefined ? undefined : [area],
    };
  });

  public visiblePeakLines = computed(() => {
    const peaksVisibilityFilter = this.peaksVisibilityFilter();

    if (!this.inHarmonicsMode()) {
      return this.peaks()
        .filter(peaksVisibilityFilter)
        .map(peak => peakToLine(peak, false));
    }

    const nonHarmonicPeaksLines = this._nonHarmonicPeaks()
      .filter(peaksVisibilityFilter)
      .map(peak => peakToLine(peak, true));

    const sortedHarmonics = this._harmonicsSortedBySelectionStatus();

    const coloredPeaksLines = sortedHarmonics.selected
      .filter(this.peaksVisibilityFilter())
      .map(peak => peakToLine(peak, false));

    const grayedOutHarmonicPeaksLines = sortedHarmonics.unselected
      .filter(this.peaksVisibilityFilter())
      .map(peak => peakToLine(peak, true));

    const grayedOutPeaksLines = nonHarmonicPeaksLines.concat(...grayedOutHarmonicPeaksLines);

    return coloredPeaksLines.concat(...grayedOutPeaksLines);
  });

  public coloredHarmonicSeries = computed(() => addColorToHarmonicSeries(this.harmonicSeries()));

  public axisOverlayStyle = computed(() => {
    const dimensions = this.graphCanvasDimensions();

    return {
      width: `${dimensions.width}px`,
      height: '40px',
      top: `${dimensions.y + dimensions.height}px`,
      left: `${dimensions.x}px`,
    };
  });

  public emphasizedHarmonics = computed(() => {
    const selectedSeries = this._selectedSeries();
    const harmonics = this._harmonicsSortedBySelectionStatus().selected;

    return computeEmphasizedHarmonics(selectedSeries, harmonics);
  });

  public hiddenSelectedHarmonicsCount = computed(() => {
    const selectedHarmonics = this._harmonicsSortedBySelectionStatus().selected;

    const visibleSelectedHarmonics = selectedHarmonics.filter(this.peaksVisibilityFilter());

    return selectedHarmonics.length - visibleSelectedHarmonics.length;
  });

  private seriesSelectionFilter = computed(() => {
    const selectedSeries = this.selectedSeries();

    return (serie: HarmonicSerie) => selectedSeries.includes(serie);
  });

  private _selectedSeries = computed(() => this.harmonicSeries().filter(this.seriesSelectionFilter()));

  private _selectedHarmonicIndices = computed(() => computeSortedSelectedPeakIndices(this._selectedSeries()));

  private _harmonicsSortedBySelectionStatus = computed(() =>
    sortHarmonicsBySelectionStatus(this._harmonics(), this._selectedHarmonicIndices())
  );

  private _harmonics = computed(() => identifyHarmonics(this.harmonicSeries(), this.peaks()));

  private _nonHarmonicPeaks = computed(() => identifyNonHarmonicPeaks(this._harmonics(), this.peaks()));
}
