import { Component, input, signal } from '@angular/core';
import { AuthActions } from '@features/auth/shared/store/auth.actions';
import { authFeature } from '@features/auth/shared/store/auth.feature';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-project-selection',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './project-selection.component.html',
})
export class ProjectSelectionComponent {
  expanded = input.required<boolean>();

  projects = this.store.selectSignal(authFeature.selectProjects);
  chosenProject = this.store.selectSignal(authFeature.selectChosenProject);

  listOpened = signal<boolean>(false);

  constructor(private store: Store) {}

  changeProject(projectId: number) {
    const project = this.projects().find(p => p.id === projectId);
    if (project) {
      this.store.dispatch(AuthActions.projectChangeRequested({ project, redirectTo: '/' }));
    }
  }
}
