import { AStrionSignal, AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { createReducer, on } from '@ngrx/store';
import { LoadingState } from '@shared/interfaces/loading-state';

import { SignalReportActions } from './signal-report.actions';
import { SIGNAL_REPORT_INITIAL_STATE, SignalReportState } from './signal-report.state';

export const reducer = createReducer(
  SIGNAL_REPORT_INITIAL_STATE,
  on(
    SignalReportActions.signalBaseDataFetchRequested,
    (state: SignalReportState, { signalId }: { signalId: AStrionSignalId }): SignalReportState => ({
      ...state,
      signalId: signalId,
      loadingState: LoadingState.Loading,
    })
  ),
  on(
    SignalReportActions.signalBaseDataFetched,
    (
      state: SignalReportState,
      { signalId, signal }: { signalId: AStrionSignalId; signal: AStrionSignal }
    ): SignalReportState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...state,
        sensorId: signal.sensorId,
        name: signal.name,
        loadingState: LoadingState.Loaded,
      };
    }
  ),
  on(
    SignalReportActions.signalBaseDataNotFound,
    (state: SignalReportState, { signalId }: { signalId: AStrionSignalId }): SignalReportState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...state,
        loadingState: LoadingState.NotFound,
      };
    }
  ),
  on(
    SignalReportActions.signalBaseDataFetchFailed,
    (state: SignalReportState, { signalId }: { signalId: AStrionSignalId }): SignalReportState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...state,
        loadingState: LoadingState.Error,
      };
    }
  ),
  on(SignalReportActions.navigatedOut, (): SignalReportState => SIGNAL_REPORT_INITIAL_STATE),
  on(
    SignalsActions.signalUpdated,
    (state: SignalReportState, { signal }: { signal: AStrionSignal }): SignalReportState => {
      if (signal.name === state.name) {
        return state;
      }

      return {
        ...state,
        name: signal.name,
      };
    }
  )
);
