import { Component, input, signal } from '@angular/core';
import { Cycle } from '@features/peak-identification/shared/interface/cycles';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-cycles-selection',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './cycles-selection.component.html',
})
export class CyclesSelectionComponent {
  public cycles = input.required<Cycle[]>();

  public activeCycleIndex = signal<number>(0);
  public showingAll = signal<boolean>(false);
}
