<div class="flex w-full flex-col">
  <app-graph-wrapper
    [name]="graphTitle()"
    xTitle="Time (ms)"
    yTitle="Amplitude"
    [data]="graphData()"
    [showDelete]="false"
    [showLegend]="false"
    [collapsable]="false"
    graphHeight="50vh" />
</div>
