export function deleteFromImmutableSet<T>(set: Set<T>, value: T): Set<T> {
  const newSet = new Set(set);
  newSet.delete(value);
  return newSet;
}

export function deleteFromImmutableSetIfExists<T>(set: Set<T>, value: T): Set<T> {
  if (set.has(value)) {
    const newSet = new Set(set);
    newSet.delete(value);
    return newSet;
  } else {
    return set;
  }
}

export function toggleFromImmutableSet<T>(set: Set<T>, value: T): Set<T> {
  const newSet = new Set(set);
  if (newSet.has(value)) {
    newSet.delete(value);
  } else {
    newSet.add(value);
  }
  return newSet;
}

export function intersectImmutableSets<T>(set1: Set<T>, set2: Set<T>): Set<T> {
  return new Set<T>(Array.from(set1).filter(value => set2.has(value)));
}
