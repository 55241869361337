import { FusionData, FusionPeak } from '@features/peak-identification/shared/interface/fusion';
import { TablePeak, TablePeakColumnDefinition } from '../../peaks-table/peaks-table.component';

export const getPeaksTableAdditionalColumns = (fusion: FusionData): TablePeakColumnDefinition[] => {
  return [
    {
      name: 'Best cycle',
      propertyName: 'bestCycleIndex',
      formattedPropertyValue: (peak: TablePeak) => {
        return `${(peak as unknown as FusionPeak).bestCycleIndex}`;
      },
    },
    {
      name: 'Fusion cycles',
      propertyName: 'cyclesCount',
      formattedPropertyValue: (peak: TablePeak) => {
        return `${(peak as unknown as FusionPeak).cyclesCount}/${fusion.cyclesCount}`;
      },
    },
  ];
};
