<mat-sidenav-container class="relative m-0 flex h-full w-full flex-col gap-0 p-0" autosize="true">
  @let _sidenavExpanded = sidenavExpanded();

  <mat-sidenav mode="side" opened disableClose fixedInVIewport [class]="_sidenavExpanded ? 'w-64' : 'w-20'">
    <app-sidenav class="h-full" [expanded]="_sidenavExpanded" (expansionButtonToggled)="toggleSidenavExpansion()" />
  </mat-sidenav>
  <mat-sidenav-content class="flex-grow overflow-hidden">
    <div class="h-full overflow-hidden px-6">
      <router-outlet />
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
