@let _equipment = equipment();
@let _canEdit = canEdit();

<span class="m3-title-large flex-none select-none">Equipment overview</span>
<div class="mt-4 flex flex-row">
  <app-overview-card
    overviewTitle="Information"
    class="mr-2 w-[420px]"
    [canEdit]="_canEdit"
    (editClicked)="editClicked.emit()">
    <div class="text-sm">
      <app-truncatable-field id="name" label="Name" [content]="_equipment.name" [truncate]="27" />
      <app-truncatable-field id="model" label="Model" [content]="_equipment.model" [truncate]="27" />
    </div>
    <div class="text-sm">
      <app-truncatable-field id="type" label="Type" [content]="_equipment.equipmentType" [truncate]="27" />
    </div>
  </app-overview-card>
  <app-overview-card overviewTitle="Number of sensors" icon="sensors" class="ml-2 w-[281px]">
    <div data-testid="sensors-count" class="m3-headline-large">{{ sensorsCount() }}</div>
  </app-overview-card>
</div>
