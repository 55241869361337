import { Injectable } from '@angular/core';
import { SignalsApiService } from '@features/signals/shared/services/signals-api.service';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';

import { SignalOverviewApiService } from '../services/signal-overview-api-service';
import { mapSignalContentFromDto } from '../utils/signal-overview-mapping';
import { SignalOverviewActions } from './signal-overview.actions';

@Injectable()
export class SignalOverviewEffects {
  constructor(
    private actions$: Actions,
    private api: SignalOverviewApiService,
    private signalsApi: SignalsApiService
  ) {}

  tryFetchSignalContentEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalOverviewActions.signalContentFetchRequested),
      switchMap(({ signalId }) =>
        this.signalsApi.getSignalContent(signalId).pipe(
          map(dto =>
            SignalOverviewActions.signalContentFetched({ signalId, signalContent: mapSignalContentFromDto(dto) })
          ),
          catchApiError(false, apiError =>
            apiError.httpError.status == 404
              ? SignalOverviewActions.signalContentNotFound({ signalId })
              : SignalOverviewActions.signalContentFetchFailed({ signalId })
          )
        )
      )
    );
  });

  tryFetchSignalBytesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalOverviewActions.signalContentFetched),
      switchMap(({ signalId, signalContent }) =>
        this.api.getSignalBytes(signalId, signalContent.content.contentPath, signalContent.metadata).pipe(
          map(dbRowId => SignalOverviewActions.signalBytesFetched({ signalId, samples: dbRowId })),
          catchApiError(false, apiError => {
            return apiError.httpError.status == 404
              ? SignalOverviewActions.signalBytesNotFound({ signalId })
              : SignalOverviewActions.signalBytesFetchFailed({ signalId });
          })
        )
      )
    );
  });
}
