import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Equipment, EquipmentId, EquipmentPayload } from '@features/equipments/shared/interfaces/equipment.interface';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-create-equipment-dialog',
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './equipment-dialog.component.html',
  styles: ``,
})
export class EquipmentDialogComponent {
  existingEquipmentId: EquipmentId | undefined;
  okButtonText = 'Create';
  titleText = 'New equipment';

  equipmentForm = new FormGroup({
    name: new FormControl<string | null>(null, Validators.required),
    equipmentType: new FormControl<string | null>(null),
    model: new FormControl<string | null>(null),
    description: new FormControl<string | null>(null),
  });

  constructor(
    private dialogRef: MatDialogRef<EquipmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { equipment?: Equipment }
  ) {
    if (data.equipment) {
      this.existingEquipmentId = data.equipment.id;
      this.equipmentForm = new FormGroup({
        name: new FormControl<string | null>(data.equipment.name, Validators.required),
        equipmentType: new FormControl<string | null>(data.equipment.equipmentType),
        model: new FormControl<string | null>(data.equipment.model),
        description: new FormControl<string | null>(data.equipment.description),
      });
      this.okButtonText = 'Update';
      this.titleText = 'Edit equipment';
    }
  }

  async onSubmit() {
    const payload: EquipmentPayload = {
      id: this.existingEquipmentId,
      name: this.equipmentForm.value.name!,
      equipmentType: this.equipmentForm.value.equipmentType!,
      model: this.equipmentForm.value.model!,
      description: this.equipmentForm.value.description!,
    };
    this.dialogRef.close(payload);
  }
}
