<h1 mat-dialog-title class="select-none">{{ options.title }}</h1>
<mat-dialog-content class="select-none">
  @for (description of options.descriptions; track $index) {
    <p class="mb-1 mt-1">{{ description }}</p>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button class="primary-button" #cancelButton [mat-dialog-close]="false" cdkFocusInitial>
    Cancel
  </button>
  <span class="grow"></span>
  <button mat-button class="secondary-button" [mat-dialog-close]="true">OK</button>
</mat-dialog-actions>
