import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { DataBytes } from '@shared/interfaces/data-bytes';
import { LoadingState } from '@shared/interfaces/loading-state';

import { ValidationStepStatus } from './validation-step-status';

export interface ShannonCriterionTestResult {
  isValid: boolean;
  succeeded: boolean;
  frequency: number;
  amplitude: number;
}

export interface ShannonTestCriterionDto {
  result: ShannonCriterionTestResult;
  spectrumAmplitudesContentPath: string;
}

export interface ShannonTestDataDto {
  signalTooShort: boolean;
  linearTestSummary: ShannonTestCriterionDto;
  dbTestSummary: ShannonTestCriterionDto;
  noiseTestSummary: ShannonTestCriterionDto;
}

export interface ShannonTestDto {
  progress: string;
  data?: ShannonTestDataDto;
}

export interface ShannonTestResult {
  signalTooShort: boolean;
  linearSucceeded: boolean;
  dbSucceeded: boolean;
  noiseSucceeded: boolean;
}

export interface ShannonTestCriterion {
  result: ShannonCriterionTestResult;
  spectrumAmplitudes: DataBytes;
}

export interface ShannonTestData {
  status: ValidationStepStatus;
  samplingFrequency: number;
  signalTooShort: boolean;
  linearTestSummary: ShannonTestCriterion;
  dbTestSummary: ShannonTestCriterion;
  noiseTestSummary: ShannonTestCriterion;
  results(): ShannonTestResult;
}

export interface ShannonTest {
  progress: ComputationStepProgress;
  loadingState: LoadingState;
  data?: ShannonTestData;
}

export const defaultSamplingTestWithLoadingState = (
  loadingState: LoadingState = LoadingState.Unknown
): ShannonTest => ({
  progress: ComputationStepProgress.Unknown,
  loadingState,
});
