import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { EquipmentsActions } from '../store/equipments.actions';
import { EquipmentDialogComponent } from '@features/equipments/components/equipment-dialog/equipment-dialog.component';
import { Equipment } from '../interfaces/equipment.interface';

@Injectable()
export class EquipmentDialogService {
  constructor(
    private dialog: MatDialog,
    private store: Store
  ) {}

  async openCreate(): Promise<void> {
    const equipment = await lastValueFrom(
      this.dialog
        .open(EquipmentDialogComponent, {
          role: 'dialog',
          autoFocus: '.focused',
          width: '560px',
          data: {},
        })
        .afterClosed()
    );
    if (equipment) {
      this.store.dispatch(EquipmentsActions.equipmentCreateRequested({ equipment }));
    }
  }

  async openUpdate(equipment: Equipment): Promise<void> {
    const updatedEquipment = await lastValueFrom(
      this.dialog
        .open(EquipmentDialogComponent, {
          role: 'dialog',
          autoFocus: '.focused',
          width: '560px',
          data: { equipment },
        })
        .afterClosed()
    );
    if (updatedEquipment) {
      this.store.dispatch(EquipmentsActions.equipmentUpdateRequested({ equipment: updatedEquipment }));
    }
  }
}
