import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-spectrum-parameter',
  templateUrl: './spectrum-parameter.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MaterialModule],
})
export class SpectrumParameterComponent {
  name = input.required<string>();
  value = input.required<string | number>();
  tooltip = input<string | null>(null);
}
