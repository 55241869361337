/*
All these utilities consider input as immutable (unless labeled as "InPlace").
*/

export function mapFrom<K, T>(entries: [K, T][]): Map<K, T> {
  const map = new Map<K, T>();
  entries.forEach(([k, v]) => map.set(k, v));
  return map;
}

export function mapEntries<K, T>(map: Map<K, T>): [K, T][] {
  return Array.from(map.entries());
}

export function mapMap<K, T, L = K, U = T>(map: Readonly<Map<K, T>>, mapFun: (key: K, val: T) => [L, U]): Map<L, U> {
  const newMap = new Map<L, U>();
  for (const [k, v] of map.entries()) {
    const [l, u] = mapFun(k, v);
    newMap.set(l, u);
  }
  return newMap;
}

export function mapFilter<K, T>(map: Readonly<Map<K, T>>, filter: (key: K, val: T) => boolean): Map<K, T> {
  const newMap = new Map<K, T>();
  for (const [k, v] of map.entries()) {
    if (filter(k, v)) {
      newMap.set(k, v);
    }
  }
  return newMap;
}

export function mapFilterInPlace<K, T>(map: Map<K, T>, filter: (key: K, val: T) => boolean): Map<K, T> {
  for (const [k, v] of map.entries()) {
    if (!filter(k, v)) {
      map.delete(k);
    }
  }
  return map;
}

export function mapFilterAndMap<K, T, L = K, U = T>(
  map: Readonly<Map<K, T>>,
  filter: (key: K, val: T) => boolean,
  mapFun: (key: K, val: T) => [L, U]
): Map<L, U> {
  const newMap = new Map<L, U>();
  for (const [k, v] of map.entries()) {
    if (filter(k, v)) {
      const [l, u] = mapFun(k, v);
      newMap.set(l, u);
    }
  }
  return newMap;
}

export function mapDelete<K, T>(map: Readonly<Map<K, T>>, keyToRemove: K): Map<K, T> {
  return mapFilter(map, k => k !== keyToRemove);
}

export function mapDeleteInPlace<K, T>(map: Map<K, T>, keyToRemove: K): Map<K, T> {
  return mapFilterInPlace(map, k => k !== keyToRemove);
}

export function mapDeleteValue<K, T>(map: Readonly<Map<K, T>>, valueToRemove: T): Map<K, T> {
  return mapFilter(map, (_, v) => v !== valueToRemove);
}

export function mapDeleteValueInPlace<K, T>(map: Map<K, T>, valueToRemove: T): Map<K, T> {
  return mapFilterInPlace(map, (_, v) => v !== valueToRemove);
}

export function mapDeleteMultiple<K, T>(map: Readonly<Map<K, T>>, toDelete: Set<K> | Array<K>): Map<K, T> {
  if (!(toDelete instanceof Set)) {
    toDelete = new Set(toDelete);
  }
  return mapFilter(map, k => !toDelete.has(k));
}

export function mapDeleteMultipleInPlace<K, T>(map: Map<K, T>, toDelete: Set<K> | Array<K>): Map<K, T> {
  if (!(toDelete instanceof Set)) {
    toDelete = new Set(toDelete);
  }
  return mapFilterInPlace(map, k => !toDelete.has(k));
}

export function mapDeleteMultipleValues<K, T>(map: Readonly<Map<K, T>>, toDelete: Set<T> | Array<T>): Map<K, T> {
  if (!(toDelete instanceof Set)) {
    toDelete = new Set(toDelete);
  }
  return mapFilter(map, (_, v) => !toDelete.has(v));
}

export function mapDeleteMultipleValuesInPlace<K, T>(map: Map<K, T>, toDelete: Set<T> | Array<T>): Map<K, T> {
  if (!(toDelete instanceof Set)) {
    toDelete = new Set(toDelete);
  }
  return mapFilterInPlace(map, (_, v) => !toDelete.has(v));
}

// To avoid triggering change if no actual change
export function mapDeleteIfExists<K, T>(map: Readonly<Map<K, T>>, keyToRemove: K): Map<K, T> {
  return map.has(keyToRemove) ? mapFilter(map, k => k !== keyToRemove) : map;
}

export function mapIntersectKeys<K, T>(map: Readonly<Map<K, T>>, newKeys: Set<K> | Array<K>): Map<K, T> {
  if (!(newKeys instanceof Set)) {
    newKeys = new Set(newKeys);
  }
  const toRemove = Array.from(map.keys()).filter(k => !newKeys.has(k));
  if (toRemove.length === 0) {
    // Do not create new object if no change
    return map;
  } else {
    const result = new Map<K, T>(map);
    toRemove.forEach(k => result.delete(k));
    return result;
  }
}

export function mapIntersectKeysInPlace<K, T>(map: Map<K, T>, newKeys: Set<K> | Array<K>): Map<K, T> {
  if (!(newKeys instanceof Set)) {
    newKeys = new Set(newKeys);
  }
  const toRemove = Array.from(map.keys()).filter(k => !newKeys.has(k));
  toRemove.forEach(k => map.delete(k));
  return map;
}
